
import { onAuthStateChanged } from 'firebase/auth';
import { onValue, ref, set } from 'firebase/database';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { studentAuth, studentDB, studentStorage } from '../../api/firebase-student-config';
import { Footer } from '../../components/academy/Footer';

import {Header} from '../../components/general/Header'
import moment from 'moment';
import { getFileExt, numberWithCommas } from '../../helper/formatter';

import bdo from '../../img/logo/bdo.png'
import chinaBank from '../../img/logo/china-bank.png'
import coinsPH from '../../img/logo/coins-ph.png'
import gcash from '../../img/logo/gcash.png'
import landBank from '../../img/logo/landbank.png'
import maya from '../../img/logo/maya.png'
import bpi from '../../img/logo/bpi.png'
import securityBank from '../../img/logo/security-bank.png'
import unionBank from '../../img/logo/union-bank.png'

import imagePlaceholder from '../../img/placeholder/image-placeholder.png';
import ModalLoading from '../../components/general/ModalLoading';
import $ from 'jquery'
import {getDownloadURL, ref as sref, uploadBytes} from 'firebase/storage'

import randomstring from 'randomstring';

import irregShape from "../../img/bg/irregular-bg.png"

export const F2FTraining = () =>{

    let [processing,setProcessing] = useState(false);

    let [student,setStudent] = useState(null);
    let [f2fTraining,setF2FTraining] = useState();

    let [proofURL,setProofURL] = useState(imagePlaceholder);

    let [registration,setRegistration] = useState();
    let [f2fApprovedRegistrations, setF2FApprovedRegistrations] = useState(0)

    useEffect(()=>{

        onValue(ref(studentDB,'/f2fTrainingRegistration'),(snapshot)=>{

            if(snapshot.exists()){

                let rqs = snapshot.val()
                let approvedRequests = []
                
                for(var key in rqs){
                    
                    if(rqs[key].status === 'APPROVED'){
                        rqs[key].key = key
                        approvedRequests.push(rqs[key])
                    }

                }

                setF2FApprovedRegistrations(approvedRequests.length)

            }

        })

    },[])

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
      
            if(student){
        
              onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
                if(snapshot.exists()){
                    let s = snapshot.val();
                    s.uid = student.uid;
                    setStudent(s);
                }
              })
        
            }
        
        })

        onValue(ref(studentDB,'f2fTraining'),(snapshot)=>{
            if(snapshot.exists()){
                setF2FTraining(snapshot.val());                
            }
        })

    },[])

    useEffect(()=>{

        if(!student) return;

        onValue(ref(studentDB,`f2fTrainingRegistration/${student.uid}`),(snapshot)=>{
            if(snapshot.exists()){
                setRegistration(snapshot.val());
                setProofURL(snapshot.val().paymentProof)
            }else{
                setRegistration(null);
                setProofURL(imagePlaceholder)
            }
        })

    },[student])

    function openProof(){
        if(proofURL === imagePlaceholder) return
        window.open(proofURL)
    }

    function uploadProof(){

        setProcessing(true)
        
        var file = $('#fileUploadProof').prop('files')[0]
        var txtErr = $("#errProfilePicture")
        txtErr.text('')

        if(!file){
            setProcessing(false)
            return;
        }else{
            if(getFileExt(file.name).toLowerCase() !== 'png' && getFileExt(file.name).toLowerCase() !== 'jpg' && getFileExt(file.name).toLowerCase() !== 'jpeg'){
                
                txtErr.text('PNG and JPG Files are the only accepted files.')
                $('#fileUploadProof').val(null)
                setProcessing(false)
                return
            }
        }

        let rs = randomstring.generate(12);
        alert(rs)

        uploadBytes(sref(studentStorage,`students/f2fPaymentProof/${student.uid}/${rs}`),file).then(()=>{
            
            getDownloadURL(sref(studentStorage,`students/f2fPaymentProof/${student.uid}/${rs}`)).then((url)=>{
                setProofURL(url)

                let dateRegistered = moment().format("MMMM DD, YYYY hh:mm:ss a");

                let reg = {
                    paymentProof:url,
                    status:'PENDING',
                    dateRegistered:dateRegistered
                }

                set(ref(studentDB,`f2fTrainingRegistration/${student.uid}`),reg).then(()=>{
                    setProcessing(false);
                });

            });

        }).catch((error)=>{
            console.log(error)
            setProcessing(false)
        })


    }

    return(
        <>
            <Helmet>
                <title>Trading Circle - F2F Training</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn p-4">

                <Header title='F2F Training'/>

                <div className='row justify-content-center mb-5 d-flex mt-5 px-lg-5 px-0' style={{gap:"25px"}}>
                    
                    <div className='col-lg-5'>
                        <div className='video-container' style={{padding:"56.25% 0 0 0",position:"relative"}}><iframe src="https://player.vimeo.com/video/986903978?h=c8a00c81e3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} title="Website Walkthrough"></iframe></div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>
                    
                    <div className='col-12 my-0 py-0'/>

                    {f2fTraining && moment().isSameOrBefore(moment(f2fTraining.eventDate),'day')?
                    <div className='col-lg-5'>
                        <div className='row justify-content-center'>
                            <div className="col-lg-12 card-container py-3 px-4 text-blue f2f-training-container">

                                

                                {f2fTraining.eaconomyVenue && <p className="text-start mb-1"><span className="pill"><i className="fa fa-star"></i> EAconomy Sponsored</span></p>}
                                
                                <h5 className="text-start bold">{f2fTraining.title}</h5>
                                <p className="text-justify mb-3">{f2fTraining.description}</p>

                                <p className="text-start"><b>Training Date: </b>{moment(f2fTraining.eventDate).format('MMMM DD, YYYY')}</p>
                                <p className="text-start mb-2"><b>Time: </b>{moment(f2fTraining.startTime,'HH:mm').format('hh:mm A') + " - " +moment(f2fTraining.endTime,'HH:mm').format('hh:mm A')}</p>

                                <p className="text-justify"><b>Venue: </b>{f2fTraining.venue}</p>
                                
                                <p className="text-start mt-3"><b>Registration Deadline: </b>{moment(f2fTraining.registrationDeadline).format('MMMM DD, YYYY')}</p>
                                
                                {(!f2fTraining.closed && f2fTraining.venueLimit - f2fApprovedRegistrations !== 0  && !moment().isAfter(f2fTraining.registrationDeadline,'day')) || (registration && registration.status === 'APPROVED')?
                                <>

                                    {!student.eaconomyAccount?
                                    <>
                                        <h4 className="text-danger text-center mt-4 mb-0 black"><del>₱ {numberWithCommas(Math.ceil(f2fTraining.price + (f2fTraining.price * 0.3)))}</del></h4>
                                        <h3 className="text-light-blue text-center mb-4 black">₱ {numberWithCommas(f2fTraining.price)} <span className='black'>ONLY </span></h3>
                                    </>
                                    :
                                    <>
                                        <h5 className="text-danger text-center mt-4 mb-0 black"><del>₱ {numberWithCommas(Math.ceil(f2fTraining.price + (f2fTraining.price * 0.3)))}</del></h5>
                                        <h4 className="text-danger text-center mb-0 black"><del>₱ {numberWithCommas(f2fTraining.price)} <span className='small'>(15% OFF)</span></del></h4>
                                        <h3 className="text-light-blue text-center mb-0 black">₱ {numberWithCommas(f2fTraining.price-500)} <span className='black'>ONLY</span></h3>
                                        <p className='text-center mb-4'><p className='pill d-inline'><i className='fa fa-star'></i> EAconomy Student</p></p>
                                    </>
                                    }

                                    <div className='row justify-content-center m-3'>
                                        <div id='imgProof' className={`card-container ${proofURL !== imagePlaceholder && 'pointer'}`} style={{
                                            backgroundImage:`url('${proofURL}')`,
                                            backgroundPosition:'center center', 
                                            backgroundSize:'cover',
                                            backgroundRepeat:'no-repeat',
                                            width:'500px',height:'300px'}}
                                            onClick={()=>openProof()}
                                        />
                                    </div>

                                    {(!registration || (registration && registration.status !== 'APPROVED')) &&
                                    <>
                                        <p className='bold text-center mb-2'>
                                            
                                            {!registration && <><i className='fa fa-upload'/> Upload Payment Proof to Register</>}
                                            {registration && registration.status === 'PENDING' && <><i className='fa fa-upload'/> Update Payment Proof</>}

                                            
                                        </p>
                                        <input className='form-control' type='file' accept='image/png,image/jpg,image/jpeg' id='fileUploadProof' onChange={()=>uploadProof()}/>
                                    </>
                                    }
                                        
                                    {!registration && <h4 className='saira text-center mt-3 bold'>STATUS: <span>N/A</span></h4>}

                                    {registration && registration.status === 'PENDING' && <h4 className='saira text-center mt-3 mb-2 bold'>STATUS: <span className='text-pending'>PENDING</span></h4>}
                                    {registration && registration.status === 'APPROVED' && 
                                    <>
                                        <h4 className='saira text-center mt-4 bold'>STATUS: <span className='text-success'>REGISTERED</span></h4>
                                        <p className='text-center text-success mb-2'>*Please show up to the venue with proof of your identity thank you!</p>
                                    </>
                                    }

                                    {registration && registration.status === 'REJECTED' && <h4 className='saira text-center mt-4 bold mb-0'>STATUS: <span className='text-danger'>REJECTED</span></h4>}
                                    {registration && registration.reason && <p className='text-danger text-center mb-2'>{registration.reason}</p>}
                                    
                                    {(!registration || registration.status !== 'APPROVED') &&
                                    <div className='justify-content-center text-center py-4'>
                                        <h5 className="text-center pill my-3 black d-inline px-5 py-2" style={{fontSize:'24px',borderRadius:'100px'}}><i className='fa fa-user'/> {f2fTraining.venueLimit - f2fApprovedRegistrations} <span className='black'>SLOT/s LEFT </span></h5>
                                    </div>
                                    }
                                </>
                                :
                                <>
                                    <div className='justify-content-center text-center py-4'>
                                        <h5 className="text-center text-danger my-3 black d-inline px-5 py-2" style={{fontSize:'24px',borderRadius:'100px'}}><i className='fa fa-user'/> <span className='black'>SOLD OUT </span></h5>
                                    </div>
                                </>
                                }

                            </div>
                        </div>
                    </div>
                    :
                    <div className='col-lg-5'>
                        <div className='row justify-content-center'>
                            <div className="col-lg-12 card-container py-5 text-blue f2f-training-container">
                                <h3 className='text-center mb-0' style={{opacity:'0.5'}}><i className='fa fa-ban'/> No Active F2F Trainings</h3>
                                <p className='text-center' style={{opacity:'0.5'}}>Watch out for our next F2F trainings on our Facebook Group!</p>
                            </div>
                        </div>
                    </div>
                    }

                    <div className='col-lg-6 text-center'>

                        <div className='row justify-content-center my-4' style={{rowGap:'50px'}}>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={gcash} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 0929 853 5283</h5>
                                {/*<h5><b>Account Name:</b> Aleli Joy Balingit</h5>
                                <h5><b>Account Number:</b> 0927 577 3805</h5>*/}
                                {/*<h5><b>Account Name:</b> Aljay Balingit</h5>
                                <h5><b>Account Number:</b> 0920 971 0611</h5>*/}
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={bdo} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 0056 4034 7652</h5>
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={chinaBank} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 1268 0202 1864</h5>
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={coinsPH} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 0929 853 5283</h5>
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={landBank} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 2796 2042 76</h5>
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={maya} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 09298535283</h5>
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={bpi} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 4719 3229 51</h5>
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={securityBank} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 00000 5691 5885</h5>
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={unionBank} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 1094 2909 4115   </h5>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <Footer/>

            <div className='d-lg-block d-none' style={{background:`url('${irregShape}')`,width:'125%',height:'1507px',position:'absolute',top:'-400px',zIndex:'-999',backgroundPosition:'center center',right:'-12.5%',backgroundSize:'cover',opacity:'0.3'}}/>
            <div className='d-lg-none d-block' style={{background:`url('${irregShape}')`,width:'100%',height:'1005px',position:'absolute',top:'-300px',zIndex:'-999',backgroundPosition:'center center',backgroundSize:'cover',opacity:'0.2'}}/>

            <ModalLoading show={processing}/>

        </>
    );

}