import { Helmet } from 'react-helmet-async';
import profilePlaceholder from '../../img/placeholder/profile-placeholder.png';

import ModalLoading from '../../components/general/ModalLoading';
import { useEffect, useState } from 'react';

import {Header} from '../../components/general/Header'
import { onValue, ref, set, update} from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import { studentAuth, studentDB } from '../../api/firebase-student-config';

import $ from 'jquery'
import axios from 'axios'
import moment from 'moment'
import dragonpayLogo from '../../img/logo/dragonpay-logo.png'
import { numberWithCommas } from '../../helper/formatter';
import ModalConfirmation from '../../components/general/ModalConfirmation';

import {useSearchParams} from 'react-router-dom'
import uuid from 'react-uuid';
import { globalDirectMethod, globalMigrationMode, globalPaymentMethod } from '../../helper/globals';


export const ElitePricing = () =>{

    const [processing,setProcessing] = useState(false);

    var [uid,setUID] = useState(null);
    var [student,setStudent] = useState(null);

    var [paymentMethods,setPaymentMethods] = useState([])

    var [purchasing,setPurchasing] = useState(false)

    var [selectedIndex,setSelectedIndex] = useState(0)

    var [voiding,setVoiding] = useState(false)
    var [selectedTxnID,setSelectedTxnID] = useState()

    var [elitePayment,setElitePayment] = useState()
    var [directURL,setDirectURL] = useState()

    var [searchParams] = useSearchParams()

    var [direct] = useState(globalDirectMethod)
    var [inclusion] = useState(globalPaymentMethod)
    
    var [migrationMode] = useState(globalMigrationMode)
    
    var [manual] = useState(true)

    useEffect(()=>{
        window.location.href = 'https://www.tradingcircletraining.com.ph/checkout-elite'
    },[])

    useEffect(()=>{

        if(student){
            if(student.level === 'Elite') window.location.href = '/'
        }

    },[student])

    useEffect(()=>{
        setProcessing(true)
        
        if(paymentMethods.length <= 0){
            function getPaymentMethods(){
                axios.get('https://us-central1-trading-circle-ph-admin.cloudfunctions.net/app/api/payment/method',
                    {headers:{
                        "X-Requested-With": "XMLHttpRequest",   
                        'content-type': 'application/json',
                        'accept': '*/*'
                    }
                }).then((res)=>{
                    
                    var d = res.data
                    var newPMs = []

                    var i = 0
                    for(var k in d){
                        
                        if(inclusion.includes(d[k].procId)){
                            d[k].index = i;
                            newPMs.push(d[k])
                            i++;
                        }
                    
                    }
                    
                    console.log(newPMs)
                    
                    setPaymentMethods(newPMs)
                    setProcessing(false)

                }).catch((error)=>{
                    setProcessing(false)
                    console.log(error.message)
                })
            }

            getPaymentMethods()

        }else{
            setProcessing(false)
        }

    },[paymentMethods,inclusion])

    function changeSelectedPayment(){
        var index = $('#txtPaymentMethod').find(":selected").val();
        setSelectedIndex(index)
    }

    function getPaymentMethodByProcID(id){

        if(paymentMethods){
            for(var i in paymentMethods)
                if(paymentMethods[i].procId === id) return paymentMethods[i];
            
        }

    }

    function checkoutElite(){

        setProcessing(true)

        var id = uuid()
        id = id.replace(/-/g, '').slice(0,-5);

        var txnid = 'ELITE-'+id
        //console.log(txnid)

        axios.post('https://us-central1-trading-circle-ph-admin.cloudfunctions.net/app/api/payment/elite',
        {
            TransactionID:txnid,
            Email:student.profile.emailAddress,
            Description:`Elite Payment for ${student.profile.name.firstName}  ${student.profile.name.lastName} (${uid})`,
            ProcId:paymentMethods[selectedIndex].procId
        },
        {
            headers:{
                "X-Requested-With": "XMLHttpRequest",   
                'Content-Type': 'application/json',
                "Accept": "*/*"
            }
        }
        ).then((res)=>{

            if(res && res.data){

                set(ref(studentDB,`students/${uid}/payment/${txnid.includes("ELITE")?"standard":"elite"}`),{"txnid":txnid}).then(()=>{
                    
                    set(ref(studentDB,`students/${uid}/payment/url`),res.data.Url).then(()=>{

                        if(!direct.includes(paymentMethods[selectedIndex].procId)){
                            setProcessing(true)
                            $(document.body).append(`<iframe src="${res.data.Url}" id="dpay-payment" style="display:none;"/>`)
                            $('#dpay-payment').on('load',()=>{
                                window.location.reload()
                            })
                        }else{
                            window.open(res.data.Url)
                            window.location.reload()
                        }

                    })
                    
                })

            }
            
        }).catch((error)=>{
            console.log(error)
            setProcessing(false)
        })

    }

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
      
            if(student){
                
              onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{

                if(snapshot.exists()){
                    setUID(student.uid);
                    setStudent(snapshot.val());

                    if(searchParams.toString().length > 0 && !snapshot.val().payment){

                        var obj = '{'
                        
                        for (const entry of searchParams.entries()) {
                            const [param, value] = entry;

                            if(param !== 'digest' && param !== 'message')
                                obj += `"${param}":"${value}",`
                            
                        }

                        obj = obj.slice(0,-1)
                        obj += '}'
                        obj = JSON.parse(obj)
                        
                        set(ref(studentDB,`students/${student.uid}/payment/${obj.txnid.includes("ELITE")?"elite":"standard"}`),obj).then(()=>{
                            window.location.href = '/pricing'
                        }).catch((error)=>{
                            console.log(error.message)
                        })

                    }else if(searchParams.toString().length > 0) window.location.href = '/pricing'
                    

                }

            })
        
            }else setProcessing(false);

        })

    },[searchParams])

    useEffect(()=>{

        if(student && student.payment && student.payment.elite){
            
            setProcessing(true)
            axios.get(`https://us-central1-trading-circle-ph-admin.cloudfunctions.net/app/api/payment/elite/txnID/${student.payment.elite.txnid}`,
            {
                headers:{
                    "X-Requested-With": "XMLHttpRequest",   
                    'content-type': 'application/json',
                    'accept': '*/*'
                }
            }
            ).then((res)=>{

                update(ref(studentDB,`students/${uid}/payment/elite`),{status:res.data.Status}).then(()=>{

                    console.log(res.data);
                    setElitePayment(res.data)

                    onValue(ref(studentDB,`students/${uid}/payment/url`),(snapshot)=>{
                        if(snapshot.exists()){
                            setDirectURL(snapshot.val())
                            setProcessing(false)
                        }
                    }).catch((error)=>{
                        setProcessing(false)
                    })

                }).catch((error)=>{
                    console.log(error)
                })
                
                
            }).catch((error)=>{
                setProcessing(false)
                console.log(error)
            })
        }

    },[student,uid])

    function logOut(){
        studentAuth.signOut().then(()=>{window.location.href='/login'})
    }

    function verifyPayment(){
        window.location.reload()
    }
    
    function prepareVoidingPayment(id){
        setSelectedTxnID(id)
        setVoiding(true)
    }

    function claimMembership(){

        setProcessing(true)
        
        if(elitePayment){
            
            if(elitePayment.Status === 'S'){

                var d = moment().format('MMMM DD, YYYY hh:mm:ss a')

                $('#eliteForm').on('submit',()=>{

                    set(ref(studentDB,`students/${uid}/profile/enrollment/upgradeDate`),d).then(()=>{

                        set(ref(studentDB,`students/${uid}/level`),'Elite').then(()=>{
                            window.location.href = '/'
                        }).catch((error)=>{
                            console.log(error)
                        })
                            
                    })

                })

                $('#eliteForm').trigger('submit')
                
            }

        }

    }

    function voidPayment(){

        if(selectedTxnID){

            setProcessing(true)
            setVoiding(false)
            console.log(selectedTxnID)
            console.log(`students/${uid}/payment/${selectedTxnID.includes("ELITE")?"elite":"standard"}`)
            set(ref(studentDB,`students/${uid}/payment/${selectedTxnID.includes("ELITE")?"elite":"standard"}`),{}).then(()=>{

                axios.get(`https://us-central1-trading-circle-ph-admin.cloudfunctions.net/app/api/payment/void/${selectedTxnID}`,
                {
                    headers:{
                        "X-Requested-With": "XMLHttpRequest",   
                        'content-type': 'application/json',
                        'accept': '*/*'
                    }
                }
                ).then((res)=>{

                    setElitePayment(null)
                    setProcessing(false)
                    
                }).catch((error)=>{
                    setProcessing(false)
                    console.log(error.message)
                })

            }).catch((error)=>{
                console.log(error.message)
            })

            

            

        }

    }

    if(manual) return <ModalLoading show={true} />
    else{
    return(
        <>
            <Helmet>
                <title>Trading Circle - Elite Pricing</title>
            </Helmet>
            
            
            <div className="container-fluid animate__animated animate__fadeIn p-4">

                <Header title='Elite Pricing'/>

                <div className='row text-center mb-3'>
                    <div className='col-12'>
                        <div className='row justify-content-center'>
                            <div className='col-8'>
                                {!elitePayment && <img style={{borderRadius:"100rem"}} src={student?student.profile.profilePictureURL:profilePlaceholder} alt='profile' width="75px"/>}
                                <p className='mt-2 mb-3'>Hi, {student?<>{student.profile.name.firstName}</>:"There"} <small className='pointer' onClick={()=>logOut()} style={{fontSize:"12px",color:"var(--accent)"}}>(Logout)</small></p>
                                {!elitePayment && student && student.level === undefined && <p className='text-muted text-center' style={{fontSize:"14px"}}>*If you are seeing this then you still haven't selected your student setup.</p>}
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className='row justify-content-center mb-5'>

                    {!migrationMode?
                    <div className='col-lg-8 col-11'>

                        {elitePayment && paymentMethods.length > 0 && (elitePayment.Status !== 'V' && elitePayment.Status !== 'F')?
                        <div id='elite-receipt' className='row justify-content-center animate__animated animate__fadeIn payment-container' style={{position:'relative'}}>
                            <div className='col-lg-5 card-container p-4 text-center pricing-container'>
                                
                                {elitePayment.Status !== 'S' &&
                                <div className="dropdown item-action">
                                    <button className="text-dark pointer bg-transparent" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-gear text-blue"></i></button>
                                    
                                    <ul className="dropdown-menu saira" style={{transition:'0s'}}>
                                        <li>
                                            <p className="dropdown-item pointer" onClick={()=>verifyPayment()}>
                                                <i className="fa fa-refresh"></i> Verify Payment
                                            </p>
                                        </li>
                                        <li className='dropdown-divider'/>
                                        <li>
                                            <p className="dropdown-item pointer" onClick={()=>prepareVoidingPayment(elitePayment.TxnId)}>
                                                <i className="fa fa-ban"></i> Void Payment
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                }
                                
                                <h3 className='text-blue text-center saira'>Payment Status</h3>
                                <img className='my-4' style={{borderRadius:"100rem"}} src={student?student.profile.profilePictureURL:profilePlaceholder} alt='profile' width="95px"/>
                                <p className='text-center text-light-blue bold' style={{fontSize:'20px'}}>{student.profile.name.firstName} {student.profile.name.lastName}</p>
                                <p className='text-center bold text-blue' style={{fontSize:'24px'}}>{elitePayment.Currency} {numberWithCommas(elitePayment.Amount.toFixed(2))}</p>
                                <p className='text-center bold text-blue' style={{fontSize:'16px',opacity:'0.7'}}>{getPaymentMethodByProcID(elitePayment.ProcId)?getPaymentMethodByProcID(elitePayment.ProcId).longName:elitePayment.ProcId}</p>

                                <hr/>
                                <p className='text-center bold text-muted'>Ref. No. {elitePayment.RefNo}</p>
                                <p className='text-center text-muted'>{moment(elitePayment.RefDate).format('MMM. DD YYYY, hh:mm a')}</p>
                                <p className='text-center text-muted mt-2' style={{fontSize:'12px'}}>{elitePayment.Description}</p>

                                {direct.includes(elitePayment.ProcId)?
                                <a className='text-center text-light-blue small bold' href={`${directURL}`} target='_blank' rel='noreferrer'><p style={{textDecoration:'underline'}}>Settle Payment</p></a>
                                :
                                <a className='text-center text-light-blue small bold' href={`https://gw.dragonpay.ph/Bank/GetEmailInstruction.aspx?refno=${elitePayment.RefNo}`} target='_blank' rel='noreferrer'><p style={{textDecoration:'underline'}}>Payment Instructions</p></a>
                                }

                                <hr/>

                                <h5 className='text-muted bold'>STATUS</h5>
                                <h4 className='bold'>
                                    {(elitePayment.Status === 'P' || elitePayment.Status === 'U')&& <span className='text-pending'>PENDING</span>}
                                    {elitePayment.Status === 'S'&& <span className='text-success'>SUCCESS</span>}
                                </h4>
                                <hr/>
                                {(elitePayment.Status === 'P' || elitePayment.Status === 'U') &&
                                <button className='btn-solid form-control' onClick={()=>verifyPayment()}><i className='fa fa-refresh'/> Verify Payment</button>
                                }

                                {elitePayment.Status === 'S' &&
                                <>
                                    <hr/>
                                    <button className='btn-solid px-5 py-2' onClick={()=>claimMembership()}><i className='fa fa-check'/> Claim Membership</button>
                                </>
                                }

                                <p className='m-0 mt-3 bold' style={{fontSize:'16px'}}>Powered By <img src={dragonpayLogo} width='100px' alt='dp'/></p>
                            
                            </div>
                        </div>
                        :
                        student &&
                        <>
                            {/* START PAYMENT ELITE w/ Website Demo*/}
                            {!purchasing &&
                            <div className='row justify-content-center'>
                                <div className='col-lg-4 card-container-blue p-4' style={{minWidth:"350px"}}>
                                    
                                    <p className='text-center light saira text-white' style={{textDecoration:"line-through"}}>₱99,997</p>
                                    <div className='row justify-content-center my-2'>
                                        <h1 className='text-center bold m-0 text-white px-5' style={{borderRadius:"25px",width:"fit-content"}}>₱5,997</h1>
                                    </div>
                                    <h5 className='text-center m-0 bold text-white'><i className="fa-solid fa-arrow-trend-up"></i> ELITE</h5>

                                    <hr/>

                                    <div className='pricing-perks px-4'>

                                        <p className='mb-4 text-white' style={{fontSize:"14px"}}>For people who wants to go beyond their win rate.</p>

                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> All Standard Features</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> Trading Circle Advanced Course</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> Advanced Mastermind Group</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> Twice a Week Live Q and A</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> Macroeconomic Analysis</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> COT Report Template</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> One-on-One Coaching</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> Copy Trading</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> ₱100,000 Funding Program</p>
                                    </div>

                                    <button className='form-control btn-inverted mt-5' onClick={()=>setPurchasing(true)}>Purchase</button>

                                </div>
                            </div>
                            }
                            {/* END PAYMENT ELITE w/ Website Demo*/}

                            {/* START PAYMENT w/ Select Payment Method Elite*/}
                            {purchasing && paymentMethods.length > 0 &&
                                <div className='row justify-content-center animate__animated animate__fadeIn payment-container'>
                                    <div className='col-lg-5 card-container p-4 pricing-container'>
                                        <div className='row h-100'>
                                            <div className='col-12 align-self-center text-center'>
                                                <p className='text-center light saira text-light-blue' style={{textDecoration:"line-through"}}>₱99,997</p>
                                                <div className='row justify-content-center my-2'>
                                                    <h1 className='text-center bold m-0 text-light-blue px-5' style={{borderRadius:"25px",width:"fit-content"}}>₱5,997</h1>
                                                </div>
                                                <h5 className='text-center m-0 bold text-light-blue'><i className="fa-solid fa-arrow-trend-up"></i> ELITE</h5>
                                                <hr/>

                                                <h6 className='text-center text-blue bold'>Payment Method</h6>

                                                <img className='mt-3 mb-4 card-container p-2' style={{borderRadius:'25px'}} src={paymentMethods[selectedIndex].logo} width='80%' alt='logo'/>
                                                <div className='row justify-content-center'>    
                                                    <div className='col-11'>
                                                        <select id='txtPaymentMethod' className='form-select' onChange={()=>changeSelectedPayment()}>
                                                        {paymentMethods.length > 0 &&
                                                        paymentMethods.map((pm)=>{
                                                            return(<option value={pm.index} key={pm.index}>{pm.longName}</option>)
                                                        })
                                                        }
                                                        </select>
                                                        <p className='text-muted text-justify mt-2 px-3' dangerouslySetInnerHTML={{__html: paymentMethods[selectedIndex].remarks}}/>
                                                    </div>

                                                    <div className='col-lg-10 col-11 mt-5'>
                                                        <button className='btn-solid form-control' onClick={()=>checkoutElite()}><i className='fa fa-money'/> Check Out</button>
                                                    </div>
                                                </div>

                                                
                                                
                                                <p className='m-0 mt-4 bold' style={{fontSize:'16px'}}>Powered By <img src={dragonpayLogo} width='100px' alt='dp'/></p>
                                                <hr/>
                                                <p className='p-3 text-muted text-justify'>*Select your Payment Method, Ensure that your email is correct when you have been prompted for it.<br/><br/>*Follow the instructions of DragonPay and Pay the indicated Amount.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* END PAYMENT w/ Select Payment Method Elite*/}
                        </>
                        }


                    </div>
                    :
                    <div className='col-lg-4 col-11 mt-5 card-container py-5'>
                        <h3 className='text-center text-blue mb-1'><i className='fa fa-lock'/> System is in Migration Mode</h3>
                        <p className='text-center text-blue'>Upgrading to Elite is temporarily disabled.</p>
                    </div>
                    }

                </div>

            </div>

            {student &&
            <form method="post" id="eliteForm" action="https://app.getresponse.com/add_subscriber.html" style={{display:'none'}}>
                
                <p className='bold text-blue mt-3'>Name</p>
                <input name='name' id="txtName" value={student.profile.name.firstName} readOnly className='form-control text-muted' type="text"/>

                <p className='bold text-blue mt-3'>Email Address</p>
                <input name='email' id="txtEmailAddress" value={student.profile.emailAddress} readOnly className='form-control text-muted' type="text"/>
                
                <input type="hidden" name="start_day" value="0"/>
                <input type="hidden" name="campaign_token" value="r0wMr"/>
                <input type='submit'/>

            </form>
            }
            
            <ModalConfirmation show={voiding} onHide={()=>setVoiding(false)} onConfirm={()=>voidPayment()} message="Do you want to void your Pending Payment?"/>
            <ModalLoading show={processing} />
        </>
    );
    }

}