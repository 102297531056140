import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import $ from 'jquery'

export default function ModalDemoAcount(props) {

  var [showingPassword,setShowingPassword] = useState(true)

  function toggleShowPassword(){
    $('#txtMT4Password').attr('type',showingPassword?'password':'text')
    setShowingPassword(!showingPassword)
  }

  function setDefault(){
    toggleShowPassword()
  }

  useEffect(()=>{
    if(!props.demoAccount) props.onHide();
  },[props])

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onEntering={()=>setDefault()}
      > 

      <Modal.Header className='bg-blue' closeButton>
        
        <h4 className='saira text-center text-white m-0'><i className='fa fa-gem'/> MT 4 Account</h4>

      </Modal.Header>

        <Modal.Body className='bg-white-blue' style={{borderRadius:"30px"}}>

          {props.demoAccount &&
          <div className='row justify-content-center py-2 px-3'>
            
            <div className='col-8'>
              <h6 className='text-blue m-0 mb-2 bold'><i className='fa fa-gem'/> MT4 ID</h6>
              <input className='form-control text-blue mb-4' type='text' value={props.demoAccount.id} readOnly/>
            </div>

            <div className='col-8 mb-4'>

              <h6 className='text-blue m-0 mb-2 bold'><i className='fa fa-lock'/> MT4 Password</h6>
              <div class="input-group">
                <input id='txtMT4Password' type="password" class="form-control text-blue" value={props.demoAccount.password} readOnly/>
                <div class="input-group-prepend">
                    <span class="input-group-text pointer" onClick={()=>toggleShowPassword()}><i className={showingPassword?'fa fa-eye-slash':'fa fa-eye'}/></span>
                </div>
              </div>

            </div>

            <div className='col-10 mb-2'>
              <p className='small text-blue text-center'>You can only use this <b>Demo Account</b> for this <b>current</b> challenge.</p>
            </div>

            
            
          </div>
          }

        </Modal.Body>

      </Modal>

    </>
  );
}