import { Helmet } from "react-helmet-async"
import { PolicySidebar } from "../../components/general/PolicySidebar"


export const TermsAndConditions = () =>{

    return(
        <div className="container-fluid">

            <Helmet>
                <title>Trading Circle - Terms & Conditions</title>
            </Helmet>

            <div className="row justify-content-center p-lg-5 p-0">

                <PolicySidebar/>

                <div className="col-lg-6 text-blue policy-container ps-lg-4 px-5 animate__animated animate__fadeIn">
                    <h3 className="bold saira mt-lg-5 mt-4">Terms & Conditions</h3>
                    <h5 className="bold saira">What is the Terms & Conditions and what does it cover?</h5>

                    <div className="content mt-3 mb-5">
                        <p className="mb-4 text-justify">The terms and conditions contained herein including the Privacy Policy which is incorporated by reference as if fully set forth herein (to be read in combination and referred to herein in their entirety as “The Agreement”) shall be binding on each and every customer who accesses www.tradingcircle.com.ph and shall inure in the benefit of Circle Online Educational Consultancy Services https://www.tradingcircle.com.ph (“Company”) and Company’s successors and assigns AND (2) SUPERSEDE AND REPLACE ANY INCONSISTENT STATEMENT IN ANY OF OUR MATERIALS, ADVERTISEMENTS OR WEBSITES.</p>
                        <p className="mb-4 text-justify">THESE TERMS AND CONDITIONS ARE LEGALLY BINDING ON YOU WITH RESPECT TO ANY “TRANSACTION” AS THAT TERM IS DEFINED BELOW. BY ACCESSING THE SERVICE, YOU AGREE TO THESE TERMS AND CONDITIONS AS A PREREQUISITE TO ANY OF THE AFOREMENTIONED ACTIVITIES WITH US.</p>
                        <p className="mb-4 text-justify">The terms "you" and "your" refer to the person(s) or entity wishing to purchase the Company materials (collectively hereinafter referred to as "Materials"), as well as accessing or using our websites and providing us with your personal information. (collectively hereinafter referred to as "Transaction"). "We", "our", and "us" refer to Company and its employees, agents, members, owners, directors, officers, successors and assigns. By using our services and/or purchasing our Materials, you agree to the terms and conditions set forth herein.</p>
                    </div>

                    <h5 className="bold saira">Eligibility</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-4 text-justify">By using the Website or Services, you represent and warrant that you are at least 18 years old, a resident of the Philippines, and are otherwise legally qualified to enter into and form contracts under applicable law. This Agreement is void where prohibited.</p>
                    </div>

                    <h5 className="bold saira">Method of Payment & Refund Policy</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-4 text-justify">The Company offers its Materials for a one-time payment. For these Materials, a valid debit or credit card will be required to complete the purchase, and the amount of the purchase will be charged to the debit or credit card concurrently with the completion of the purchase on the website.</p>
                        <p className="mb-4 text-justify">When you purchase an individual product, you will receive a streaming or digital version; no physical copies of products or other materials will be shipped or provided to you.</p>
                        <p className="mb-4 text-justify">All payments made to the Company in connection with the Materials purchased are refundable only if properly implemented and proven not working; and the Company does not offer, and is not required to provide, any refunds or credits for any reason, including, without limitation, satisfaction of the Materials. There is no circumstance in which you will be entitled to, or the Company is required to provide, a refund or credit for any reason, including, without limitation, satisfaction of the materials.</p>
                    </div>

                </div>

            </div>

        </div>
    )

}