
import { Helmet } from 'react-helmet-async';

import ModalLoading from '../../components/general/ModalLoading';
import { useEffect, useState } from 'react';

import {Header} from '../../components/general/Header'
import { onValue, ref } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import { studentAuth, studentDB } from '../../api/firebase-student-config';
import { Footer } from '../../components/academy/Footer';

export const Welcome = () =>{

    const [processing,setProcessing] = useState(false);

    //var [uid,setUID] = useState(null);
    //var [student,setStudent] = useState(null);

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
      
            if(student){
        
              onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
                if(snapshot.exists()){
                    //setUID(student.uid);
                    //setStudent(snapshot.val());
                    setProcessing(false);
                }
              })
        
            }else setProcessing(false);
        
          })

    },[])

    return(
        <>
            <Helmet>
                <title>Trading Circle - Welcome</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn p-4">

                <Header title='Welcome'/>

                <div className='row justify-content-center mb-5'>
                    <div className='col-12 text-center'>
                        <iframe width="100%" height="460px" autoplay="1" allow="autoplay; fullscreen" allowfullscreen sandbox="allow-scripts allow-forms allow-same-origin" src="https://player.vimeo.com/video/728348075?h=ee980b7a2d&amp;autoplay=1" title="Welcome Video"></iframe>
                    </div>
                </div>

            </div>
            
            <Footer/>
            <ModalLoading show={processing} />
        </>
    );

}