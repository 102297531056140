import { Helmet } from "react-helmet-async"
import { Footer } from "../../components/landing/Footer"
import { Header } from "../../components/general/Header"
import { NavLink } from 'react-router-dom';
import { useState } from "react";
import { hasCorrectEmail } from "../../helper/formatter";
import $ from 'jquery'
import axios from 'axios'
import ModalLoading from "../../components/general/ModalLoading";

export const Support = ()=>{

    const [processing,setProcessing] = useState(false);
    var[name,setName] = useState()
    var[emailAddress,setEmailAddress] = useState()
    var[concern,setConcern] = useState('Enrollment')
    var[message,setMessage] = useState()

    var[sent,setSent] = useState(false)

    function verifyName(){

        var txtName = $('#txtName').val();
        var errName = $('#errName');
        
        errName.text('')
        setName(null);

        if(txtName.trim().length === 0) errName.text('Blank spaces are not allowed.');
        else if(txtName.length <= 1) errName.text('Atleast 2 Characters.');
        else setName(txtName);

    }

    function verifyEmailAddress(){

        var txtEmailAddress = $('#txtEmailAddress').val();
        var errEmailAddress = $('#errEmailAddress');
        
        errEmailAddress.text('')
        setEmailAddress(null);

        if(txtEmailAddress.trim().length === 0) errEmailAddress.text('Blank spaces are not allowed.');
        else if(!hasCorrectEmail(txtEmailAddress.trimEnd())) errEmailAddress.text('Invalid Email Format.');
        else setEmailAddress(txtEmailAddress);

    }

    function verifyConcern(){
        var slcConcern = $('#slcConcern').find(":selected").text();
        setConcern(slcConcern)
    }

    function verifyMessage(){
        var txtMessage = $('#txtMessage').val();
        var errMessage = $('#errMessage');
        
        errMessage.text('')
        setMessage(null);

        if(txtMessage.trim().length === 0) errMessage.text('Blank spaces are not allowed.');
        else if(txtMessage.length <= 10) errMessage.text('Atleast 10 Characters.');
        else setMessage(txtMessage);
    }

    function sendMail(){

        setProcessing(true)

        axios.post('https://us-central1-trading-circle-ph-admin.cloudfunctions.net/app/api/support/sendMail',
        {
            "name":name,
            "email":emailAddress,
            "concern":concern,
            "message":message
        },{
            headers:{
                "X-Requested-With": "XMLHttpRequest",   
                'content-type': 'application/json',
                'accept': '*/*'
            }       
        }
        ).then((res)=>{
            
            if(res.status === 200){

                setSent(true)
                setProcessing(false)

            }else{
                console.log(res)
                setProcessing(false)
            }

        }).catch((error)=>{
            setProcessing(false)
            console.log(error)
        })

    }
    
    return(
        <>
            <Helmet>
                <title>Trading Circle - Support</title>
            </Helmet>

            <div className="container-fluid animate__animated animate__fadeIn p-lg-4 p-0 my-5">
                <Header title='Support'/>

                <div className="row justify-content-center" style={{gap:'30px'}}>

                    <div className="col-lg-5 support-container">

                        <div className="row justify-content-center h-100">

                            <div className="support-form-container col-10 card-container-debossed py-lg-5 px-0 px-4 py-4 align-self-center">

                                <h5 className="text-center black text-blue">
                                    <span class="fa-solid fa-headset mb-3" style={{fontSize:'64px'}}/>
                                    <br/>
                                    Reach out to our Support Team
                                </h5>
                                <div className='row justify-content-center'>
                                    <div className='col-8'>
                                        <hr className='px-5 m-0 blue-hr' style={{opacity:"0.5"}}/>
                                    </div>
                                </div>
                                <h6 className="text-center my-2 bold text-blue">Send us an Email</h6>

                                <div className="row justify-content-center mt-4" style={{gap:'10px'}}>

                                    {!sent?
                                    <>
                                        <div className="col-lg-10 col-12">
                                            <input id="txtName" onChange={()=>verifyName()} className="form-control" placeholder="Name" type='text'/>
                                            <small id="errName" class="form-text text-danger text-center mb-3 mt-2"></small>
                                        </div>

                                        <div className="col-lg-10 col-12">
                                            <input id="txtEmailAddress" onChange={()=>verifyEmailAddress()} className="form-control" placeholder="Email Address" type='text'/>
                                            <small id="errEmailAddress" class="form-text text-danger text-center mb-3 mt-2"></small>
                                        </div>

                                        <div className="col-lg-10 col-12">
                                            <select id="slcConcern" onChange={()=>verifyConcern()} className="form-select">
                                                <option value="Enrollment">Enrollment</option>
                                                <option value="Payment">Payment</option>
                                                <option value="Website Access">Website Access</option>
                                            </select>
                                        </div>

                                        <div className="col-lg-10 col-12">

                                            <textarea id="txtMessage" onChange={()=>verifyMessage()} className="form-control mt-3" rows={5} placeholder="Message" style={{resize:'none'}}/>
                                            <small id="errMessage" class="form-text text-danger text-center mb-3 mt-2"></small>

                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="col-lg-10 col-12">
                                            <input value={name} readOnly className="form-control" placeholder="Name" type='text'/>
                                        </div>

                                        <div className="col-lg-10 col-12">
                                            <input value={emailAddress} className="form-control" placeholder="Email Address" type='text'/>
                                        </div>

                                        <div className="col-lg-10 col-12">
                                            <select value={concern} disabled className="form-select">
                                                <option value="Enrollment">Enrollment</option>
                                                <option value="Payment">Payment</option>
                                                <option value="Website Access">Website Access</option>
                                            </select>
                                        </div>

                                        <div className="col-lg-10 col-12">
                                            <textarea value={message} readOnly onChange={()=>verifyMessage()} className="form-control mt-3" rows={5} placeholder="Message" style={{resize:'none'}}/>
                                        </div>
                                    </>
                                    }
                                    
                                    <div className="col-lg-10 col-12 mt-3">
                                        {name && emailAddress && concern && message && !sent?
                                        <button className="form-control btn-dark-blue" onClick={()=>sendMail()}>Submit</button>
                                        :
                                        <button className="form-control btn-dark-blue" disabled>Submit</button>
                                        }
                                        {sent && <p className="text-success text-center mt-2"><i className="fa fa-check"/> Concern Sent!</p>}
                                    </div>

                                </div>

                            </div>

                        </div>


                    </div>

                    <div className="col-lg-4 p-5 support-container">

                        <h5 className="text-center black text-blue">
                            <span class="fa-solid fa-clipboard-question mb-3" style={{fontSize:'64px'}}/>
                            <br/>
                            FREQUENTLY ASKED QUESTIONS
                        </h5>
                        <div className='row justify-content-center'>
                            <div className='col-8'>
                                <hr className='px-5 m-0 blue-hr' style={{opacity:"0.5"}}/>
                            </div>
                        </div>
                        <h6 className="text-center my-2 bold text-blue">We Are Here To Help</h6>

                        <div class="accordion mt-4" id="accordionFAQ">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                                    How can I be part of Trading Circle?
                                </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionFAQ">
                                    <div class="accordion-body">
                                        Sign up for an account here. When your account is created, go to the payments tab, and settle your account.
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                                    What do I do if I have a problem with my purchase?
                                </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div class="accordion-body">
                                        Please reach out to support if you are having issues with your membership purchase.
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                                    Do you have testimonials?
                                </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div class="accordion-body">
                                        Yes. You can view them <NavLink to='/home#testimonials'>here</NavLink>.
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFour">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                                    Are we guaranteed to succeed when we join your academy?
                                </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div class="accordion-body">
                                        The success will always be dependent on the student. We strongly believe that you can turn profitable as long as you follow everything on the course site.
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFive">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive">
                                    Is the standard subscription one-time payment?
                                </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div class="accordion-body">
                                        Yes, you may access it anytime you wish. It is a lifetime access.
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingSix">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix">
                                    Who can I contact for billing issue?
                                </button>
                                </h2>
                                <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div class="accordion-body">
                                        Send your message to the support team area on this website.
                                    </div>
                                </div>
                            </div>
                            
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingSeven">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven">
                                    Will you refund our payment if we did not learn anything?
                                </button>
                                </h2>
                                <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div class="accordion-body">
                                        Yes! 100% of your payment will be refunded if you did not get any value from our academy in 2 months.
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                    </div>

                </div>
            </div>
            <Footer/>
            <ModalLoading show={processing}/>
        </>
    )
}