import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const forumConfig = {
  apiKey: "AIzaSyALNJyqbbXefKpulB6pF8eiaHsnP1VKv30",
  authDomain: "tradingcircle-ph.firebaseapp.com",
  databaseURL: "https://tradingcircle-ph-forum.firebaseio.com",
  projectId: "tradingcircle-ph",
  storageBucket: "tradingcircle-ph-forum",
  messagingSenderId: "196432718257",
  appId: "1:196432718257:web:6fde4c7f2cc9cd835bf590",
  measurementId: "G-MMV6JVZCQ1"
};

const app = initializeApp(forumConfig,'Forum Configuration');

export const forumDB = getDatabase(app);
export const forumStorage = getStorage(app);

