import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import $ from 'jquery'

import { globalRegions, globalProvinces } from '../../helper/globals';
import {hasLetter, hasSpecial, toTitleCase} from '../../helper/formatter'
import ModalLoading from '../general/ModalLoading';
import { ref, set } from 'firebase/database';
import { studentDB } from '../../api/firebase-student-config';

export default function ModalClaimPrize(props) {

  const [region,setRegion] = useState('Metro Manila')
  const [province,setProvince] = useState('Metro Manila')
  const [postalCode,setPostalCode] = useState()
  const [city,setCity] = useState()
  const [barangay,setBarangay] = useState()
  const [address,setAddress] = useState()

  const [size,setSize] = useState('XS')

  const [processing,setProcessing] = useState(false)

  function setDefault(){
    if(props.claimData){

      var cd = props.claimData
      console.log(cd)

      setSize(cd.size)

      setRegion(cd.detailedAddress.region)
      setProvince(cd.detailedAddress.province)
      setPostalCode(cd.detailedAddress.postalCode)
      setCity(cd.detailedAddress.city)
      setBarangay(cd.detailedAddress.barangay)
      setAddress(cd.detailedAddress.address)


      $('#slcSize').val(cd.size)

      $('#slcRegion').val(cd.detailedAddress.region)
      $('#slcProvince').val(cd.detailedAddress.province)
      $('#txtPostalCode').val(cd.detailedAddress.postalCode)
      $('#txtCity').val(cd.detailedAddress.city)
      $('#txtBarangay').val(cd.detailedAddress.barangay)
      $('#txtAddress').val(cd.detailedAddress.address)

    }
  }

  function verifySize(){
    var slcSize = $('#slcSize').find(":selected").val();
    setSize(slcSize)
  }

  function verifyRegion(){

    var r = $('#slcRegion').find(":selected").val();
    setRegion(r)

    setProvince(globalProvinces[r][0])

  }

  function verifyProvince(){

    var p = $('#slcProvince').find(":selected").val();
    setProvince(p)

  }

  function verifyPostalCode(){

    var txtPostalCode = $('#txtPostalCode').val()
    var errPostalCode = $('#errPostalCode')

    errPostalCode.text('')
    setPostalCode('')

    if(txtPostalCode.length <= 0) errPostalCode.text('Do not leave blank fields.')
    else if(hasLetter(txtPostalCode) || hasSpecial(txtPostalCode) || txtPostalCode.length !== 4) errPostalCode.text('Invalid Postal Code')
    else setPostalCode(txtPostalCode)
    

  }

  function verifyCity(){

    var txtCity = $('#txtCity').val()
    var errCity = $('#errCity')

    errCity.text('')
    setCity('')

    if(txtCity.length <= 0) errCity.text('Do not leave blank fields.')
    else if(txtCity.length < 3) errCity.text('Atleast 3 Characters.')
    else setCity(toTitleCase(txtCity))

  }

  function verifyBarangay(){

    var txtBarangay = $('#txtBarangay').val()
    var errBarangay = $('#errBarangay')

    errBarangay.text('')
    setBarangay('')

    if(txtBarangay.length <= 0) errBarangay.text('Do not leave blank fields.')
    else if(txtBarangay.length < 3) errBarangay.text('Atleast 3 Characters.')
    else setBarangay(toTitleCase(txtBarangay))
    
  }

  function verifyAddress(){

    var txtAddress = $('#txtAddress').val()
    var errAddress = $('#errAddress')

    errAddress.text('')
    setAddress('')

    if(txtAddress.length <= 0) errAddress.text('Do not leave blank fields.')
    else if(txtAddress.length < 10) errAddress.text('Atleast 10 Characters.')
    else setAddress(toTitleCase(txtAddress))
    
  }

  function submitDetails(){

    setProcessing(true)
    
    var fullAddress = `${region}, ${province}, ${postalCode} ${city}, ${barangay}, ${address}`
    var body = {
      status:'PENDING',
      address:fullAddress,
      detailedAddress:{
        region:region,
        province:province,
        postalCode:postalCode,
        city:city,
        barangay:barangay,
        address:address
      },
      size:size
    }

    set(ref(studentDB,`students/${props.uid}/challenge/`),body).then(()=>{
      setProcessing(false)
      props.onHide()
    })

  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onShow={()=>setDefault()}
      > 

      <Modal.Header className='bg-blue' closeButton>
        
        <h4 className='saira text-center text-white m-0'><i className='fa fa-trophy'/> Claim Prize</h4>

      </Modal.Header>

        <Modal.Body className='bg-white-blue' style={{borderRadius:"30px"}}>
          {props.student &&
          <div className='row justify-content-center px-3 py-2' style={{rowGap:'15px'}}>

            <h4 className='text-blue saira bold mb-0'>Details</h4>
            <hr className='m-0'/>

            <div className='col-lg-8'>
              <p className='text-blue saira ms-2 bold'>Full Name</p>
              <input className='form-control' type='text' placeholder='Full Name' value={`${props.student.profile.name.firstName} ${props.student.profile.name.lastName}`} disabled/>
            </div>

            <div className='col-lg-4'>
              <p className='text-blue saira ms-2 bold'>Phone Number</p>
              <input className='form-control' type='text' placeholder='Phone Number' value={`${props.student.profile.contactNumber}`} disabled/>
            </div>

            <p className='text-muted small ms-3'>*You can edit your name and number on your profile.</p>
            
            <div className='col-lg-12'>
              <p className='text-blue saira ms-2 bold'>T-Shirt Size</p>
              <select className='form-select' id='slcSize' onChange={()=>verifySize()}>
                <option value='XS'>XS (Extra Small)</option>
                <option value='S'>S (Small)</option>
                <option value='M'>M (Medium)</option>
                <option value='L'>L (Large)</option>
                <option value='XL'>XL (Extra Large)</option>
              </select>
            </div>

            <h4 className='text-blue saira bold mb-0 mt-3'>Address</h4>
            <hr className='m-0'/>

            <div className='col-lg-12'>
              <p className='text-blue saira ms-2 bold'>Region</p>
              <select className='form-select' id='slcRegion' onChange={()=>verifyRegion()}>
                {globalRegions.map((r)=>{return(<option key={r} value={r}>{r}</option>)})}
              </select>
            </div>

            <div className='col-lg-12'>
              <p className='text-blue saira ms-2 bold'>Province</p>
              <select className='form-select' id='slcProvince' onChange={()=>verifyProvince()}>
                {globalProvinces[region].map((p)=>{return(<option key={p} value={p}>{p}</option>)})}
              </select>
            </div>

            <div className='col-lg-4 col-5'>
              <p className='text-blue saira ms-2 bold'>Postal Code</p>
              <input id='txtPostalCode' className='form-control' type='text' maxLength={4} onChange={()=>verifyPostalCode()}/>
              <small id='errPostalCode' className='text-error'></small>
            </div>

            <div className='col-lg-8 col-7'>
              <p className='text-blue saira ms-2 bold'>City</p>
              <input id='txtCity' className='form-control' type='text' onChange={()=>verifyCity()}/>
              <small id='errCity' className='text-error'></small>
            </div>

            <div className='col-12'>
              <p className='text-blue saira ms-2 bold'>Barangay</p>
              <input id='txtBarangay' className='form-control' type='text' onChange={()=>verifyBarangay()}/>
              <small id='errBarangay' className='text-error'></small>
            </div>

            <div className='col-12'>
              <p className='text-blue saira ms-2 bold'>Address Line <small style={{fontSize:'12px'}}>(Subdv., St. Name, House No.)</small></p>
              <input id='txtAddress' className='form-control' type='text' onChange={()=>verifyAddress()}/>
              <small id='errAddress' className='text-error'></small>
            </div>

            <hr className='m-0'/>

            <div className='col-12'>
              <p className='text-blue saira ms-2 bold'>Full Address</p>
              <p className='text-blue small ms-3'>{region}, {province}, {postalCode} {city}, {barangay}, {address}</p>
            </div>

            <hr className='m-0'/>

            <div className='col-12'>
              {size && region && province && postalCode && city && barangay && address?
              <button className='btn-solid w-100 py-2' onClick={()=>submitDetails()}> <i className='fa fa-box'/> Submit Details</button>
              :
              <button className='btn-solid w-100 py-2' disabled> <i className='fa fa-box'/> Submit Details</button>
              }
            </div>

          </div>
          }

        </Modal.Body>

      </Modal>

      <ModalLoading show={processing}/>

    </>
  );
}