import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import placeholder from '../../img/placeholder/profile-placeholder.png'
import { allowedAttachments, allowedImages, globalCoaches, globalDevelopers } from '../../helper/globals';
import { studentDB } from '../../api/firebase-student-config';
import { onValue, push, ref, set } from 'firebase/database';
import {getDownloadURL, ref as sref} from 'firebase/storage'

import $ from 'jquery'
import ModalViewImage from '../general/ModalViewImage';
import { forumDB, forumStorage } from '../../api/firebase-forum-config';
import ModalLoading from '../general/ModalLoading';
import { uploadBytes } from 'firebase/storage';

import moment from 'moment'
import ModalAlert from '../general/ModalAlert';
import { getFileExt } from '../../helper/formatter';

export default function ModalCreatePost(props) {

  const [innerCircle,setInnerCircle] = useState('N/A')

  const [content,setContent] = useState('')

  const [fileAttachment,setFileAttachment] = useState()
  const [fileAttachmentURL,setFileAttachmentURL] = useState()

  const [imageFiles,setImageFiles] = useState([])
  const [imageURLs,setImageURLs] = useState([])

  const [fileAttachmentDownloadURL, setFileAttachmentDownloadURL] = useState([])
  const [imageFilesDownloadURL, setImageFilesDownloadURL] = useState([])

  const [selectedImage,setSelectedImage] = useState(false)

  const [processing, setProcessing] = useState(false)

  const [alertMessage,setAlertMessage] = useState()

  function setDefault(){
    setContent('')
    
    setFileAttachment(null)
    setFileAttachmentURL(null)

    setImageFiles([])
    setImageURLs([])

    setFileAttachmentDownloadURL([])
    setImageFilesDownloadURL([])
  }

  useEffect(()=>{

    if(props.uid){
      onValue(ref(studentDB,'partnershipRequests/' + props.uid),(snap)=>{
        if(snap.exists()){
            setInnerCircle(snap.val().status)
        }
        else{
            setInnerCircle('N/A')
        }
      })
    }

  },[props.uid])

  function verifyContent(){

    if($('#txtContent').text().trim().length > 0){
      setContent($('#txtContent').html())
    }else setContent('')

  }

  useEffect(()=>{

    if(content.length > 0){
      $('#txtPlaceholder').css('display','none')
    }else{
      $('#txtPlaceholder').css('display','block')
    }

    console.log(content)

  },[content])

  function uploadImages(){

    $('#fileImage').trigger('click')

  }

  function uploadAttachment(){
    $('#fileAttachment').trigger('click')
  }

  function getAttachment(){

    var files = $('#fileAttachment').prop('files')

    if(!allowedAttachments.includes(getFileExt(files[0].name).toUpperCase())){
      setAlertMessage(`An invalid file was selected we only accept attachment files in these format:<br/><b>${allowedAttachments}</b>.<br/><br/> File selected is a <b>${getFileExt(files[0].name).toUpperCase()}</b> file.`)
      return
    }

    if(files[0].size / 1000000 > 5){
      setAlertMessage(`Attachment size is too large only <b>5MB</b> is allowed.<br/><br/> File being uploaded is <b>${parseFloat(files[0].size/1000000).toFixed(2)}MB</b>`)
      return
    }

    setFileAttachment(files[0])
    setFileAttachmentURL(URL.createObjectURL(files[0]))

  }

  function getImages(){
    
    var files = $('#fileImage').prop('files')

    var fileList = []

    for(var k in files){
      if(k !== 'length' && k !=='item'){
        if(allowedImages.includes(getFileExt(files[k].name).toUpperCase())){
          fileList.push(files[k])
        }else{
          setAlertMessage(`An invalid file was selected we only accept image files in these format:<br/><b>${allowedImages}</b>.<br/><br/> File selected is a <b>${getFileExt(files[k].name).toUpperCase()}</b> file.`)
          return
        }
      }
    }

    for(k in fileList){

      if(fileList[k].size / 1000000 > 5){
        setAlertMessage(`Image size is too large only <b>5MB</b> is allowed.<br/><br/> File being uploaded is <b>${parseFloat(fileList[k].size/1000000).toFixed(2)}MB</b>`)
        return
      }

    }

    var newImages = [...imageFiles]
    var newURLs = [...imageURLs]

    for(var i in fileList){
      newImages.push(fileList[i])
      newURLs.push(
        {
        key:i,
        url:URL.createObjectURL(fileList[i])
        }
      )
    }

    if(newImages.length > 4){

      setAlertMessage(`Only <b>4 images</b> are allowed in a post.<br/><br/>You selected <b>${newImages.length} images.</b>`)

    }else{
      setImageFiles(newImages)
      setImageURLs(newURLs)
    }
    $('#fileImage').val(null)

  }

  function removeImage(key){

    var newImages = [...imageFiles]
    var newURLs = [...imageURLs]

    newImages[key] = null
    newURLs[key] = null

    var filteredImages = newImages.filter((a)=>{
      return a !== null
    })

    var filteredURLs = newURLs.filter((a)=>{
      return a !== null
    })

    newImages = [...filteredImages]
    newURLs = [...filteredURLs]

    for(var k in newURLs){
      newURLs[k].key = k
    }

    console.log(newURLs)

    setImageFiles(newImages)
    setImageURLs(newURLs)
  }

  function deleteFileAttachment(){
    setFileAttachment(null)
    setFileAttachmentURL(null)
  }

  function post(){

    setProcessing(true)

    var pushRef = push(ref(forumDB,`${props.level}/forum`))
    var key = String(pushRef).split('/')[String(pushRef).split('/').length-1]

    postAttachment(fileAttachment,key)

  }

  function postAttachment(attachment,key){

    if(attachment){

      uploadBytes(sref(forumStorage,`${props.level}/${key}/${fileAttachment.name}`),fileAttachment).then((res)=>{
        

        getDownloadURL(res.ref).then((url)=>{
          fileAttachmentDownloadURL.push(url)
          postImages([...imageFiles],key)
        })

      })

    }else postImages([...imageFiles],key)
    

  }

  function postImages(images, key){
    console.log(images)
    if(images.length > 0){

      uploadBytes(sref(forumStorage,`${props.level}/${key}/${images[0].name}`),images[0]).then((res)=>{
        
        getDownloadURL(res.ref).then((url)=>{
          imageFilesDownloadURL.push(url)
          images.shift()
          postImages(images,key)
        })

      })

    }else publishPost(key)

  }

  function publishPost(key){

    var now = moment().format('MMMM DD, YYYY hh:mm:ss a')

    var post ={
      content:content.length > 0?content:null,
      attachment:{
        url:fileAttachmentDownloadURL.length > 0?fileAttachmentDownloadURL[0]:null,
        name:fileAttachment?fileAttachment.name:null
      },
      images:[...imageFilesDownloadURL],
      datePosted:now,
      owner:props.uid
    }

    set(ref(forumDB,`${props.level}/posts/${key}`),post).then(()=>{
      setProcessing(false)
      props.onHide()
      setDefault()
    })

  }

  function regulatePaste(e){

    e.preventDefault();

    var item = e.clipboardData.items[0];
    console.log(e.clipboardData.items)
 
    if (item.type.indexOf("image") === 0)
    {

        var blob = item.getAsFile();
 
        var reader = new FileReader();
        reader.onload = function(event) {
          setImageURLs([{
            key:0,
            url:event.target.result
          }]);
        };
 
        reader.readAsDataURL(blob);
    }else{

      let paste = (e.clipboardData || window.clipboardData).getData('text/html');

      const selection = window.getSelection();
      if (!selection.rangeCount) return;
      selection.deleteFromDocument();

      var newDiv = document.createElement('span')
      newDiv.innerHTML = paste

      var links = newDiv.getElementsByTagName('a')
      if(links.length > 0){
        
        for(var i = 0; i < links.length;i++){
          links[i].innerHTML = links[i].href
          links[i].target = '_blank'
        }

      }else{
        paste = (e.clipboardData || window.clipboardData).getData('text');
        newDiv.innerHTML = paste
      }

      selection.getRangeAt(0).insertNode(newDiv);
      selection.collapseToEnd();

    }
  
  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onShow={()=>setDefault()}
      > 
        
        <Modal.Body className='bg-white-blue' style={{borderRadius:"30px"}}>

          <div className='row py-2 px-3'>

            <div className='col-12' style={{position:'relative'}}>
              <i className='fa fa-close pointer' onClick={()=>props.onHide()} style={{position:'absolute',right:'0'}}/>
            </div>

            <div className='col-12 create-post-container'>
              
              <div className="row mb-3">
                <div className='col-4 px-0' style={{width:'fit-content'}}>
                  <img className="profile-picture" src={props.student?props.student.profile.profilePictureURL:placeholder} alt="profile"/>
                </div>
                <div className="col-8 align-self-center">
                    <p className="align-self-center bold text-blue">{props.student && props.student.profile.name.firstName + ' ' + props.student.profile.name.lastName}</p>

                    {props.student && props.student.level === 'Standard' && <span className='small pill'><i className='fa fa-user'/> <span>Standard</span></span>}
                    {props.student && props.student.level === 'Elite' && <span className='small pill'><i className='fa fa-bolt'/> <span>Elite</span></span>}
                    {innerCircle !== 'N/A' && <span className='small pill'><i className='fa-brands fa-discourse'/> <span className='d-lg-inline d-none'>Inner Circle</span></span>}
                    {props.student &&  globalDevelopers.includes(props.student.profile.emailAddress) && <span className='small pill'><i className='fa fa-computer'/> <span className='d-lg-inline d-none'>Developer</span></span>}
                    {props.student &&  globalCoaches.includes(props.student.profile.emailAddress) && <span className='small pill'><i className='fa fa-star'/> <span className='d-lg-inline d-none'>Coach</span></span>}
                
                </div>
                
              </div>

              <div className='row'>

                <div className='col-12'>
                  <p className='content-placeholder' id='txtPlaceholder' style={{position:'absolute',top:'90px',userSelect:'none',zIndex:'0',pointerEvents:'none'}}>What's on your mind, {props.student && props.student.profile.name.firstName}</p>
                  <p contentEditable onPaste={(e)=>regulatePaste(e)} id='txtContent' onKeyUp={()=>verifyContent()}></p>
                </div>

                <div className='col-12 mt-3'> 

                  {fileAttachment && fileAttachmentURL &&
                  <div className='row create-post-attachment-container mt-2 mb-3 py-2 px-2' style={{gap:'10px'}}>

                    <div className='col-12'>
                      <a href={fileAttachmentURL} target='_blank' rel='noreferrer'><i className='fa fa-paperclip'/> {fileAttachment.name}</a>
                    </div>

                    <div className='delete-attachment' onClick={()=>deleteFileAttachment()} style={{width:'fit-content'}}>
                      <i className='fa fa-close'/>
                    </div>

                  </div>
                  }

                  <div className='row' style={{gap:'10px'}}>

                    {imageURLs.map((file)=>{
                      
                      return(
                        <div className='col-3 px-0 create-post-image-container' style={{width:'fit-content'}}>
                          <div className='create-post-image' style={{backgroundImage:`url('${file.url}')`}} onClick={()=>setSelectedImage(file.url)}/>
                          <p className='text-center'>
                            <i className='fa fa-trash pointer mt-1' onClick={()=>removeImage(file.key)}/>
                          </p>
                        </div>
                      )

                    })}

                    <div className='col-12'>
                      <p className='small text-error' id='errImageUpload'></p>
                      <p className='small text-muted'>*You can upload up to 4 images</p>
                    </div>

                  </div>

                </div>

                <div className='col-12 mt-3'>
                  <div className='row px-2 py-3 post-addition'>
                    <div className='col-6 align-self-center'>
                      <p style={{fontSize:'calc(0.2vw + 0.8rem)'}}>Add to your Post</p>
                    </div>

                    <div className='col-6 text-end text-blue' style={{fontSize:'calc(0.2vw + 0.95rem)'}}>
                      <i className='fa fa-paperclip pointer me-3' onClick={()=>uploadAttachment()}/>
                      <i className='fa fa-image pointer' onClick={()=>uploadImages()}/>
                    </div>

                    <input onChange={()=>getImages()} id='fileImage' type='file' accept='image/*' multiple style={{display:'none'}}/>

                    <input onChange={()=>getAttachment()} id='fileAttachment' type='file' accept='application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' style={{display:'none'}}/>
                  </div>
                </div>

              </div>
              
            </div>

            <div className='col-12 d-flex mt-3 flex-row-reverse' style={{gap:"10px"}}>
              
              {(fileAttachment && content) || (content && !fileAttachment) || (imageFiles.length > 0 && !fileAttachment) || (imageFiles.length > 0 && content)?
              <button className='form-control btn-blue px-5' onClick={()=>post()}>Post</button>
              :
              <button className='form-control btn-blue px-5' disabled>Post</button>
              }
            </div>
            
          </div>

        </Modal.Body>

      </Modal>

      <ModalAlert show={alertMessage} onHide={()=>setAlertMessage()} icon='fa fa-circle-exclamation' title='Post Error' message={alertMessage}/>
      <ModalViewImage show={selectedImage} onHide={()=>setSelectedImage(null)} image={selectedImage}/>
      <ModalLoading show={processing}/>

    </>
  );
}