
import { Helmet } from 'react-helmet-async';
import blackWordLogo from '../../img/logo/black-word-logo.png';
import { FunnelFooter } from '../../components/landing/FunnelFooter';
import bgShapeDark from "../../img/bg/bg-shape-dark.png"
import { useState } from 'react';
import { randomIntFromInterval } from '../../helper/formatter';

import { useEffect } from 'react';
import moment from 'moment'
import $ from 'jquery'
import {set,ref,push} from 'firebase/database'
import { funnelDB } from '../../api/firebase-funnel-config';
import ModalConfirmation from '../../components/general/ModalConfirmation';

import irregShape from "../../img/bg/irregular-bg.png"

export const ServicesCommunity = (props) =>{

    var [videoIDs] = useState(['es-bGGXpse0', '87ZRTlwniec', 's2dkYYvqLsI', 'TRcVe8G4cbs', '0yOG0AejxMM', 's3ERRrmDzD8', 'kX6p_aQVaEE', 'GrfpM6hISgI', 'zyJNvv6Jllo', 'lDYtbpnj-mA','xxQ2FUo-s6I','0YhhhPVtuUs'])

    var [confirming, setConfirming] = useState(false);

    function goToEnrollment(){
        
        if(props.origin === 'YouTube') window.location.href = '/enrollment-yt'
        else window.location.href = '/enrollment'

    }

    const [pageID,setPageID] = useState()

    useEffect(()=>{

        if(pageID){
            var now = moment().format('MMMM DD YYYY')

            if(pageID){

                $.getJSON("https://api64.ipify.org?format=json", function(e){

                    var cleanedIP = String(e.ip).split('.').join('-')

                    push(ref(funnelDB,`analytics/impressions/${pageID}/${now}`),e.ip).then(()=>{

                        set(ref(funnelDB,`analytics/unique/${pageID}/${now}/${cleanedIP}`),'VISITED').then(()=>{

                        })

                    })

                });

            }
        }else setPageID('services')

    },[pageID])

    return(
        <>
            <Helmet>
                <title>Trading Circle - Free Training</title>
                <script>
                    window.fbq("track", "FreeTraining");
                </script>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn py-4">

                <div className='row justify-content-center mt-lg-3 mt-0'>

                    <div className='col-12 text-center mb-4'>
                        <img className='d-none d-lg-inline darkmode-logo' src={blackWordLogo} width='20%' alt='logo'/>
                        <img className='d-inline d-lg-none darkmode-logo' src={blackWordLogo} width='70%' alt='logo'/>
                        <div className='row justify-content-center'>
                            <div className='col-lg-7 col-12'>
                                <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                                <h4 className='bold' style={{fontSize:'calc(1rem + 0.35vw)'}}>Are You Ready For An Infinite Trading Experience?</h4>
                                <h1 className='bold text-blue black' style={{fontSize:'calc(1rem + 0.75vw)'}}>A Step by Step Guide for Robot & Copy Traders, How to Trade the Forex Market Consistently and Profitably</h1>
                            </div>
                        </div>
                        
                    </div>

                    <div className='col-lg-8 col-12' data-aos="fade-up">
                        <div className='video-container' style={{padding:"56.25% 0 0 0",position:"relative"}}><iframe src="https://player.vimeo.com/video/972147174?h=90a33ce491&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} title="Website Walkthrough"></iframe></div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>

                    <div className='col-9 d-lg-block d-none mt-4' data-aos="fade-up">
                        <button onClick={()=>setConfirming(true)} className='form-control btn-solid bold py-4 px-5 w-100 mt-3 animate__animated animate__pulse animate__infinite animate__slow btn-funnel' style={{width:"fit-content",fontSize:'28px'}}><i class="fa-solid fa-arrow-trend-up"></i> I Want to Start my Forex Journey NOW!</button>
                    </div>

                    <div className='col-11 d-lg-none d-block mt-4'>
                        <button onClick={()=>setConfirming(true)} style={{fontSize:'calc(0.75rem + 1 vw)'}} className='form-control btn-solid bold py-3 px-5 mt-4 w-100 animate__animated animate__pulse animate__infinite animate__slow btn-funnel'><i class="fa-solid fa-arrow-trend-up"></i> START Forex Journey!</button>
                    </div>

                    <div className='col-12 mt-5 mb-4 py-5 text-white' style={{background:`url('${bgShapeDark}')`,backgroundSize:'cover',backgroundPosition:'center',minHeight:'1600px'}}>

                        <h2 className='text-center bold mt-5' data-aos="fade-up"><i className='fa fa-star'/> <i className='fa fa-star'/> <i className='fa fa-star'/> <i className='fa fa-star'/> <i className='fa fa-star'/></h2>
                        <h2 className='text-center bold black' style={{fontSize:'calc(1.25rem + 0.95vw)',marginBottom:'75px'}} data-aos="fade-up">Testimonials</h2>
                        
                        <div className='row text-center mt-5 justify-content-center' style={{gap:"50px 50px"}}>

                            {videoIDs &&
                            videoIDs.map((id)=>{
                                return(
                                <div className='col-lg-3 col-11 services-video' data-aos="fade-up">
                                    <div style={{"height":"0px","overflow": "hidden","paddingTop":"56.25%","position":"relative","width": "100%",'borderRadius':'25px',rotate:`${randomIntFromInterval(-2,2)}deg`}}>
                                        <iframe
                                            style={{"position":"absolute","top":"0px","left":"0px","width":"100%", "height":"100%"}}
                                            src={`https://tube.rvere.com/embed?v=${id}`}
                                            title="YouTube video player"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            sandbox="allow-scripts allow-forms allow-same-origin allow-presentation"
                                            allowFullScreen>
                                        </iframe>
                                    </div>
                                </div>
                                )
                            })}
                            
                        </div>

                    </div>

                    <div className='col-9 d-lg-block d-none mb-5' data-aos="fade-up">
                        <button onClick={()=>setConfirming(true)} className='form-control btn-solid bold py-4 px-5 w-100 mt-3 animate__animated animate__pulse animate__infinite animate__slow btn-funnel' style={{width:"fit-content",fontSize:'28px'}}><i class="fa-solid fa-arrow-trend-up"></i> I Want to Start my Forex Journey NOW!</button>
                    </div>

                    <div className='col-11 d-lg-none d-block mb-5'>
                        <button onClick={()=>setConfirming(true)} style={{fontSize:'calc(0.75rem + 1 vw)'}} className='form-control btn-solid bold py-3 px-5 mt-4 w-100 animate__animated animate__pulse animate__infinite animate__slow btn-funnel'><i class="fa-solid fa-arrow-trend-up"></i> START Forex Journey!</button>
                    </div>

                </div>
                

            </div>

            <div className='d-lg-block d-none' style={{background:`url('${irregShape}')`,width:'125%',height:'1507px',position:'absolute',top:'-100px',zIndex:'-999',backgroundPosition:'center center',right:'-12.5%',backgroundSize:'cover',opacity:'0.3'}}/>
            <div className='d-lg-none d-block' style={{background:`url('${irregShape}')`,width:'100%',height:'1005px',position:'absolute',top:'-100px',zIndex:'-999',backgroundPosition:'center center',backgroundSize:'cover',opacity:'0.2'}}/>

            <ModalConfirmation show={confirming} onHide={()=>setConfirming(false)} onConfirm={()=>goToEnrollment()} title="Are you Finished?" message={`<b><i class='fa fa-triangle-exclamation'></i> WARNING:</b> This <b>FREE TRAINING</b> will <b class="text-error">EXPIRE</b> once you click this button. Are you <b>SURE</b> you already reached and finished the <b>END</b> of this <b>1-HOUR VIDEO</b>?<br/><br/>If not, please finish it first because this video has <b>EXPIRY</b>.`} yesButton="I AM FINISHED" cancelButton="I AM NOT YET DONE WATCHING" />
            <FunnelFooter/>
        </>
    );

}