/* eslint-disable jsx-a11y/anchor-has-content */

import { Helmet } from 'react-helmet-async';

import ModalLoading from '../../components/general/ModalLoading';
import { useEffect, useState } from 'react';

import {Header} from '../../components/general/Header'
import { onValue, ref } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import { studentAuth, studentDB } from '../../api/firebase-student-config';
import { Footer } from '../../components/academy/Footer';
import { adminDB } from '../../api/firebase-admin-config';

import freeAdvancedTraining from '../../img/modules/free-advanced-training.webp'
import wealthCircle from '../../img/modules/wealth-circle.png'

import { NavLink } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export const Module = () =>{

    const [processing,setProcessing] = useState(false);

    var [uid,setUID] = useState(null);
    var [student,setStudent] = useState(null);

    var [courses,setCourses] = useState([])
    
    var [watchHistory,setWatchHistory] = useState({})
    var [standardTotalPercentage,setStandardTotalPercentage] = useState(0)
    var [upgrade,setUpgrade] = useState(false);

    useEffect(()=>{
        setProcessing(true)
        onAuthStateChanged(studentAuth,(student)=>{
      
            if(student){
        
              onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
                if(snapshot.exists()){
                    setUID(student.uid);
                    setStudent(snapshot.val());

                    if(snapshot.val().special && snapshot.val().special === "UPGRADE") setUpgrade(true)
                }
              })
        
            }else setProcessing(false);
        
        })

        onValue(ref(adminDB,"courses"),(snapshot)=>{


            if(snapshot.exists()){

                var crs = []

                for(var _key in snapshot.val()){
                    var curr = snapshot.val()[_key]
                    curr['key'] = _key
                    
                    var epCount = 0

                    // eslint-disable-next-line no-unused-vars
                    for(var _epKey in curr.episodes) epCount++

                    curr['episodeCount'] = epCount

                    crs.push(curr)

                }

                setProcessing(false)
                setCourses(crs)

            }

        })

    },[])

    useEffect(()=>{

        if(uid){
            onValue(ref(studentDB,`watchHistory/${uid}`),(snapshot)=>{
                if(snapshot.exists()){

                    var standardTotalPercentage = 0

                    for(var key in courses){

                        var watchCount = 0;

                        if(snapshot.val()[key] !== undefined){
                            // eslint-disable-next-line no-unused-vars
                            for(var i in snapshot.val()[key]) watchCount++
                            var percentageWatched = Math.round(watchCount / courses[key].episodeCount * 100)
                            standardTotalPercentage += percentageWatched
                        }

                    }

                    standardTotalPercentage /= 7
                    setStandardTotalPercentage(Math.round(standardTotalPercentage))
                    setWatchHistory(snapshot.val())
                }else setWatchHistory(null)
            })
        }

    },[uid,courses])

    return(
        <>
            <Helmet>
                <title>Trading Circle - Module</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn p-4">

                <Header title='Module'/>

                <div className='row justify-content-center mb-5 mt-5'>

                    <div className='col-lg-12 mb-5'>

                        <h4 className='text-center bold mb-4 text-blue'>Basic Training<br/><span style={{fontSize:'14px'}}>({standardTotalPercentage >= 100?100:standardTotalPercentage}% Complete)</span></h4>
                        <div className='row justify-content-center' style={{gap:'20px 10px'}}>

                            {courses.length > 0?
                            
                            courses.map((course)=>{

                                var watchCount = 0
                                if(watchHistory && watchHistory[course.key] !== undefined){
                                    // eslint-disable-next-line no-unused-vars
                                    for(var _i in watchHistory[course.key]) watchCount++
                                }

                                var percentageWatched = Math.round(watchCount / course.episodeCount * 100)
                                console.log(course)
                                if(course.accessLevel === 'Standard' && course.key !== "99"){
                                    return(
                                        <a className='col-lg-3 col-md-5 col-11 pointer animate__animated animate__fadeIn module-container' href={`/module/view?chapter=${course.key}&episode=1`} rel='noreferrer'>
                                            
                                            <a id={`icon-${course.key}`} href={`/module/assessment?chapter=${course.key}`} rel='noreferrer' target='_self' class={`fa-solid fa-file-pen module-assessment-icon ${percentageWatched === 100?'':'disabled'}`} style={{background:`url(${course.thumbnailURL})`}}/>

                                            <p className='text-center module-chapter' style={{background:`url(${course.thumbnailURL})`,fontVariant:'small-caps',fontSize:'24px'}}>Chapter {course.key}</p>
                                            <img alt='icon' className='course-thumbnail animate__animated animate__fadeIn' style={{width:"100%",borderRadius:"25px"}} src={course.thumbnailURL}/>

                                            <div className="progress mt-3 mx-4">
                                                <div className="progress-bar saira bold" role="progressbar" style={{background:`url(${course.thumbnailURL})`,width:`${percentageWatched}%`,backgroundPositionY:'20px'}}><span>{percentageWatched === 100 && <i className='fa fa-check me-1'/>} {percentageWatched}%</span></div>
                                            </div>
                                            
                                            {percentageWatched === 100 &&
                                            <Tooltip 
                                            anchorSelect={`#icon-${course.key}`}
                                            content="Assessment"
                                            style={{background:`url(${course.thumbnailURL})`,backgroundPosition:'top right',boxShadow:'-1px 2px 15px 0px rgba(0,0,0,0.6)'}}
                                            />
                                            }

                                        </a>
                                    )
                                }else return null
                            })
                            
                            :
                            <></>
                            }

                        </div>  

                    </div>
                    
                    {student && student.level === "Standard" && upgrade &&
                        
                        courses.map((course)=>{

                            var watchCount = 0
                            if(watchHistory && watchHistory[course.key] !== undefined){
                                // eslint-disable-next-line no-unused-vars
                                for(var _i in watchHistory[course.key]) watchCount++
                            }

                            var percentageWatched = Math.round(watchCount / course.episodeCount * 100)
                            console.log(course)
                            
                            if(course.accessLevel === 'Standard' && course.key === "99"){
                                return(
                                    <>
                                    <a className='mt-5 col-lg-4 col-md-5 col-11 pointer animate__animated animate__fadeIn module-container' href={`/module/view?chapter=${course.key}&episode=1`} rel='noreferrer'>
                                        <div className='animate__animated animate__pulse animate__infinite animate__slower'>
                                            <p className='text-center module-chapter' style={{background:`url(${course.thumbnailURL})`,fontVariant:'small-caps',fontSize:'32px'}}>Free Elite Chapter</p>
                                            <img alt='icon' className='course-thumbnail animate__animated animate__fadeIn ' style={{width:"100%",borderRadius:"25px",boxShadow:"2px 2px 50px 5px rgba(222,39,156,0.5)"}} src={course.thumbnailURL}/>

                                            <div className="progress mt-3 mx-4">
                                                <div className="progress-bar saira bold" role="progressbar" style={{background:`url(${course.thumbnailURL})`,width:`${percentageWatched}%`,backgroundPositionY:'20px'}}><span>{percentageWatched === 100 && <i className='fa fa-check me-1'/>} {percentageWatched}%</span></div>
                                            </div>  
                                        </div>
                                    </a>
                                    </>
                                )
                            }else return null;
                        })
                        
                    }

                    {student && student.level === 'Standard' && !upgrade &&
                    <div className='col-lg-12 mt-5'>
                        
                        <h4 className='text-center bold mb-4 text-blue'>Free Advanced Training {standardTotalPercentage < 50 && <><br/><span style={{fontSize:"14px"}}>(Finish 50% of the Modules to Unlock)</span></>}</h4>
                        <div className='row justify-content-center'>    

                            {standardTotalPercentage < 50?
                            <p className='col-lg-3 col-md-5 col-11 animate__animated animate__fadeIn module-container'>
                                <img alt='icon' className='course-thumbnail animate__animated' style={{width:"100%",borderRadius:"25px",opacity:'0.5'}} src={freeAdvancedTraining}/>
                            </p>
                            :
                            <NavLink className='col-lg-3 col-md-5 col-11 pointer animate__animated animate__fadeIn module-container' to={`/whats-next`} rel='noreferrer'>
                                <img alt='icon' className='course-thumbnail animate__animated animate__fadeIn' style={{width:"100%",borderRadius:"25px"}} src={freeAdvancedTraining}/>
                            </NavLink>
                            }

                        </div>

                    </div>
                    }
                        
                    <div className='col-lg-12 mt-5'>

                        {student && student.level === 'Elite' && <h4 className='text-center bold mb-4 text-blue'>Advanced Training <span style={{fontSize:"14px"}}>(Elite Only)</span></h4>}
                        <div className='row justify-content-center' style={{gap:'20px 10px'}}>

                            {courses.length > 0?
                            
                            courses.map((course)=>{

                                var watchCount = 0
                                if(watchHistory && watchHistory[course.key] !== undefined){
                                    // eslint-disable-next-line no-unused-vars
                                    for(var _i in watchHistory[course.key]) watchCount++
                                }

                                var percentageWatched = Math.round(watchCount / course.episodeCount * 100)

                                if(course.accessLevel === 'Elite'){

                                    if(student && student.level === 'Elite'){
                                        return(
                                            <a className='col-lg-3 col-md-5 col-11 pointer animate__animated animate__fadeIn module-container' href={`/module/view?chapter=${course.key}&episode=1`} rel='noreferrer'>
                                            
                                                <a id={`icon-${course.key}`} href={`/module/assessment?chapter=${course.key}`} rel='noreferrer' target='_self' class={`fa-solid fa-file-pen module-assessment-icon ${percentageWatched === 100?'':'disabled'}`} style={{background:`url(${course.thumbnailURL})`}}/>

                                                <p className='text-center module-chapter' style={{background:`url(${course.thumbnailURL})`,fontVariant:'small-caps',fontSize:'24px'}}>Chapter {course.key}</p>
                                                <img alt='icon' className='course-thumbnail animate__animated animate__fadeIn' style={{width:"100%",borderRadius:"25px"}} src={course.thumbnailURL}/>

                                                <div className="progress mt-3 mx-4">
                                                    <div className="progress-bar saira bold" role="progressbar" style={{background:`url(${course.thumbnailURL})`,width:`${percentageWatched}%`,backgroundPositionY:'20px'}}><span>{percentageWatched === 100 && <i className='fa fa-check me-1'/>} {percentageWatched}%</span></div>
                                                </div>
                                                
                                                {percentageWatched === 100 &&
                                                <Tooltip 
                                                anchorSelect={`#icon-${course.key}`}
                                                content="Take Assessment"
                                                style={{background:`url(${course.thumbnailURL})`,backgroundPosition:'top right',boxShadow:'-1px 2px 15px 0px rgba(0,0,0,0.6)'}}
                                                />
                                                }

                                            </a>
                                        )
                                    }else return(null)
                                    
                                }else return null

                            })
                            :
                            <></>
                            }

                            {student && student.level  === 'Elite' && !student.wealthcircle &&
                            <div className='col-lg-12 mt-5 animate__animated animate__pulse animate__infinite'>
                                <p className='text-center module-chapter' style={{background:`url(${wealthCircle})`,fontVariant:'small-caps',fontSize:'24px'}}>EXCLUSIVE OFFER</p>
                                <div className='row justify-content-center'>    
                                    <NavLink className='col-lg-3 col-md-5 col-11 pointer animate__animated animate__fadeIn module-container' to={`/wealthcirclecheckout`} rel='noreferrer'>
                                        <img alt='icon' className='course-thumbnail animate__animated animate__fadeIn' style={{width:"100%",borderRadius:"25px"}} src={wealthCircle}/>
                                    </NavLink>
                                </div>
                            </div>
                            }

                        </div>  

                    </div>

                </div>

            </div>
            
            <Footer/>
            <ModalLoading show={processing} />

            
        </>
    );

}