
const escapeRegExpMatch = function(s) {
    return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
};

export const isExactMatch = (str, match) => {
  return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str)
}

export function shortenDescription(desc,limit){
    if(!desc) return null
    else if(limit > desc.length) return desc
    return desc.substring(0,limit) + "...";
}

export function toTitleCase(str) {

  str = str.toLowerCase()

  return str.replace(/(?:^|\s)\w/g, function(match) {
      return match.toUpperCase();
  });
  
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function hasNumber(str) {
  return /\d/.test(str);
}

export function hasLetter(str){
  return /[a-zA-Z]/g.test(str);
}

export function hasSpecial(str){
  var format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,<>/?~]/;
  return format.test(str)
}

export function hasSpecialDot(str){
  var format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  return format.test(str)
}

export function hasCorrectEmail(str) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str)
}

export function hasUppercase(str) {
  return /[A-Z]/.test(str);
}


export function getFileExt(filename){
  return filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
}

export function getURL(text){
  var urlRE= new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?");
  return urlRE.test(text)
}



export  function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}