
export const FunnelFooter = () =>{

    return(

        <div className="container-fluid" style={{maxWidth:'1280px'}} data-aos="fade-up">

            <hr className='footer-hr mb-3'/>

            <div className="row justify-content-center px-3" style={{rowGap:'30px'}}>

                <div className="col-lg-12 px-lg-5 px-2 my-3 d-none d-lg-block" style={{fontSize:'12px'}}>
                    <p className='text-justify mb-3 bold' style={{opacity:"1",lineHeight:"1.75"}}>This site is not a part of the Facebook website or Facebook Inc. Additionally, This site is NOT endorsed by Facebook in any way. FACEBOOK is a trademark of FACEBOOK, Inc.</p>
                    <p className='text-justify mb-3 bold' style={{opacity:"1",lineHeight:"1.75"}}>Circle Online Educational Consultancy Services cannot and does not make any guarantees about your ability to get results or earn any money with our ideas, information, tools, or strategies.</p>
                    <p className='text-justify mb-3 bold' style={{opacity:"1",lineHeight:"1.75"}}>Nothing on this page, any of our websites, or any of our content or curriculum is a promise or guarantee of results or future earnings, and we do not offer any legal, medical, tax or other professional advice. Any financial numbers referenced here, or on any of our sites, are illustrative of concepts only and should not be considered average earnings, exact earnings, or promises for actual or future performance.</p>
                    <p className='text-justify bold' style={{opacity:"1",lineHeight:"1.75"}}>Use caution and always consult your accountant, lawyer or professional advisor before acting on this or any information related to a lifestyle change or your business or finances. You alone are responsible and accountable for your decisions, actions and results in life, and by your registration here you agree not to attempt to hold us liable for your decisions, actions or results, at any time, under any circumstance. If you have questions, email support@tradingcircle.com.ph. We wish you success on your journey.</p>
                </div>

                <div className="col-lg-12 px-lg-5 px-2 my-3 d-block d-lg-none" style={{fontSize:'10px'}}>
                    <p className='text-justify mb-3 bold' style={{opacity:"1",lineHeight:"1.75"}}>This site is not a part of the Facebook website or Facebook Inc. Additionally, This site is NOT endorsed by Facebook in any way. FACEBOOK is a trademark of FACEBOOK, Inc.</p>
                    <p className='text-justify mb-3 bold' style={{opacity:"1",lineHeight:"1.75"}}>Circle Online Educational Consultancy Services cannot and does not make any guarantees about your ability to get results or earn any money with our ideas, information, tools, or strategies.</p>
                    <p className='text-justify mb-3 bold' style={{opacity:"1",lineHeight:"1.75"}}>Nothing on this page, any of our websites, or any of our content or curriculum is a promise or guarantee of results or future earnings, and we do not offer any legal, medical, tax or other professional advice. Any financial numbers referenced here, or on any of our sites, are illustrative of concepts only and should not be considered average earnings, exact earnings, or promises for actual or future performance.</p>
                    <p className='text-justify bold' style={{opacity:"1",lineHeight:"1.75"}}>Use caution and always consult your accountant, lawyer or professional advisor before acting on this or any information related to a lifestyle change or your business or finances. You alone are responsible and accountable for your decisions, actions and results in life, and by your registration here you agree not to attempt to hold us liable for your decisions, actions or results, at any time, under any circumstance. If you have questions, email support@tradingcircle.com.ph. We wish you success on your journey.</p>
                </div>
               
            </div>

            <hr className='footer-hr mb-0'/>

            <div className="row p-lg-4 p-0 pb-4 pt-4">
                <div className='col-lg-6 text-lg-start text-center'>
                    <p style={{fontSize:'calc(0.65rem + 0.3vw)'}}>&copy; Trading Circle 2023, <span className='text-muted'>All Rights Reserved</span></p>
                </div>
                <div className='col-lg-6 text-lg-end text-center mt-lg-0 mt-2'>
                    <p style={{fontSize:'calc(0.65rem + 0.3vw)'}}><span className='text-muted'>Powered By </span> SDPT Solutions</p>
                </div>
            </div>

        </div>

    );

}