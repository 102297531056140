import { Helmet } from 'react-helmet-async';
import { FunnelFooter } from '../../components/landing/FunnelFooter';

import blackWordLogo from '../../img/logo/black-word-logo.png';
import { useEffect, useState } from 'react';
import $ from 'jquery'
import { getFileExt, hasCorrectEmail, hasLetter, hasNumber, hasUppercase, toTitleCase } from '../../helper/formatter';

import profilePlaceholder from '../../img/placeholder/profile-placeholder.png';
import ModalCropper from '../../components/general/ModalCropper';
import ModalLoading from '../../components/general/ModalLoading';
import { onAuthStateChanged } from 'firebase/auth';
import { studentAuth, studentDB, studentStorage } from '../../api/firebase-student-config';
import { onValue, ref, set } from 'firebase/database';
import { ref as sref } from 'firebase/storage';

import { getDownloadURL, uploadBytes } from 'firebase/storage';
import moment from 'moment';


export const RecoverEnrollee = () =>{

    const [uid,setUID] = useState();
    const [email,setEmail] = useState();

    useEffect(()=>{
        setProcessing(true)
        onAuthStateChanged(studentAuth,(user)=>{
            if(user){

                onValue(ref(studentDB,`students/${user.uid}`),(snapshot)=>{
                    console.log(snapshot.exists()) 
                    
                    if(snapshot.exists()){
                        window.location.href = '/';
                    }else{
                        setUID(user.uid);
                        setEmail(user.email);
                        setProcessing(false);
                    }
                    

                })

            }else window.location.href = "/";
        })

    },[])

    const [processing,setProcessing] = useState(false);

    const [step,setStep] = useState(1)

    const [firstName,setFirstName] = useState()
    const [lastName,setLastName] = useState()
    const [birthday,setBirthday] = useState()
    const [contactNumber,setContactNumber] = useState()
    const [discovery,setDiscovery] = useState()

    const [rawImage,setRawImage] = useState()
    const [profilePicture,setProfilePicture] = useState()
    const [profilePictureURL,setProfilePictureURL] = useState()
    const [cropping,setCropping] = useState(false)
    
    function verifyFirstName(){

        var txtFirstName = $('#txtFirstName').val().trim();
        var errFirstName = $('#errFirstName');
        
        errFirstName.text('')
        setFirstName(null);

        if(txtFirstName.trim().length === 0) errFirstName.text('Blank spaces are not allowed.');
        else if(txtFirstName.length <= 1) errFirstName.text('Atleast 2 Characters.');
        else setFirstName(toTitleCase(txtFirstName));
        
    }

    function verifyLastName(){

        var txtLastName = $('#txtLastName').val().trim();
        var errLastName = $('#errLastName');
        
        errLastName.text('')
        setLastName(null);

        if(txtLastName.trim().length === 0) errLastName.text('Blank spaces are not allowed.');
        else if(txtLastName.length <= 1) errLastName.text('Atleast 2 Characters.');
        else setLastName(toTitleCase(txtLastName));

    }

    function verifyBirthday(){

        var txtBirthday = $('#txtBirthday').val();
        var errBirthday = $('#errBirthday');
        
        errBirthday.text('')
        setBirthday(null);

        if(txtBirthday.trim().length === 0) errBirthday.text('Select a Date.');
        else setBirthday(txtBirthday);


    }

    function verifyContactNumber(){

        var txtContactNumber = $('#txtContactNumber').val().trim();
        var errContactNumber = $('#errContactNumber');
        
        errContactNumber.text('')
        setContactNumber(null);

        if(txtContactNumber.trim().length === 0) errContactNumber.text('Blank spaces are not allowed.');
        else if(hasLetter(txtContactNumber)) errContactNumber.text('Letters are not allowed.');
        else if(txtContactNumber.length > 15) errContactNumber.text('Invalid Format.');
        else if(txtContactNumber.includes(' ')) errContactNumber.text('Spaces are not allowed')
        else setContactNumber(txtContactNumber);

    }

    function verifyDiscovery(){
        
        var slcDiscovery = $('#slcDiscovery').find(":selected").val();
        setDiscovery(slcDiscovery)

    }

    function verifyProfilePicture(){

        var fileProfilePicture = $("#fileProfilePicture").prop('files')
        var txtErr = $("#errProfilePicture")
    
        setProfilePicture(null)
        setProfilePictureURL(null)
        txtErr.text('')
    
        if(fileProfilePicture.length === 0) txtErr.text('Please select a file.')
        else if(getFileExt(fileProfilePicture[0].name).toLowerCase() !== 'png' && getFileExt(fileProfilePicture[0].name).toLowerCase() !== 'jpg' && getFileExt(fileProfilePicture[0].name).toLowerCase() !== 'jpeg') txtErr.text('PNG and JPG Files are the only accepted files.')
        else{
            var _URL = window.URL || window.webkitURL;

            var objectURL = _URL.createObjectURL(fileProfilePicture[0])

            setRawImage(objectURL)
            setCropping(true)

        }
        
    }

    function confirmProfilePicture(croppedImage){

        var txtErr = $("#errProfilePicture")
    
        setProfilePicture(null)
        setProfilePictureURL(null)
        txtErr.text('')

        setCropping(false)  

        var xhr = new XMLHttpRequest();
        xhr.open("GET", croppedImage);
        xhr.responseType = "blob";
        xhr.onload = function(){

            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(this.response);

            setProfilePicture(croppedImage)
            setProfilePictureURL(imageUrl)

        }
        xhr.send();

    }

    function cancelCrop(){
        $("#fileProfilePicture").val(null)
        setCropping(false)
        setProfilePicture(null)
        setProfilePictureURL(null)
        setRawImage(null)
    }
    

    function recoverUser(){
        setProcessing(true);

        fetch(profilePicture).then(r => r.blob()).then(blobFile => {

            var file = new File([blobFile], 'Hi', { type: blobFile.type })

            uploadBytes(sref(studentStorage,'students/profilePicture/' + uid), file).then((res)=>{
                
                getDownloadURL(sref(studentStorage,'students/profilePicture/' + uid)).then((url)=>{
                    
                    var registerDate = moment().format('MMMM DD, YYYY hh:mm:ss a')

                    const body = {
                        profile:{
                            name:{
                                firstName:firstName,
                                lastName:lastName
                            },
                            birthday:birthday,
                            contactNumber:contactNumber,
                            emailAddress:email,
                            profilePictureURL:url,
                            discovery:discovery,
                            enrollment:{
                                registerDate:registerDate,
                            }
                        }
                    }

                    set(ref(studentDB,'students/' + uid),body).then(()=>{
                        
                        window.top.location.href = '/checkout';

                    }).catch((error)=>{
                        alert(error.message);
                        setProcessing(false);
                    })


                }).catch((error)=>{
                    alert(error.message);
                    setProcessing(false);
                })

            }).catch((error)=>{
                alert(error.message);
                setProcessing(false);
            })

        })

    }

    function logOut(){
        studentAuth.signOut().then(()=>{
            window.location.reload()
        });
    }

    return(
        <>
            <Helmet>
                <title>Trading Circle - Recover Enrollee</title>
            </Helmet>
            
            {!processing &&
            <div className="container-fluid animate__animated animate__fadeIn p-4">
                
                <div className='row justify-content-center'>
                    <div className='col-lg-12 col-12 text-center'>
                        <img className='d-none d-lg-inline darkmode-logo' src={blackWordLogo} width='20%' alt='logo'/>
                        <img className='d-inline d-lg-none darkmode-logo' src={blackWordLogo} width='70%' alt='logo'/>
                        <div className='row justify-content-center'>
                            <div className='col-lg-6 col-12'>
                                <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                            </div>
                        </div>
                        <h3 className='black text-blue' style={{fontSize:'calc(1rem + 0.75vw)'}}>Recover Your Account Before it Disappears</h3>
                        <p className='text-muted' style={{fontSize:'calc(0.5rem + 0.65vw)'}}>Due to your <b>inactivity</b> for more than <b>3 Months</b> your details was <b>archived</b>,<br/>please fill out the form below to <b>recover your account</b>, so you can enroll at the academy!</p>
                    </div>
                </div>

                <div className='row justify-content-center mt-4'>
                    <div className='col-lg-8 text-center align-self-center animate__animated animate__fadeInUp creds-form-container'>
                        <div className='row justify-content-center'>

                            <div className='col-12 mb-4'>
                                <div className='col-lg-12 text-center text-blue mb-4'>
                                    <p className='small'><i className='fa fa-envelope'/> Your Email</p>
                                    <p className='bold mb-2'>{email}</p>
                                    <p className='small text-muted'>Not You? <span className='underline pointer' onClick={()=>logOut()}>Sign Out</span></p>
                                </div>  

                                <div className='row justify-content-center'>
                                    <div className='col-12 d-flex justify-content-center' style={{gap:"10px"}}>
                                        <div className={step===1?'dot active':'dot'}/>
                                        <div className={step===2?'dot active':'dot'}/>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='col-12 p-0 animate__animated animate__fadeIn' style={{display:step===1?'':'none'}}>

                                <div className='row p-lg-0 ps-4 pe-4'>

                                    

                                    <div className='col-lg-6 text-start mb-3'>
                                
                                        <input placeholder='First Name' id="txtFirstName" onChange={()=>verifyFirstName()} type="text" className="form-control"/>
                                        <small id="errFirstName" className="form-text text-danger"></small>

                                    </div>

                                    <div className='col-lg-6 text-start mb-3'>
                                
                                        <input placeholder='Last Name' id="txtLastName" onChange={()=>verifyLastName()} type="text" className="form-control"/>
                                        <small id="errLastName" className="form-text text-danger"></small>

                                    </div>

                                    <div className='col-12 text-start mb-3'>
                                
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><span className='text-blue bold fa fa-phone'></span></span>
                                            </div>
                                            <input placeholder='Contact Number' id="txtContactNumber" onChange={()=>verifyContactNumber()} maxLength={15} type="text" className="form-control"/>
                                        </div>
                                        <small id="errContactNumber" className="form-text text-danger"></small>

                                    </div>

                                    <div className='col-12 text-start mb-3'>
                                
                                        <label className='saira bold text-blue mb-1' htmlFor="txtBirthday">Birthday</label>
                                        <input onChange={()=>verifyBirthday()} id="txtBirthday" type="date" className="form-control"/>
                                        <small id="errBirthday" className="form-text text-danger"></small>

                                    </div>

                                    <div className='col-12 text-start mb-4'>
                                
                                        <label className='saira bold text-blue mb-1' htmlFor="slcDiscovery">Where did you see Trading Circle?</label>
                                        <select className='form-select' id='slcDiscovery' onChange={()=>verifyDiscovery()}>
                                            <option value=''>-- Select One --</option>
                                            <option value='Facebook Ads'>Facebook Ads</option>
                                            <option value='Facebook Group'>Facebook Group</option>
                                            <option value='Forex Trading PH'>Forex Trading PH</option>
                                            <option value='TikTok'>TikTok</option>
                                            <option value='Instagram'>Instagram</option>
                                            <option value='YouTube Ads'>YouTube Ads</option>
                                            <option value='YouTube Main Channel'>YouTube Main Channel</option>
                                            <option value='Friend Referral'>Friend Referral</option>
                                        </select>

                                    </div>

                                </div>
                                
                            </div>

                            <div className='col-12 p-0 animate__animated animate__fadeIn' style={{display:step===2?'':'none'}}>

                                <div className='row p-lg-0 ps-4 pe-4'>

                                    <div className='col-12 text-start mb-3 justify-content-center'>
                                
                                        <div className='row'>
                                            <div className='col-12 d-flex justify-content-center'>
                                                <img src={profilePictureURL?profilePictureURL:profilePlaceholder} alt="profile" width="150px" style={{borderRadius:"100rem"}}/>
                                            </div>
                                            <small id="errProfilePicture" className="form-text text-danger text-center mb-3 mt-2"></small>
                                        </div>

                                        <label className='saira bold text-blue mb-1' htmlFor="fileProfilePicture">Profile Picture</label>
                                        <input id="fileProfilePicture" onChange={()=>verifyProfilePicture()} type="file" className="form-control" accept='image/*'/>

                                    </div>

                                </div>
                                
                            </div>

                            <div className='col-12 p-lg-0 ps-4 pe-4'>
                                {step === 1 &&
                                    <div>
                                    {firstName && lastName && birthday && contactNumber && discovery?
                                        <button className='form-control saira m-0' onClick={()=>setStep(2)}>Next<i className='fa fa-arrow-right ms-2'/></button>
                                        :
                                        <button className='form-control saira m-0' disabled>Next<i className='fa fa-arrow-right ms-2'/></button>
                                    }   
                                    </div>
                                }
                                
                                
                                {step === 2 && 
                                <div className='row'>

                                    <div className='col-2 pe-0'>
                                        <button className='form-control saira m-0' onClick={()=>setStep(1)}><i className='fa fa-arrow-left'/></button>
                                    </div>

                                    <div className='col-10'>
                                        {profilePicture?
                                        <button className='form-control saira m-0' onClick={()=>recoverUser()}>Recover Account <i className='fa fa-check ms-2'/></button>
                                        :
                                        <button className='form-control saira m-0' disabled>Next<i className='fa fa-arrow-right ms-2'/></button>
                                        }
                                    </div>

                                </div>
                                }

                            </div>

                        </div>

                    </div>
                </div>

            </div>
            }

            <FunnelFooter/>

            <ModalCropper show={cropping} image={rawImage} onHide={()=>cancelCrop()} onCrop={(img)=>confirmProfilePicture(img)}/>   
            <ModalLoading show={processing} />
        </>
    );

}