import { Helmet } from "react-helmet-async"
import { PolicySidebar } from "../../components/general/PolicySidebar"


export const RefundPolicy = () =>{

    return(
        <div className="container-fluid">

            <Helmet>
                <title>Trading Circle - Refund Policy</title>
            </Helmet>

            <div className="row justify-content-center p-lg-5 p-0">

                <PolicySidebar/>

                <div className="col-lg-6 text-blue policy-container ps-lg-4 px-5 animate__animated animate__fadeIn">
                    <h3 className="bold saira mt-lg-5 mt-4">Refund Policy</h3>
                    <h5 className="bold saira">What is the Refund Policy and what does it cover?</h5>

                    <div className="content mt-3 mb-5">
                        <p className="mb-4 text-justify">The Trading Circle Academy promised and assured to deliver the best quality of service in Foreign Exchange education for beginners who want to make money through Forex trading. Our confidence is high that this program will work if you make time to study and apply everything we teach you.</p>
                        <p className="mb-4 text-justify">In line with that, the least we ask from our students is to take this course seriously and devote their commitment and responsibility to honestly apply the given lessons and skills.</p>
                        <p className="mb-4 text-justify">In certain cases, and within certain limits, Trading Circle assures an implementation guarantee for the students who will find the service of the academy ineffective and profitless.</p>
                    </div>

                    <h5 className="bold saira">Requirements for Refund</h5>
                    <div className="content mt-3 mb-5">
                        <ol>
                            <li><p className="mb-4 text-justify">Finish all the chapters 1 to 7. The coaches will have a one-on-one assessment if the student who is candidate for refund had really finished the entire course.</p></li>
                            <li><p className="mb-4 text-justify">Attended the live training every Monday, 7:30 pm at least once. Please provide any documentation or evidence that you have attended. All the sessions have replays, just indicate which date you attended so the coaches can verify.</p></li>
                            <li><p className="mb-4 text-justify">Post a sample analysis on the Facebook group using the CIRCLE Strategy to check if the student is applying the learnings provided by the academy.</p></li>
                            <li><p className="mb-4 text-justify">Opened a broker under FX Primus and tried practicing using a demo account.</p></li>
                            <li><p className="mb-4 text-justify">Joined the Demo Challenge of the academy at least for one month. Please provide the demo account number you have used to confirm if you participated with honesty.</p></li>
                            <li><p className="mb-4 text-justify">Complete the Career Documents in the Course Site. Use the Trading Journal template provided during your practice and document your first 100 trades using the Creating Your System template. Please submit your documentations to Coach Alex Balingit’s email alexbalingit@tradingcircle.com.ph</p></li>
                            <li><p className="mb-4 text-justify">The coaches will happily provide you a free one-on-one session to diagnose the possible problem with your style and strategy.</p></li>
                            <li><p className="mb-4 text-justify">If the assessment did not work and nothing changed with your results, a refund will be given. If all the provided evidence and documentations are proven to be applied with honesty and integrity, the bonus ₱5,000 will also be given and the student will get a total of ₱9,997 as implementation guarantee refund.</p></li>
                        </ol>
                        <p className="mb-4 text-justify">If you have done all of these and you still not yield any results, then you will be refunded.</p>

                    </div>

                </div>

            </div>

        </div>
    )

}