import { onAuthStateChanged } from "firebase/auth"
import { onValue, ref, set } from "firebase/database"
import {ref as sref} from 'firebase/storage'
import { getDownloadURL } from "firebase/storage"
import $ from "jquery"
import { useEffect, useState } from "react"
import {useNavigate, useSearchParams } from "react-router-dom"
import { adminDB, adminStroage } from "../../api/firebase-admin-config"
import { studentAuth, studentDB } from "../../api/firebase-student-config"
import ModalLoading from "../../components/general/ModalLoading"
import blackWordLogo from '../../img/logo/black-word-logo.png';


export const Assessment = ()=>{

    const [processing,setProcessing] = useState(true)

    const [params] = useSearchParams()

    const [course,setCourse] = useState()
    const [assessment,setAssessment] = useState([])
    const [assessmentImages] = useState([])

    const [started,setStarted]= useState(false)
    const [index, setIndex] = useState(0)
    const [answer, setAnswer] = useState()

    const [finalAnswers] = useState([])

    const [student,setStudent] = useState()

    const [attemptCount,setAttemptCount] = useState(0) 

    const navigate = useNavigate();

    function shuffle(array) {

        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle.
        while (currentIndex !== 0) {
      
          // Pick a remaining element.
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    useEffect(()=>{
        onAuthStateChanged(studentAuth,(s)=>{
            if(s) setStudent(s)
        })
        
    },[])

    useEffect(()=>{

        if(student && params.get('chapter')){
            
            onValue(ref(studentDB,`/assessments/${student.uid}/${params.get('chapter')}`),(snapshot)=>{
                if(snapshot.exists()){
                    if(snapshot.val().answers) navigate(`/module/assessment/results?chapter=${params.get('chapter')}`)
                    else{
                        setAttemptCount(snapshot.val().attempt)
                    }
                }
            })

        }

    },[student,params,navigate])

    useEffect(()=>{

        if(params.get('chapter')){

            setProcessing(true)

            onValue(ref(adminDB,`/assessment/${params.get('chapter')}`),(snapshot)=>{

                onValue(ref(adminDB,`/courses/${params.get('chapter')}`),(s)=>{
                    if(s.exists()){
                        setCourse(s.val())
                        setProcessing(false)
                    }
                    else navigate('/module')
                });

                if(snapshot.exists()){
                    
                    var questions = []

                    for(var key in snapshot.val()){

                        var cs = []

                        for(var k in snapshot.val()[key].Choices){
                            cs.push(JSON.parse(`{"letter":"${k}","choice":"${snapshot.val()[key].Choices[k]}"}`))
                        }

                        var item = snapshot.val()[key]
                        
                        item['Choices'] = cs
                        item['Index'] = key
                        questions.push(item)
                        
                    }
                    
                    questions = shuffle(questions)
                    setAssessment(questions)

                }else navigate('/module')

            })

        }else navigate('/module')
        

    },[params,navigate])

    useEffect(()=>{

        if(assessment.length > 0){

            assessmentImages.length = 0

            function getImage(i){
                
                getDownloadURL(sref(adminStroage,`/assessment/C${params.get('chapter')}-Q${assessment[i].Index}.png`)).then((url)=>{
                    console.log(url)
                    
                    assessmentImages[i] = url

                    if(i < assessment.length) getImage(i+1)

                }).catch((error)=>{

                    if(i < assessment.length) getImage(i+1)

                })

            }

            getImage(0)


        }

    },[assessment,params,assessmentImages])

    function takeAssessment(){
        setProcessing(true)
        setTimeout(()=>{
            setStarted(true)
            setProcessing(false)
        },1000)
    }

    function selectAnswer(letter){

        var letters = []
        for(var k in assessment[index].Choices)
            letters.push(assessment[index].Choices[k]['letter'])

        for(k in letters){
            if(letters[k] !== letter)
                $('#answer-'+letters[k]).removeClass('selected')
        }

        $('#answer-'+letter).toggleClass('selected')

        if($('#answer-'+letter).hasClass('selected')) setAnswer(letter)
        else setAnswer(null)
    }

    function resetChoices(){
        var letters = []
        for(var k in assessment[index].Choices)
            letters.push(assessment[index].Choices[k]['letter'])

        for(k in letters){
            $('#answer-'+letters[k]).removeClass('selected')
        }
    }

    function prevQuestion(){

        setProcessing(true)
        
        resetChoices()
        setIndex(index-1)

        setTimeout(()=>{

            selectAnswer(finalAnswers[index-1])
            setProcessing(false)

        },250)
        
    }

    function submitAnswer(){

        if(index < assessment.length-1){

            setProcessing(true)
            
            resetChoices()
            setIndex(index+1)
            
            setTimeout(()=>{
                setProcessing(false)

                finalAnswers[index] = answer
                
                if(finalAnswers[index+1]) selectAnswer(finalAnswers[index+1])
                else setAnswer(null)

            },500)

        }else{

            setProcessing(true)
            setTimeout(()=>{

                resetChoices()
                finalAnswers[index] = answer
                setAnswer(null)

                var jsonAnswers = '{';
                var correctAnswers = 0

                for(var i in assessment){
                    jsonAnswers += `"${parseInt(i)+1}":`
                    jsonAnswers += `{"index":"${assessment[i]['Index']}", "answer":"${finalAnswers[i]}"},`

                    if(assessment[i]['Answer'] === finalAnswers[i]) correctAnswers++
                }

                jsonAnswers = jsonAnswers.slice(0,-1)
                jsonAnswers += '}'

                jsonAnswers = JSON.parse(jsonAnswers)

                

                
                var answerToSubmit = {
                    attempt:attemptCount+1,
                    answers:jsonAnswers,
                    score:correctAnswers
                }

                set(ref(studentDB,`assessments/${student.uid}/${params.get('chapter')}`),answerToSubmit).then(()=>{
                    setProcessing(false)
                }).catch((error)=>{
                    console.log(error.message)
                })

            },500)

        }

    }

    return(
        <>
        
        {started?
        <div className="row justify-content-center assessment-main-container animate__animated animate__fadeIn">
            <div className="col-lg-6 col-11 card-container pb-3 px-0 my-5 assessment-container align-self-center">
                <p className="mb-2" style={{backgroundColor:'var(--accent)',borderRadius:'10px',height:'5px',backgroundPosition:'center bottom'}}/>

                <div className="text-center mt-3 mb-2">
                    <img className="darkmode-logo" src={blackWordLogo} style={{width:'calc(12.5rem + 5vw)'}} alt='logo'/>
                </div>

                <div className="row px-5 pt-4">
                    <div className="col-12 mb-4">
                        <p className="bold text-blue text-justify assessment-question" style={{fontSize:'calc(0.85rem + 0.3vw)'}} dangerouslySetInnerHTML={{__html:`${index+1}. ${assessment[index].Question}`}}/>
                    </div>

                    {assessmentImages[index] &&
                    <div className="col-12 mb-4 justify-content-center text-center">
                        <img className="assessment-image" src={assessmentImages[index]} alt='assessment'/>
                    </div>
                    }

                    {assessment[index].Choices.map((choice)=>{
                        
                        return(
                            <p id={`answer-${choice['letter']}`} key={`answer-${choice['letter']}`} style={{fontSize:'calc(0.8rem + 0.2vw)'}} className="pointer px-5 py-3 mb-1 mt-1 assessment-choice text-blue" onClick={()=>selectAnswer(choice['letter'])}><b>{choice['letter']}.</b> {choice['choice']}</p>
                        )

                    })}

                    <div className="col-12 mt-3">
                        <div className="row justify-content-center">

                            <div className="col-lg-2 col-4">
                                {index !== 0?
                                <button className="btn-blue py-2 w-100" style={{fontSize:'calc(0.8rem + 0.2vw)'}} onClick={()=>prevQuestion()}><i className="fa fa-arrow-left"/></button>
                                :
                                <button className="btn-blue py-2 w-100" style={{fontSize:'calc(0.8rem + 0.2vw)'}} disabled><i className="fa fa-arrow-left"/></button>
                                }
                            </div>

                            <div className="col-lg-10 col-8">

                                {answer?
                                <button className="btn-blue py-2 w-100" style={{fontSize:'calc(0.8rem + 0.2vw)'}} onClick={()=>submitAnswer()}>{index < assessment.length-1?'Next':'Submit'}<i className={`ms-2 ${index < assessment.length-1?'fa fa-arrow-right':'fa fa-check'}`}/></button>
                                :
                                <button className="btn-blue py-2 w-100" disabled style={{fontSize:'calc(0.8rem + 0.2vw)'}}>{index < assessment.length-1?'Next':'Submit'}<i className={`ms-2 ${index < assessment.length-1?'fa fa-arrow-right':'fa fa-check'}`}/></button>
                                }

                            </div>
                        </div>
                        
                    </div>

                    <p className="mt-3 px-4 text-lg-start text-center" style={{opacity:'0.5',fontSize:'calc(0.75rem + 0.15vw)'}}>Knowledge Assessment - <b>{course.title}</b></p>

                </div>
                

            </div>
        </div>
        :
        course &&
        <div className="row justify-content-center assessment-main-container animate__animated animate__fadeIn">
            
            <div className="col-lg-6 col-11 card-container pb-5 px-0 my-5 assessment-container align-self-center mx-0">
        
                
                <p className="mb-4" style={{backgroundImage:`url(${course.thumbnailURL})`,borderRadius:'10px',height:'5px',backgroundPosition:'center bottom'}}/>
                
                {course &&
                <div className="row justify-content-center">
                    <div className='col-lg-5 col-11 animate__animated animate__fadeIn'>
                        <p className='text-center module-chapter mb-2' style={{fontVariant:'small-caps',backgroundImage:`url(${course.thumbnailURL})`,fontSize:'20px'}}>Chapter {params.get('chapter')}</p>
                        <div className="px-lg-0 px-5">
                            <img alt='icon' className='course-thumbnail animate__animated animate__fadeIn px-lg-0' style={{width:"100%",borderRadius:"25px"}} src={course.thumbnailURL}/>
                        </div>
                    </div>
                    <div className="col-lg-5 col-11 align-self-center px-5 mt-lg-0 mt-4">
                        <p className="text-center"><i className="fa-solid fa-file-circle-question module-chapter mb-2" style={{fontSize:'32px',backgroundImage:`url(${course.thumbnailURL})`,backgroundPosition:'right top'}}/></p>
                        <p className="text-center bold module-chapter" style={{fontSize:'18px',backgroundImage:`url(${course.thumbnailURL})`,backgroundPosition:'right top'}}>Ready to test your learnings? Answer these 20 questions.</p>
                        <hr/>
                    
                        <p className="bold text-center module-chapter" style={{fontSize:'14px',backgroundImage:`url(${course.thumbnailURL})`,backgroundPosition:'right top'}}><i className="fa-solid fa-repeat text-light-blue"/> {2-attemptCount} Chances Left</p>

                        <div className="row">
                            <button className="py-2 px-5 mt-3" style={{backgroundImage:`url(${course.thumbnailURL})`,backgroundPosition:'right bottom'}} onClick={()=>takeAssessment()}>Take Assessment</button>        
                        </div>
                    </div>
                </div>
                }

            </div>
            
        </div>
        }
            <ModalLoading show={processing}/>
        </>
    )
}