import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import $ from 'jquery'

import { globalRegions, globalProvinces } from '../../helper/globals';
import {hasLetter, hasSpecial, toTitleCase} from '../../helper/formatter'
import ModalLoading from '../general/ModalLoading';
import { ref, set } from 'firebase/database';
import { studentDB } from '../../api/firebase-student-config';

export default function ModalClaimFund(props) {


  const [bankWalletName,setBankWalletName] = useState()
  const [accountName,setAccountName] = useState()
  const [accountPhoneNumber,setAccountPhoneNumber] = useState()


  const [processing,setProcessing] = useState(false)

  function setDefault(){
    if(props.claimData){
      var cd = props.claimData

      setBankWalletName(cd.bankWalletName);
      setAccountName(cd.accountName);
      setAccountPhoneNumber(cd.accountPhoneNumber);

      $('#txtBankWalletName').val(cd.bankWalletName);
      $('#txtAccountName').val(cd.accountName);
      $('#txtAccountPhoneNumber').val(cd.accountPhoneNumber);

    }
  }

  function verifyBankWalletName(){

    var txtBankWalletName = $('#txtBankWalletName').val()
    var errBankWalletName = $('#errBankWalletName')

    errBankWalletName.text('')
    setBankWalletName('')

    if(txtBankWalletName.length <= 0) errBankWalletName.text('Do not leave blank fields.')
    else setBankWalletName(toTitleCase(txtBankWalletName))
    
  }

  function verifyAccountName(){

    var txtAccountName = $('#txtAccountName').val()
    var errAccountName = $('#errAccountName')

    errAccountName.text('')
    setAccountName('')

    if(txtAccountName.length <= 0) errAccountName.text('Do not leave blank fields.')
    else setAccountName(toTitleCase(txtAccountName))
    
  }

  function verifyAccountPhoneNumber(){

    var txtAccountPhoneNumber = $('#txtAccountPhoneNumber').val()
    var errAccountPhoneNumber = $('#errAccountPhoneNumber')

    errAccountPhoneNumber.text('')
    setAccountPhoneNumber('')

    if(txtAccountPhoneNumber.length <= 0) errAccountPhoneNumber.text('Do not leave blank fields.')
    else setAccountPhoneNumber(txtAccountPhoneNumber)
  
  }

  function submitDetails(){

    setProcessing(true)
    
    var body = {
      status:'PENDING',
      bankWalletName:bankWalletName,
      accountName:accountName,
      accountPhoneNumber:accountPhoneNumber
    }

    set(ref(studentDB,`students/${props.uid}/quarterlyChallenge/`),body).then(()=>{
      setProcessing(false)
      props.onHide()
    })

  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onShow={()=>setDefault()}
      > 

      <Modal.Header className='bg-blue' closeButton>
        
        <h4 className='saira text-center text-white m-0'><i className='fa fa-trophy'/> Claim Fund Prize</h4>

      </Modal.Header>

        <Modal.Body className='bg-white-blue' style={{borderRadius:"30px"}}>
          {props.student &&
          <div className='row justify-content-center px-3 py-2' style={{rowGap:'15px'}}>

            <h4 className='text-blue saira bold mb-0'>Details</h4>
            <hr className='m-0'/>

            <div className='col-lg-8'>
              <p className='text-blue saira ms-2 bold'>Full Name</p>
              <input className='form-control' type='text' placeholder='Full Name' value={`${props.student.profile.name.firstName} ${props.student.profile.name.lastName}`} disabled/>
            </div>

            <div className='col-lg-4'>
              <p className='text-blue saira ms-2 bold'>Phone Number</p>
              <input className='form-control' type='text' placeholder='Phone Number' value={`${props.student.profile.contactNumber}`} disabled/>
            </div>

            <p className='text-muted small ms-3'>*You can edit your name and number on your profile.</p>

            <h4 className='text-blue saira bold mb-0 mt-3'>Account Details</h4>
            <hr className='m-0'/>

            <div className='col-12'>
              <p className='text-blue saira ms-2 bold'>Bank / E-Wallet Name <small style={{fontSize:'12px'}}>(GCash, BDO, ChinaBank etc.)</small></p>
              <input id='txtBankWalletName' className='form-control' type='text' onChange={()=>verifyBankWalletName()}/>
              <small id='errBankWalletName' className='text-error'></small>
            </div>
            
            <div className='col-12'>
              <p className='text-blue saira ms-2 bold'>Account Name</p>
              <input id='txtAccountName' className='form-control' type='text' onChange={()=>verifyAccountName()}/>
              <small id='errAccountName' className='text-error'></small>
            </div>

            <div className='col-12'>
              <p className='text-blue saira ms-2 bold'>Account / Phone Number</p>
              <input id='txtAccountPhoneNumber' className='form-control' type='text' onChange={()=>verifyAccountPhoneNumber()}/>
              <small id='errAccountPhoneNumber' className='text-error'></small>
            </div>


            <hr className='m-0'/>

            <div className='col-12'>
              {accountName && accountPhoneNumber && bankWalletName?
              <button className='btn-solid w-100 py-2' onClick={()=>submitDetails()}> <i className='fa fa-box'/> Submit Details</button>
              :
              <button className='btn-solid w-100 py-2' disabled> <i className='fa fa-check'/> Submit Details</button>
              }
            </div>

            <hr className='m-0'/>
            <p className='small text-muted'><b>*DISCLAIMER : </b> Please double check your details. Once you submit wrong details or number and our team has transacted your monetary prize, you are liable for that loss and mistake.</p>

          </div>
          }

        </Modal.Body>

      </Modal>

      <ModalLoading show={processing}/>

    </>
  );
}