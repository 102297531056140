import Modal from 'react-bootstrap/Modal';
export default function ModalAlert(props) {

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdropClassName='top-most'
      > 

        <Modal.Body className='bg-white-blue' style={{borderRadius:"30px"}}>

          <div className='row py-2 px-3'>

            <h4 className='saira text-blue'><i className={`${props.icon} me-2`}/>{props.title}</h4>

            <p className='text-blue mt-2 text-justify' style={{padding:"0px 30px"}} dangerouslySetInnerHTML={{__html:props.message}}/>

            <div className='col-12 d-flex mt-4 flex-row-reverse' style={{gap:"10px"}}>
              <button className='form-control btn-blue px-5' style={{width:"fit-content"}} onClick={props.onHide}>Okay</button>
            </div>
            
          </div>

        </Modal.Body>

      </Modal>

    </>
  );
}