import Modal from 'react-bootstrap/Modal';

import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop'
import getCroppedImg from '../../helper/cropImage'

export default function ModalCropper(props) {

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    function setDefault(){
        setZoom(1)
        setCroppedAreaPixels(null)
        setCrop({x:0,y:0})
        setRotation(0)
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {

        try {
          const croppedImage = await getCroppedImg(
            props.image,
            croppedAreaPixels,
            rotation
          )
          console.log('donee', { croppedImage })
          props.onCrop(croppedImage)
        } catch (e) {
        
          console.error(e)
        }

      }, [croppedAreaPixels, rotation,props])


    return (
        <>
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop='static'   
            backdropClassName={props.noBackdrop?'no-backdrop':''}
            onExit={()=>setDefault()}
            onShow={()=>setDefault()}
        >
            
            <Modal.Header className='bg-blue' closeButton>
            
            <h4 className='saira text-center text-white m-0'><i class="fa-solid fa-crop"></i> Crop Image</h4>

            </Modal.Header>

            <Modal.Body className='bg-white-blue'>

                <div className='row justify-content-center py-3' style={{minHeight:'500px'}}>

                    <div className='col-12'>
                        <Cropper
                        image={props.image}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        aspect={1 / 1}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        />
                    </div>
                </div>
                
                

            </Modal.Body>

            <Modal.Footer className='bg-blue'>
                <div className='row d-flex d-inline'>   
                    <button className='btn-blue py-1' onClick={showCroppedImage}>Confirm</button>
                </div>
            </Modal.Footer>

        </Modal>

        </>
    );
}