import { push, ref, set } from "firebase/database";
import { studentDB } from "./firebase-student-config";
import moment from "moment";

export async function getNotificationPermission(uid){
    if(!("Notification" in window)){
        alert("Browser does not support notifications");
    }else if (Notification.permission !== "denied" && Notification.permission !== "granted"){
        await Notification.requestPermission().then((permission)=>{
            if(permission === "granted"){

                var d = moment().format("MMMM DD, YYYY hh:mm:ss a");

                let welcomeNotif = {
                    icon:"fa fa-thumbs-up",
                    image:"https://tradingcircle.com.ph/logo192.png",
                    link:"#",
                    message:"Thank you for enabling notifications.",
                    read:false,
                    time:d
                }

                push(ref(studentDB,`notifications/${uid}`),welcomeNotif)

            }
        });
    }else{
        //alert("Permission Denied");
    }

}


export function notifyUser(notificationText = "Thank you for enabling notifications!",link="#",key,uid){
    
    var answer = navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i);

    if(!answer){

        if(!("Notification" in window)){
            alert("Browser does not support notifications");
        }else if(Notification.permission === "granted"){
            const notification = new Notification('Notification',{
                body:notificationText.replace(/(<([^>]+)>)/ig, ''),
                icon:'https://tradingcircle.com.ph/logo192.png',
                vibrate: [200, 100, 200],
                badge:'https://tradingcircle.com.ph/logo192.png'
            });

            notification.onclick = (e)=>{
                e.preventDefault();

                set(ref(studentDB,`notifications/${uid}/${key}/read`),true).then(()=>{
                    window.focus();
                    window.location.href = link;
                })
                
            }
            
            setTimeout(() => {
                notification.close();
            }, 10 * 1000);

        }
    }
    
}