import Modal from 'react-bootstrap/Modal';
import placeholder from '../../img/placeholder/image-placeholder.png'
import { useEffect, useState } from 'react';

export default function ModalAttachmentConfirmation(props) {

  const [image,setImage] = useState()
  const [fileName,setFileName] = useState()

  useEffect(()=>{

    if(props.attachment && props.type){

      if(props.type === 'IMAGE'){
        setImage(URL.createObjectURL(props.attachment))
        setFileName(null)
      }
      else{
        setFileName(props.attachment.name)
        setImage(null)
      }

    }

  },[props.attachment,props.type])

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      > 

        <Modal.Body className='bg-white-blue' style={{borderRadius:"30px"}}>

          <div className='row py-2 px-3'>

            <h4 className='saira text-blue'><i className='fa fa-paperclip'/> Upload Attachment</h4>

            {fileName && <p className='text-center bold text-blue my-4 py-3 px-2' style={{background:'#d3daea',borderRadius:'15px'}}><i className='fa fa-file'/> {fileName}</p>}

            {image && <img className='pointer' onClick={()=>window.open(image)} src={image?image:placeholder} width='100%' alt='attachment' style={{borderRadius:'25px'}}/>}

            <p className='text-blue mt-2' style={{padding:"0px 30px"}}>Are you sure you want to upload the Attachment?</p>

            <div className='col-12 d-flex mt-4 flex-row-reverse' style={{gap:"10px"}}>
              <button className='form-control btn-transparent px-5' style={{width:"fit-content"}} onClick={props.onHide}>Cancel</button>
              <button className='form-control btn-blue px-5' style={{width:"fit-content"}} onClick={props.onConfirm}>Yes</button>
            </div>
            
          </div>

        </Modal.Body>

      </Modal>
    </>
  );
}