import { Helmet } from "react-helmet-async"
import { PolicySidebar } from "../../components/general/PolicySidebar"


export const PrivacyPolicy = () =>{

    return(
        <div className="container-fluid">

            <Helmet>
                <title>Trading Circle - Privacy Policy</title>
            </Helmet>

            <div className="row justify-content-center p-lg-5 p-0">

                <PolicySidebar/>

                <div className="col-lg-6 text-blue policy-container ps-lg-4 px-5 animate__animated animate__fadeIn">
                    <h3 className="bold saira mt-lg-5 mt-4">Privacy Policy</h3>
                    <h5 className="bold saira">What is the Privacy Policy and what does it cover?</h5>

                    <div className="content mt-3 mb-5">
                        <p className="mb-2 text-justify">We at Trading Circle want you to understand what information we collect, and how we use and share it. That's why we encourage you to read our Privacy Policy. This helps you use our Products in the way that's right for you.</p>
                        <p className="mb-2 text-justify">In the Privacy Policy, we explain how we collect, use, share, retain and transfer information. We also let you know your rights. Each section of the Policy includes helpful examples and simpler language to make our practices easier to understand. It's important to us that you know how to control your privacy.</p>
                    </div>

                    <h5 className="bold saira">Who We Are</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-2 text-justify">We are Trading Circle the first community-building group for traders that emphasizes interaction and learning in one platform. This community is designed for humble beginners who want to achieve financial freedom through Forex Market.</p>
                    </div>

                    <h5 className="bold saira">Media</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-2 text-justify">If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors of the website can download and extract any location data from images on the website.</p>
                    </div>

                    <h5 className="bold saira">Cookies</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-2 text-justify">If you have an account and you log in to this site, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.</p>
                    </div>

                    <h5 className="bold saira">Embedded Content From Other Websites</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-2 text-justify">Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</p>
                        <p className="mb-2 text-justify">These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</p>
                    </div>

                    <h5 className="bold saira">How Long We Retain Your Data</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-2 text-justify">If you leave any content, the content and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up contents automatically instead of holding them in a moderation queue.</p>
                        <p className="mb-2 text-justify">For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their email). Website administrators can also see and edit that information.</p>
                    </div>

                    <h5 className="bold saira">What Rights You Have Over Your Data</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-2 text-justify">If you have an account on this site or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</p>
                        <p className="mb-2 text-justify">Visitor contents may be checked through an automated spam detection service.</p>
                    </div>

                </div>

            </div>

        </div>
    )

}