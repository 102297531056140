
import { useEffect, useState } from 'react';
import $ from 'jquery';
import profilePlaceholder from '../../img/placeholder/profile-placeholder.png';
import imagePlaceholder from '../../img/placeholder/image-placeholder.png';

import { Helmet } from 'react-helmet-async';
import {onAuthStateChanged} from 'firebase/auth'
import {onValue, ref, update} from 'firebase/database'
import {getDownloadURL, ref as sref, uploadBytes} from 'firebase/storage'
import {studentAuth, studentDB, studentStorage} from '../../api/firebase-student-config'
import ModalLoading from '../../components/general/ModalLoading';

import {useSearchParams} from 'react-router-dom'
import moment from 'moment';

import axios from 'axios'
import { getFileExt } from '../../helper/formatter';

export const ProofFunnel = () =>{

    const [params] = useSearchParams()

    const [processing,setProcessing] = useState(false);
    const [student,setStudent] = useState()
    const [studentInfo,setStudentInfo] = useState()

    const [proofURL,setProofURL] = useState(imagePlaceholder)
    const [status,setStatus] = useState(`<span class='text-danger'>Unpaid</span>`)
    const [reason,setReason] = useState()

    const [redirectURL,setRedirectURL] = useState('https://www.tradingcircletraining.com.ph/enroll-fb')
    const [type,setType] = useState()

    const [canSubmit,setCanSubmit] = useState(true)

    const [qualified,setQualified] = useState(true)
  
    useEffect(()=>{

        onAuthStateChanged(studentAuth,(s)=>{
            
            if(s) setStudent(s)
            else{
                setStudent(null)
                setStudentInfo(null)
            }

        },[])

        $('body').css('overflow','hidden')
        $('body').css('height','100vh !important')

        if(window.self === window.top){
            window.location.href='/register'
        }

        if(params.get('funnel') !== null){
            if(params.get('funnel') === 'YouTube') setRedirectURL('https://www.tradingcircletraining.com.ph/enroll-yt');
            else if(params.get('funnel') === 'Facebook') setRedirectURL('https://www.tradingcircletraining.com.ph/enroll-fb')
            //alert(params.get('funnel'))
        }

        if(params.get('type') !== null){
            setType(params.get('type'))
        }

    },[params])

    useEffect(()=>{

        if(student){

            onValue(ref(studentDB,`students/${student.uid}`),(snapshot)=>{
                if(snapshot.exists()){
                    setStudentInfo(snapshot.val())

                    if(params.get('type') === 'Elite' && !snapshot.val().level){
                        setQualified(false)
                    }

                    if(snapshot.val().manualPayment){

                        if(type === 'Standard'){
                            
                            if(snapshot.val().manualPayment.standard.status === 'Pending') setStatus(`<span class='text-pending'>Pending</span>`)
                            else if(snapshot.val().manualPayment.standard.status === 'Rejected'){
                                setStatus(`<span class='text-error'>Rejected</span>`)
                                setReason(snapshot.val().manualPayment.standard.reason)
                            }else if(snapshot.val().manualPayment.standard.status === 'Verified'){
                                setStatus(`<span class='text-success'>Verified</span>`)
                                setCanSubmit(false)
                            }                            
                            setProofURL(snapshot.val().manualPayment.standard.proof)

                        }else if(type === 'Elite'){

                            if(snapshot.val().manualPayment.elite.status === 'Pending') setStatus(`<span class='text-pending'>Pending</span>`)
                            else if(snapshot.val().manualPayment.elite.status === 'Rejected'){
                                setStatus(`<span class='text-error'>Rejected</span>`)
                                setReason(snapshot.val().manualPayment.elite.reason)
                            }else if(snapshot.val().manualPayment.elite.status === 'Verified'){
                                setStatus(`<span class='text-success'>Verified</span>`)
                                setCanSubmit(false)
                            }
                            setProofURL(snapshot.val().manualPayment.elite.proof)
                        }

                    }else{
                        setStatus(`<span class='text-danger'>Unpaid</span>`)
                        setProofURL(imagePlaceholder)
                    }

                }
            })

        }

    },[student,type,params])

    function openProof(){
        if(proofURL === imagePlaceholder) return
        window.open(proofURL)
    }

    function sendAdminMail(){

        setProcessing(true)

        axios.post('https://us-central1-trading-circle-ph-admin.cloudfunctions.net/app/api/email',{
          subject:`New Enrollment - ${type==='standard'?'NEW':'UPGRADE'}`,
          email:'payment@tradingcircle.com.ph',
          html:`        
          <div style='display:table;margin:0 auto;'>
            <div style='max-width:500px;margin:0 auto;'>
              
                <p style='text-align:center;'>
                    <img src='https://tradingcircle-ph.web.app/static/media/black-word-logo.1bf10b226314dd33a7ab.png' width='350px'/>
                </p>
        
                <hr/>
        
                <h2 style='white-space:pre;color:#01245A;margin-bottom:0;'>New Enrollment - ${type==='standard'?'NEW':'UPGRADE'}</h2>
                <p style='color:#01245A;margin-top:0;margin-bottom:0;'>Attached below is the payment proof of the enrollee.</p>

                <table style='color:#01245A;border-collapse:collapse;'>
                    <tr>
                        <th colspan='2' style='color:white;background-color:#01245A;border: 1px solid #ddd;text-align:center;padding:8px;'>Account Details</th>
                    </tr>
                    <tr>
                        <th style='border: 1px solid #ddd;text-align:left;padding:8px;'>Full Name</th>
                        <td style='border: 1px solid #ddd;text-align:left;padding:8px;'>${studentInfo.profile.name.firstName} ${studentInfo.name.lastName}</td>
                    </tr>
                    <tr>
                        <th style='border: 1px solid #ddd;text-align:left;padding:8px;'>Email Address</th>
                        <td style='border: 1px solid #ddd;text-align:left;padding:8px;'>$ ${studentInfo.profile.emailAddress}</td>
                    </tr>
                    <tr>
                        <th style='border: 1px solid #ddd;text-align:left;padding:8px;'>Payment Type</th>
                        <td style='border: 1px solid #ddd;text-align:left;padding:8px;'>${type==='standard'?'NEW':'UPGRADE'}</td>
                    </tr>
                </table>

                <img src='${proofURL}'/>
        
            </div>
          </div>
          `
        }).then((res)=>{
    
          if(res.status === 200){
            setProcessing(false)
          }
    
        })
    
    }

    function uploadProof(){

        setProcessing(true)
        
        var file = $('#fileUploadProof').prop('files')[0]
        var txtErr = $("#errProfilePicture")
        txtErr.text('')

        if(!file){
            setProcessing(false)
            return;
        }else{
            if(getFileExt(file.name).toLowerCase() !== 'png' && getFileExt(file.name).toLowerCase() !== 'jpg' && getFileExt(file.name).toLowerCase() !== 'jpeg'){
                
                txtErr.text('PNG and JPG Files are the only accepted files.')
                $('#fileUploadProof').val(null)
                setProcessing(false)
                return
            }
        }

        setStatus("<span class='text-danger'>Unpaid</span>")

        uploadBytes(sref(studentStorage,`students/paymentProof/${student.uid}`),file).then(()=>{

            getDownloadURL(sref(studentStorage,`students/paymentProof/${student.uid}`)).then((url)=>{
                
                setProofURL(url)

                var type = `${studentInfo.level?'elite':'standard'}`
                var body = {}

                var time = moment().format()

                if(type === 'standard') body['standard'] = {'status':'Pending','proof':url,'date':time}
                else if(type === 'elite') body['elite'] = {'status':'Pending','proof':url,'date':time}

                update(ref(studentDB,`students/${student.uid}/manualPayment`),body).then(()=>{

                    setProcessing(false)
                    setReason(null)

                    sendAdminMail()

                }).catch((error)=>{
                    console.log(error)
                    setProcessing(false)
                })
                
            })

        }).catch((error)=>{
            console.log(error)
            setProcessing(false)
        })

    }

    return(
        <>
            <Helmet>
                <title>Trading Circle - Proof</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn" style={{background:'white'}}>

                <div className="row justify-content-center mx-0" style={{height:'100vh'}}>
                    <div className='col-12 align-self-center'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-12 justify-content-center text-center align-self-center'>
                                <img style={{borderRadius:"100rem"}} src={studentInfo?studentInfo.profile.profilePictureURL:profilePlaceholder} alt='profile' width="75px"/>
                                <p className='mt-2 mb-1 text-center text-blue bold'>{studentInfo?studentInfo.profile.name.firstName + ' ' + studentInfo.profile.name.lastName:''}</p>
                                <p className='mb-4'>{studentInfo && !canSubmit?'You may now access the website by clicking the button below.':studentInfo?'Please upload a screenshot of the payment proof so Trading Circle can verify it.':<span>{type === 'Standard' && <><b>Enroll</b> or</>} <b>Log in</b> to your account to confirm your payment.</span>}</p>
                                <hr className='mb-4 mt-0'/>
                            </div>

                            <div className='col-lg-12 justify-content-center text-center'>
                                
                                {studentInfo?
                                <div className='row justify-content-center'>
                                    <h3 className='saira bold text-blue'>{type === 'Elite'?'Elite Payment Proof':'Standard Payment Proof'}</h3>
                                    <div className='col-12 justify-content-center d-flex'>
 
                                        <div id='imgProof' className={`card-container ${proofURL !== imagePlaceholder && 'pointer'}`} style={{
                                            backgroundImage:`url('${proofURL}')`,
                                            backgroundPosition:'center center', 
                                            backgroundSize:'cover',
                                            backgroundRepeat:'no-repeat',
                                            width:'500px',height:'300px'}}
                                            onClick={()=>openProof()}
                                        />

                                    </div>
                                    {proofURL !== imagePlaceholder && <span className='mt-1 text-muted small'>*Tap/Click image to view</span>}

                                    <div className='col-lg-6 col-10 mt-3'>
                                        {canSubmit && qualified && 
                                        <>
                                            <input className='form-control' type='file' accept='image/png,image/jpg,image/jpeg' id='fileUploadProof' onChange={()=>uploadProof()}/>
                                            <small id="errProfilePicture" class="form-text text-danger text-center mb-3 mt-2"></small>
                                        </>
                                        }
                                        {qualified?
                                        <>
                                            <h5 className='saira mt-3'>STATUS: <span dangerouslySetInnerHTML={{__html:status}}></span></h5>
                                            <p className='text-error'>{reason}</p>
                                            {status && status.includes('Pending') && <p className='text-success'><i className='fa fa-check'/> Payment Proof Sent</p>}
                                            {status && status.includes('Pending') && <p className='text-muted small'>*You will be notified here and on your email ({studentInfo.profile.emailAddress})</p>}
                                        </> 
                                        :
                                        <>
                                            <h5 className='saira mt-3'>STATUS: <span className='text-error'>NOT ELIGIBLE FOR UPGRADE</span></h5>
                                            <p className='text-error'>You are not a Standard Member yet.</p>
                                        </>
                                        }
                                        {!canSubmit && <a href='http://tradingcircle.com.ph/' target='_top'><button className='btn-solid px-3 py-2 w-100 mt-3'><i className='fa fa-check'/> Access Website</button></a>}
                                    </div>

                                    <p className='text-muted small mt-3'>{reason && "*Please resubmit the correct payment proof."}</p>
                                </div>
                                :
                                <div className='row justify-content-center'>
                                    <div className='col-lg-8 col-10'>
                                        <a href={redirectURL} target='_top'><button className='px-3 py-2 btn-solid w-100'> <i className='fa fa-sign-in me-2'/>Enroll / Log In</button></a>
                                    </div>
                                </div>
                                }
                            </div> 

                        </div>
                    </div>
                </div>

            </div>
                   
            <ModalLoading show={processing} />
            
        </>
    );

}