import Modal from 'react-bootstrap/Modal';
export default function ModalConfirmation(props) {

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdropClassName='top-most'
      > 

        <Modal.Body className='bg-white-blue' style={{borderRadius:"30px"}}>

          <div className='row py-2 px-3'>

            <h4 className='saira text-blue' dangerouslySetInnerHTML={{__html:props.title?props.title:"Are You Sure?"}}></h4>
            <p className='text-blue mt-2' style={{padding:"0px 30px"}} dangerouslySetInnerHTML={{__html:props.message?props.message:"Are you sure you want to proceed?"}}></p>

            <div className='col-12 d-flex mt-4 flex-row-reverse' style={{gap:"10px"}}>
              <button className='form-control btn-transparent px-5' style={{width:"fit-content"}} onClick={props.onHide}>{props.cancelButton || "Cancel"}</button>
              <button className='form-control btn-blue px-5' style={{width:"fit-content"}} onClick={props.onConfirm}>{props.yesButton || "Yes"}</button>
            </div>
            
          </div>

        </Modal.Body>

      </Modal>

    </>
  );
}