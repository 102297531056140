import { Helmet } from "react-helmet-async"
import ModalLoading from "../../components/general/ModalLoading"
import { useEffect, useState } from "react"
import { Header } from "../../components/general/Header"

import placeholder from '../../img/placeholder/profile-placeholder.png'
import { onAuthStateChanged } from "firebase/auth"
import { studentAuth, studentDB } from "../../api/firebase-student-config"
import { onValue, ref, set } from "firebase/database"
import ModalCreatePost from "../../components/academy/ModalCreatePost"
import { forumDB } from "../../api/firebase-forum-config"
import moment from "moment"
import ModalViewImage from "../../components/general/ModalViewImage"
import { globalCoaches, globalDevelopers, reactColor, reactEmoji } from "../../helper/globals"
import ModalViewPost from "../../components/academy/ModalViewPost"
import ModalViewReaction from "../../components/academy/ModalViewReaction"
import ModalConfirmation from "../../components/general/ModalConfirmation"
import { useSearchParams } from "react-router-dom"

export const Forum = (props)=>{

    const [processing, setProcessing] = useState(false)

    const [uid,setUID] = useState()
    const [student,setStudent] = useState()

    const [posting,setPosting] = useState(false)

    const [students,setStudents] = useState()

    const [posts, setPosts] = useState([])

    const [finalPosts,setFinalPosts] = useState([])

    const [limit,setLimit] = useState(5)

    const [selectedImage,setSelectedImage] = useState()

    const [selectedPost,setSelectedPost] = useState()
    const [selectedReaction,setSelectedReaction] = useState(null)

    const [deletingPost,setDeletingPost] = useState(null)

    const [params] = useSearchParams()

    useEffect(()=>{

        setProcessing(true)

        onAuthStateChanged(studentAuth,(s)=>{
            if(s) setUID(s.uid)
        })

        onValue(ref(studentDB,'students'),(snap)=>{
            if(snap.val()){
                setStudents(snap.val())
            }
        })

    },[])

    useEffect(()=>{

        if(params.get('id') && posts.length > 0){
            setSelectedPost(params.get('id'))
        }

    },[params, posts])

    useEffect(()=>{

        if(students){
            onValue(ref(forumDB,`${props.level}/posts`),(snap)=>{
                if(snap.val()){
                
                    var ps = []

                    for(var k in snap.val()){
                        var p = snap.val()[k]
                        p.key = k
                        ps.push(p)
                    }

                    setPosts(ps.reverse())

                }else{
                    setProcessing(false)
                }
            })

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[students])

    useEffect(()=>{

        if(posts.length > 0){
            
            var limitedPosts = []

            for(var i = 0; i < limit;i++){
                if(posts[i]) limitedPosts.push(posts[i])
                else break
            }

            setFinalPosts(limitedPosts)
            setProcessing(false)

        }

    },[posts,limit])

    useEffect(()=>{

        if(uid){
            onValue(ref(studentDB,`students/${uid}`),(snap)=>{
                setStudent(snap.val())
            })
        }

    },[uid])

    function postReact(reaction,post){

        if(post.reaction && post.reaction[uid] && post.reaction[uid] === reaction){
            set(ref(forumDB,`${props.level}/posts/${post.key}/reaction/${uid}`),null).then(()=>{
                setProcessing(false)
            })
        }else{
            set(ref(forumDB,`${props.level}/posts/${post.key}/reaction/${uid}`),reaction).then(()=>{
                setProcessing(false)
            })
        }

    }

    function deletePost(){

        setProcessing(true)
        var postID = deletingPost
        setDeletingPost(null)

        set(ref(forumDB,`${props.level}/posts/${postID}`),null).then(()=>{
            set(ref(forumDB,`${props.level}/comments/${postID}`),null).then(()=>{
                setProcessing(false)
            })
        })

    }

    return(
        <>
            <Helmet>
                <title>Trading Circle - {props.level} Forum</title>
            </Helmet>

            <div className="container-fluid animate__animated animate__fadeIn p-4" style={{overflowX:'hidden'}}>

                <Header title={`<span class='fa ${props.icon}'></span> ${props.level} Forum`}/>

                <div className='row justify-content-center mb-5'>

                    <div className='col-lg-10'>

                        <div className="row justify-content-center">


                            <div className="col-lg-6 forum-card-container pb-4">
                                <div className="row">
                                    <div className="col-12 d-flex">
                                        <img className="me-2 profile" src={student?student.profile.profilePictureURL:placeholder} alt="profile"/> <p className="align-self-center create-post-button text-blue" style={{fontSize:'calc(0.1vw + 0.85rem)'}} onClick={()=>setPosting(true)}>What's on your mind?</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mt-5">
                                <div className="row justify-content-center" style={{gap:'10px'}}>

                                    {finalPosts.length === 0 &&
                                    <div className="col-lg-6 forum-card-container animate__animated animate__fadeInUp">
                                        <h3 className="text-muted text-center">No Posts Yet</h3>
                                    </div>
                                    }

                                    {finalPosts.map((p)=>{

                                        var s = students[p.owner]
                                        var emoji = ''
                                        var reactCount = 0

                                        var images = []
                                        
                                        if(p.images){
                                            console.log(p.images)
                                            for(var i in p.images){
                                                images.push(p.images[i])
                                            }
                                        }

                                        if(p.reaction){
                                            reactCount = Object.keys(p.reaction).length
                                            var reacts ={
                                                'Like':0,
                                                'Love':0,
                                                'Haha':0,
                                                'Wow':0,
                                                'Sad':0,
                                                'Angry':0
                                            }

                                            for(var react in p.reaction){
                                                reacts[p.reaction[react]] += 1
                                            }

                                            let sortable = [];
                                            for (var r in reacts) {
                                                sortable.push([r, reacts[r]]);
                                            }

                                            sortable.sort(function(a, b) {
                                                return b[1] - a[1];
                                            });
                                            
                                            if(sortable[0][1] !== 0) emoji += reactEmoji[sortable[0][0]]
                                            if(sortable[1][1] !== 0) emoji += reactEmoji[sortable[1][0]]

                                        }

                                        return(
                                            <>
                                                <div className="col-lg-6 forum-card-container animate__animated animate__fadeInUp" key={p.key}>
                                                   <div className="row mb-3 px-3 animate__animated">

                                                        {((student && (globalDevelopers.includes(student.profile.emailAddress) || globalCoaches.includes(student.profile.emailAddress))) || uid === p.owner) &&
                                                        <div className="dropdown post-action-button">
                                                            <button className="text-dark pointer bg-transparent" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical"></i></button>
                                                            <ul className="dropdown-menu saira" style={{transition:'0s'}}>
                                                                <li>
                                                                    <p className="dropdown-item pointer text-danger" onClick={()=>setDeletingPost(p.key)}>
                                                                        <i className="fa fa-trash"></i> Delete Post
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        }

                                                        <div className='col-4 px-0' style={{width:'fit-content'}}>
                                                            <img className="profile-picture profile" src={s?s.profile.profilePictureURL:placeholder} alt="profile"/>
                                                        </div>
                                                        <div className="col-8 align-self-center">
                                                            <p className="align-self-center bold text-blue">{s && s.profile.name.firstName + ' ' + s.profile.name.lastName} {(globalDevelopers.includes(s.profile.emailAddress) || globalCoaches.includes(s.profile.emailAddress)) && <i className="fa fa-circle-check text-light-blue small"/>}</p>
                                                            <span className="text-muted small">{moment(p.datePosted).fromNow()}</span>

                                                            
                                                            {/*s.level === 'Standard' && <span className='small pill'><i className='fa fa-user'/> <span>Standard</span></span>}
                                                            {s.level === 'Elite' && <span className='small pill'><i className='fa fa-bolt'/> <span>Elite</span></span>}
                                                            {/*innerCircle !== 'N/A' && <span className='small pill'><i className='fa-brands fa-discourse'/> <span className='d-lg-inline d-none'>Inner Circle</span></span>}
                                                            {s &&  globalDevelopers.includes(s.profile.emailAddress) && <span className='small pill'><i className='fa fa-computer'/> <span className='d-lg-inline d-none'>Developer</span></span>}
                                                            {s &&  globalCoaches.includes(s.profile.emailAddress) && <span className='small pill'><i className='fa fa-star'/> <span className='d-lg-inline d-none'>Coach</span></span>*/}

                                                        </div>
                                                    </div>

                                                    <div className='row mt-3'>
                                                        {p.content &&
                                                        <div className='col-12'>
                                                            <p className="post-content" dangerouslySetInnerHTML={{__html:p.content}}></p>
                                                        </div>
                                                        }

                                                        {p.attachment &&
                                                        <div className='col-12 create-post-attachment-container py-3 px-4 mt-3'>
                                                            <a href={p.attachment.url} target='_blank' rel='noreferrer'><i className='fa fa-paperclip'/> {p.attachment.name}</a>
                                                        </div>
                                                        }

                                                        {images.length > 0 &&
                                                        <div className='col-12 mt-3 px-0'>
                                                            <div className="row justify-content-center">

                                                                {images.length === 1 &&
                                                                <div className="col-12 post-image" style={{backgroundImage:`url('${images[0]}')`}} onClick={()=>setSelectedImage(images[0])}>
                                                                    <img src={images[0]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                                                                </div>
                                                                }

                                                                {images.length === 2 &&
                                                                <>
                                                                    <div className="col-6 post-image" style={{backgroundImage:`url('${images[0]}')`}} onClick={()=>setSelectedImage(images[0])}>
                                                                        <img src={images[0]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                                                                    </div>
                                                                    <div className="col-6 post-image" style={{backgroundImage:`url('${images[1]}')`}} onClick={()=>setSelectedImage(images[1])}>
                                                                        <img src={images[1]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                                                                    </div>
                                                                </>
                                                                }

                                                                {images.length === 3 &&
                                                                <>
                                                                    <div className="col-6 post-image" style={{backgroundImage:`url('${images[0]}')`}} onClick={()=>setSelectedImage(images[0])}>
                                                                        <img src={images[0]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                                                                    </div>
                                                                    <div className="col-6 post-image" style={{backgroundImage:`url('${images[1]}')`}} onClick={()=>setSelectedImage(images[1])}>
                                                                        <img src={images[1]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                                                                    </div>
                                                                    <div className="col-12 post-image" style={{backgroundImage:`url('${images[2]}')`}} onClick={()=>setSelectedImage(images[2])}>
                                                                        <img src={images[2]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                                                                    </div>
                                                                </>
                                                                }

                                                                {images.length === 4 &&
                                                                <>
                                                                    <div className="col-6 post-image" style={{backgroundImage:`url('${images[0]}')`}} onClick={()=>setSelectedImage(images[0])}>
                                                                        <img src={images[0]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                                                                    </div>
                                                                    <div className="col-6 post-image" style={{backgroundImage:`url('${images[1]}')`}} onClick={()=>setSelectedImage(images[1])}>
                                                                        <img src={images[1]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                                                                    </div>
                                                                    <div className="col-6 post-image" style={{backgroundImage:`url('${images[2]}')`}} onClick={()=>setSelectedImage(images[2])}>
                                                                        <img src={images[2]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                                                                    </div>
                                                                    <div className="col-6 post-image" style={{backgroundImage:`url('${images[3]}')`}} onClick={()=>setSelectedImage(images[3])}>
                                                                        <img src={images[3]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                                                                    </div>
                                                                </>
                                                                }

                                                            </div>
                                                        </div>
                                                        }
                                                    </div>

                                                    <div className="row mt-3">
                                                        
                                                        <div className="col-6 text-start">

                                                            {p.reaction &&
                                                            <p className="text-blue small">
                                                                <span className="reaction-count d-lg-inline d-none" onClick={()=>setSelectedReaction(p.key)}>
                                                                    {emoji}
                                                                    {p.reaction[uid]?
                                                                    `You ${reactCount-1 > 0?`and ${reactCount-1} other/s.`:''}`
                                                                    :
                                                                    reactCount
                                                                    } 
                                                                </span>

                                                                <span className="reaction-count d-lg-none d-inline" onClick={()=>setSelectedReaction(p.key)}>
                                                                    {emoji} {reactCount}
                                                                </span>
                                                            </p>
                                                            }

                                                        </div>

                                                        {p.comments &&
                                                        <div className="col-6 text-end">
                                                            <p className="small text-blue comment-count" onClick={()=>setSelectedPost(p.key)}>{p.comments} <i className="fa fa-comment d-lg-none d-inline"/> <span className="d-lg-inline d-none">Comment/s</span></p>
                                                        </div>
                                                        }

                                                        <hr className="mb-1 mt-1"/>  

                                                        <div className="col-6 text-center post-action py-2 pointer">
                                                            {p.reaction && p.reaction[uid]?
                                                            <p className="animate__animated animate__tada bold" style={{color:`${reactColor[p.reaction[uid]]}`}} onClick={()=>postReact(p.reaction[uid],p)}><span>{reactEmoji[p.reaction[uid]]}</span> {p.reaction[uid]}</p>
                                                            :
                                                            <p className="text-blue" onClick={()=>postReact('Like',p)}><span style={{filter:'grayscale(1)'}}>👍</span> Like</p>
                                                            }
                                                            <div className="react-container py-1 d-flex justify-content-center px-3 animate__animated animate__fadeIn">
                                                                <picture className="react-item" onClick={(e)=>postReact('Like',p)}>
                                                                    <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f44d_1f3fb/512.webp" type="image/webp"/>
                                                                    <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f44d_1f3fb/512.gif" alt="👍" width="32" height="32"/>
                                                                </picture>
                                                                <picture className="react-item" onClick={(e)=>postReact('Love',p)}>
                                                                    <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/2764_fe0f/512.webp" type="image/webp"/>
                                                                    <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/2764_fe0f/512.gif" alt="❤️" width="32" height="32"/>
                                                                </picture>
                                                                <picture className="react-item" onClick={(e)=>postReact('Haha',p)}>
                                                                    <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f606/512.webp" type="image/webp"/>
                                                                    <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f606/512.gif" alt="😆" width="32" height="32"/>
                                                                </picture>
                                                                <picture className="react-item" onClick={(e)=>postReact('Wow',p)}>
                                                                    <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f62f/512.webp" type="image/webp"/>
                                                                    <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f62f/512.gif" alt="😯" width="32" height="32"/>
                                                                </picture>
                                                                <picture className="react-item" onClick={(e)=>postReact('Sad',p)}> 
                                                                    <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f622/512.webp" type="image/webp"/>
                                                                    <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f622/512.gif" alt="😢" width="32" height="32"/>
                                                                </picture>  
                                                                <picture className="react-item" onClick={(e)=>postReact('Angry',p)}>
                                                                    <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f620/512.webp" type="image/webp"/>
                                                                    <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f620/512.gif" alt="😠" width="32" height="32"/>
                                                                </picture>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 text-center pointer post-action py-2" onClick={()=>setSelectedPost(p.key)}>
                                                            <p className="text-blue"><i className="fa fa-comment"/> Comment</p>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-12"/>
                                            </>
                                        )
                                    })}

                                    {limit < posts.length &&
                                    <div className="col-lg-5 col-12  text-center see-more-container pointer" onClick={()=>setLimit(limit+5)}>
                                        <p className="text-blue"><i className="fa fa-arrow-down"/> See More Posts</p>
                                    </div>
                                    }
                                    
                                    

                                </div>
                            </div>

                            

                        </div>

                    </div>

                </div>

            </div>
            
            <ModalConfirmation show={deletingPost} onHide={()=>setDeletingPost(null)} onConfirm={()=>deletePost()} title='<i class="fa fa-trash"/> <span class="bold">Delete Post?</span>' message='Deleting a post will also delete its comments and reactions. Are you sure you want to proceed?'/>

            <ModalViewReaction show={selectedReaction} onHide={()=>setSelectedReaction(null)} postKey={selectedReaction} level={props.level}/>

            <ModalViewImage show={selectedImage} onHide={()=>setSelectedImage(null)} image={selectedImage}/>
            
            <ModalViewPost show={selectedPost} onHide={()=>setSelectedPost(null)} postKey={selectedPost} level={props.level} uid={uid}/>

            <ModalCreatePost show={posting} onHide={()=>setPosting(false)} student={student} uid={uid} level={props.level}/>
            <ModalLoading show={processing}/>
            
        </>
    )
}