import Modal from 'react-bootstrap/Modal';

import $ from 'jquery'
import { useEffect, useState } from 'react';
import ModalLoading from '../general/ModalLoading';
import { getFileExt, hasLetter, toTitleCase } from '../../helper/formatter';

import {getDownloadURL, ref as sref, uploadBytes} from 'firebase/storage'
import { studentDB, studentStorage } from '../../api/firebase-student-config';
import { ref, set, update } from 'firebase/database';
import ModalCropper from '../general/ModalCropper';


export default function ModalUpdateInfo(props) {

  var [processing,setProcessing] = useState(false)
  var [changed,setChanged] = useState(false);

  const [rawImage,setRawImage] = useState()
  const [profilePictureURL,setProfilePictureURL] = useState()
  const [cropping,setCropping] = useState(false)

  var [firstName,setFirstName] = useState()
  var [lastName,setLastName] = useState()
  var [birthday,setBirthday] = useState()
  var [contactNumber,setContactNumber] = useState()

  useEffect(()=>{
    setDefault()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.student])

  function setDefault(){

    if(props.student){
      var profile = props.student.profile
      $('#txtFirstName').val(profile.name.firstName)
      $('#txtLastName').val(profile.name.lastName)
      $('#txtBirthday').val(profile.birthday)
      $('#txtContactNumber').val(profile.contactNumber)
      setChanged(false)

      setFirstName(toTitleCase(profile.name.firstName))
      setLastName(toTitleCase(profile.name.lastName))
      setBirthday(profile.birthday)
      setContactNumber(profile.contactNumber)

    }
    
  }

  function verifyFirstName(){

    var txtFirstName = $('#txtFirstName').val().trim();
    var errFirstName = $('#errFirstName');
    
    errFirstName.text('')
    setFirstName(null);
    setChanged(false)

    if(txtFirstName.trim().length === 0) errFirstName.text('Blank spaces are not allowed.');
    else if(txtFirstName.length <= 1) errFirstName.text('Atleast 2 Characters.');
    else setFirstName(toTitleCase(txtFirstName));

  }

  function verifyLastName(){

    var txtLastName = $('#txtLastName').val().trim();
    var errLastName = $('#errLastName');
      
    errLastName.text('')
    setLastName(null);
    setChanged(false)

    if(txtLastName.trim().length === 0) errLastName.text('Blank spaces are not allowed.');
    else if(txtLastName.length <= 1) errLastName.text('Atleast 2 Characters.');
    else setLastName(toTitleCase(txtLastName));

  }

  function verifyBirthday(){

    var txtBirthday = $('#txtBirthday').val();
    var errBirthday = $('#errBirthday');
    
    errBirthday.text('')
    setBirthday(null);
    setChanged(false)

    if(txtBirthday.trim().length === 0) errBirthday.text('Select a Date.');
    else setBirthday(txtBirthday);


  }

  function verifyContactNumber(){

      var txtContactNumber = $('#txtContactNumber').val().trim();
      var errContactNumber = $('#errContactNumber');
      
      errContactNumber.text('')
      setContactNumber(null);
      setChanged(false)

      if(txtContactNumber.trim().length === 0) errContactNumber.text('Blank spaces are not allowed.');
      else if(hasLetter(txtContactNumber)) errContactNumber.text('Letters are not allowed.');
      else if(txtContactNumber.length > 15) errContactNumber.text('Invalid Format.');
      else if(txtContactNumber.includes(' ')) errContactNumber.text('Spaces are not allowed')
      else setContactNumber(txtContactNumber);

  }

  function verifyProfilePicture(){

    var fileProfilePicture = $("#fileProfilePicture").prop('files')
    var txtErr = $("#errProfilePicture")

    setProfilePictureURL(null)
    txtErr.text('')

    if(fileProfilePicture.length === 0) txtErr.text('Please select a file.')
    else if(getFileExt(fileProfilePicture[0].name).toLowerCase() !== 'png' && getFileExt(fileProfilePicture[0].name).toLowerCase() !== 'jpg' && getFileExt(fileProfilePicture[0].name).toLowerCase() !== 'jpeg') txtErr.text('PNG and JPG Files are the only accepted files.')
    else{
        var _URL = window.URL || window.webkitURL;

        var objectURL = _URL.createObjectURL(fileProfilePicture[0])

        setRawImage(objectURL)  
        setCropping(true)

    }
    
  }

  function cancelCrop(){
    $("#fileProfilePicture").val(null)
    setCropping(false)
    setRawImage(null)
  }


  function uploadProfilePicture(picture){

    setProcessing(true)
    
    fetch(picture).then(r => r.blob()).then(blobFile => {

      var file = new File([blobFile], 'Hi', { type: blobFile.type })

      uploadBytes(sref(studentStorage,'students/profilePicture/' + props.student.uid), file).then((res)=>{

        getDownloadURL(sref(studentStorage,'students/profilePicture/' + props.student.uid)).then((url)=>{
  
          set(ref(studentDB,'students/'+props.student.uid+'/profile/profilePictureURL'),url).then(()=>{
            
            setCropping(false)
            setProcessing(false)
  
          }).catch((error)=>{
            alert(error.message)
          })
  
        }).catch((error)=>{
          alert(error.message)
        })
  
      }).catch((error)=>{
        alert(error.message)
      })
      
    })

  }


  function saveChanges(){

    const body = {
        profile:{
            name:{
                firstName:firstName,
                lastName:lastName
            },
            birthday:birthday,
            contactNumber:contactNumber,
            enrollment:props.student.profile.enrollment,
            emailAddress:props.student.profile.emailAddress,
            profilePictureURL:props.student.profile.profilePictureURL
        }
    }

    setProcessing(true)

    update(ref(studentDB,'students/' + props.student.uid),body).then(()=>{

      setProcessing(false)
      setChanged(true)

    })

  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
        onShow={()=>setDefault()}
      >
        
        <Modal.Header className='bg-blue' closeButton>
        
          <h4 className='saira text-center text-white m-0'><i className="fa-solid fa-edit"></i> Update Information</h4>

        </Modal.Header>

        <Modal.Body className='bg-white-blue'>

          <div className='row justify-content-center py-3'>

            
            <div className='col-10 animate__animated animate__fadeIn'>
              
              <div className='row justify-content-center'>

                <div className='col-12 text-center mb-2'>

                  {props.student &&
                  <img src={profilePictureURL?profilePictureURL:props.student.profile.profilePictureURL} width='150px' alt='profile' style={{borderRadius:'50%'}}/>
                  }

                  <input id="fileProfilePicture" onChange={()=>verifyProfilePicture()} type="file" className="form-control" style={{display:'none'}} accept='image/*'/>
                  <small id="errProfilePicture" className="form-text text-danger text-center mb-2" style={{display:'block'}}></small>

                  <div className='row justify-content-center mt-3'>
                    <button className='form-control btn-blue px-5 py-2' style={{width:'fit-content'}} onClick={()=>{
                      $('#fileProfilePicture').trigger('click')
                    }}>
                      <i className='fa fa-upload'/> Upload Image
                    </button>
                  </div>

                  <small id="helpProfilePicture" className="form-text text-muted">Only 1:1 Pictures are allowed.</small>
                </div>

                <div className='col-12'>
                  <hr className='blue-hr my-2' style={{opacity:'0.5'}}/>
                </div>

                <div className='col-lg-6'>
                  <p className='bold text-blue mt-3'>First Name</p>
                  <input onChange={()=>verifyFirstName()} id="txtFirstName" className='form-control' type="text"/>
                  <small id="errFirstName" className="form-text text-danger text-center mb-3 mt-2"></small>
                </div>

                <div className='col-lg-6'>
                  <p className='bold text-blue mt-3'>Last Name</p>
                  <input onChange={()=>verifyLastName()} id="txtLastName" className='form-control' type="text"/>
                  <small id="errLastName" className="form-text text-danger text-center mb-3 mt-2"></small>
                </div>

                <div className='col-lg-12 mt-2'>
                  <p className='bold text-blue mt-3'>Birthday</p>
                  <input onChange={()=>verifyBirthday()} id="txtBirthday" className='form-control' type="date"/>
                  <small id="errBirthday" className="form-text text-danger text-center mb-3 mt-2"></small>
                </div>

                <div className='col-12'>   
                  <p className='bold text-blue mt-3'>Contact Number</p>
                  <div className="input-group">
                      <div className="input-group-prepend">
                          <span className="input-group-text"><span className='text-blue bold fa fa-phone'/></span>
                      </div>
                      <input id="txtContactNumber" onChange={()=>verifyContactNumber()} maxLength={15} type="text" className="form-control"/>
                  </div>
                  <small id="errContactNumber" className="form-text text-danger"></small>
              </div>

              </div>
              

              {firstName && lastName && contactNumber && birthday?
              <button onClick={()=>saveChanges()} className='form-control btn-blue py-2 mt-4'><i className="fa-solid fa-save me-1"></i> Save Changes</button>
              :
              <button className='form-control btn-blue py-2 mt-4' disabled><i className="fa-solid fa-save me-1"></i> Save Changes</button>
              }
              {changed && <p className='text-success mt-2 ms-4'><i className='fa fa-check me-2'/> Saved Changes</p>}
            
            </div>
            
            

          </div>

        </Modal.Body>

        <Modal.Footer className='bg-blue'>
        </Modal.Footer>

      </Modal>

      <ModalCropper show={cropping} image={rawImage} onHide={()=>cancelCrop()} onCrop={(img)=>uploadProfilePicture(img)}/>   
      <ModalLoading show={processing} />

    </>
    
  );
}