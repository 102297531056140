import Modal from 'react-bootstrap/Modal';

import $ from 'jquery'
import { useState } from 'react';
import {hasCorrectEmail} from '../../helper/formatter'
import ModalLoading from './ModalLoading';
import blueFullLogo from '../../img/logo/blue-full-logo.png';
import { sendPasswordResetEmail } from 'firebase/auth';
import { studentAuth } from '../../api/firebase-student-config';

export default function ModalForgotPassword(props) {

  var [processing,setProcessing] = useState(false)
  var [emailAddress,setEmailAddress] = useState()
  var [sent,setSent] = useState(false);

  function sendRetrievalEmail(){

    setProcessing(true)

    var err = $('#errForgotEmailAddress');

    sendPasswordResetEmail(studentAuth,emailAddress).then(()=>{
      err.text('')
      setProcessing(false)
      setSent(true)
    }).catch((error)=>{
      setProcessing(false)

      if(error.code === 'auth/user-not-found') err.text('Account does not exist.')
      else err.text(error.message)

    })

  }

  function verifyEmailAddress(){

    var txtForgotEmailAddress = $('#txtForgotEmailAddress').val();
    var errForgotEmailAddress = $('#errForgotEmailAddress');
    
    errForgotEmailAddress.text('')
    setEmailAddress(null);

    if(txtForgotEmailAddress.trim().length === 0) errForgotEmailAddress.text('Blank spaces are not allowed.');
    else if(!hasCorrectEmail(txtForgotEmailAddress)) errForgotEmailAddress.text('Invalid Email Format.');
    else setEmailAddress(txtForgotEmailAddress);

}

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
      >
        
        <Modal.Header className='bg-blue' closeButton>
        
          <h4 className='saira text-center text-white m-0'>Forgot Password <i class="fa-solid fa-question"></i></h4>

        </Modal.Header>

        <Modal.Body className='bg-white-blue'>

          <div className='row justify-content-center py-3'>

            {sent?
            <div className='col-10 animate__animated animate__fadeIn'>

              <div className='row mb-2 justify-content-center'>
                <div className='col-12 text-center'>
                  <img src={blueFullLogo} alt='logo' width="50%" />
                </div>
              </div>

              <p className='text-center'><i className='fa fa-check text-blue' style={{fontSize:"72px",marginBottom:"-50px"}}/></p>
              <p className='text-center text-blue'>Password Reset Email was sent to <b className='text-light-blue'>({emailAddress})</b></p>
            
            </div>
            :
            <div className='col-10 animate__animated animate__fadeIn'>

              <div className='row mb-4 justify-content-center'>
                <div className='col-12 text-center'>
                  <img src={blueFullLogo} alt='logo' width="50%" />
                </div>
              </div>
              

              <p className='bold text-blue mt-3'>Email Address</p>
              <input name='email' onChange={()=>verifyEmailAddress()} id="txtForgotEmailAddress" className='form-control' type="text"/>
              <small id="errForgotEmailAddress" class="form-text text-danger text-center mb-3 mt-2"></small>

              <p className='mt-2' style={{lineHeight:"1.25"}}>
                <small className='text-muted'>*Make sure you type in your correct email address or you won't receive the account retrieval email.</small>
              </p>

              {emailAddress?
              <button onClick={()=>sendRetrievalEmail()} className='form-control btn-blue py-2 mt-4'><i class="fa-solid fa-send me-1"></i> Send Account Retrieval</button>
              :
              <button className='form-control btn-blue py-2 mt-4' disabled><i class="fa-solid fa-send me-1"></i> Send Account Retrieval</button>
              }
            
            </div>
            }
            

          </div>

        </Modal.Body>

        <Modal.Footer className='bg-blue'>
        </Modal.Footer>

      </Modal>

      <ModalLoading show={processing} />

    </>
  );
}