import formBG from '../../img/bg/form-bg.png';
import wordLogo from '../../img/logo/word-logo.png';
import whiteLogo from '../../img/logo/white-logo.png';
import blueFullLogo from '../../img/logo/blue-full-logo.png';
import { Footer } from '../../components/landing/Footer';

import {signInWithEmailAndPassword} from 'firebase/auth'
import {studentAuth, studentDB} from '../../api/firebase-student-config'
import { Helmet } from 'react-helmet-async';
import $ from 'jquery';

import ModalLoading from '../../components/general/ModalLoading';
import { useState } from 'react';
import ModalForgotPassword from '../../components/general/ModalForgotPassword';
import { ref, set } from 'firebase/database';
import moment from 'moment';

export const Login = () =>{

    const [processing,setProcessing] = useState(false);
    const [forgotPassword,setForgotPassword] = useState(false);

    function signIn(){

        var txtEmailAddress = $('#txtEmailAddress').val().trim();
        var txtPassword = $('#txtPassword').val();
        
        setProcessing(true);

        signInWithEmailAndPassword(studentAuth,txtEmailAddress,txtPassword).then((student)=>{
            
            if(student){
                window.location.href = '/';
            }

        }).catch((error)=>{

            setProcessing(false);
            $('#errGeneral').text(error.message);

            if(error.code === 'auth/invalid-email') $('#errGeneral').text('Invalid Email');
            else if(error.code === 'auth/wrong-password') $('#errGeneral').text('Wrong Password');
            else if(error.code === 'auth/user-not-found') $('#errGeneral').text('User does not exist.');

        })

    }

    return(
        <>
            <Helmet>
                <title>Trading Circle - Login</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn credential-container" style={{marginTop:'-15px'}}>

                <div className="row h-100 justify-content-center mx-0">

                    <div className="col-lg-6 col-11 mb-lg-0 creds-input-container">

                        <div className='row h-100 justify-content-center m-0 p-0'>

                            <div className='col-lg-8 text-center align-self-center animate__animated animate__fadeInUp creds-form-container'>

                                <img className='wow fadeIn ps-5 pe-5 pt-lg-5' src={blueFullLogo} alt="blue logo" width="100%"/>
                                <p className='saira m-0'>Infinite Trading Experience</p>

                                <hr className='blue-hr mb-4'/>

                                <div className='row pe-4 ps-4 pb-lg-5 justify-content-center'>

                                    <div className='col-12 mb-3'>
                                        <h1 className='saira bold text-blue m-0'>Welcome Back!</h1>
                                        <p className='saira'>Let's continue learning together.</p>
                                    </div>

                                    <div className='col-12 text-start mb-3'>
                                        
                                        <label className='saira bold text-blue mb-1' for="txtEmailAddress">Email Address</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i className='fa fa-user'/></span>
                                            </div>
                                            <input id="txtEmailAddress" type="text" class="form-control" placeholder="Enter Email"/>
                                        </div>
                                        <small id="errEmailAddress" class="form-text text-danger text-center mb-3 mt-2"></small>

                                    </div>

                                    <div className='col-12 text-start mb-3'>
                                        
                                        <label className='saira bold text-blue mb-1' for="txtPassword">Password</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i className='fa fa-lock'/></span>
                                            </div>
                                            <input id="txtPassword" type="password" class="form-control" placeholder="Enter Password"/>
                                        </div>
                                        <small id="errPassword" class="form-text text-danger text-center mb-3 mt-2"></small>

                                    </div>

                                    <div className='col-12 mb-3'>
                                        <p className='saira text-dark-blue medium pointer' onClick={()=>setForgotPassword(true)}>Forgot Password?</p>
                                    </div>

                                    <div className='col-12'>
                                        <button id='btnLogin' onClick={()=>signIn()} className='form-control saira'>Login</button>
                                        <small id="errGeneral" class="form-text text-danger text-center mb-3 mt-4"></small>
                                    </div>

                                    <div className='col-12 mt-3'>
                                        <p className='saira text-dark-blue medium'>Don't have an account? <a className='bold pointer text-dark-blue' href='register'>Sign up now.</a></p>
                                    </div>
                                    
                                    <div className='col-8 mt-4'>
                                        <hr className='blue-hr'/>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="col-lg-6 d-lg-block d-none" style={{background:`url(${formBG})`,backgroundSize:"cover", backgroundPosition:"center", borderRadius:"2vw 0 0 2vw"}}>

                        <div className="row h-100 justify-content-center" style={{background:"rgba(0,0,0,0.5)",borderRadius:"inherit"}}>
                            
                            <div className='col-12 align-self-center text-center animate__animated animate__fadeInDown'>

                                <img className='wow fadeIn creds-white-logo' src={whiteLogo} alt="white logo"/><br/>
                                <img className='wow fadeIn creds-word-logo' src={wordLogo} alt="word logo"/>
                                <h1 className='text-white mt-3 mb-0 wow fadeIn'>Welcome Students</h1>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
            
            <Footer/>
            <ModalLoading show={processing} />
            <ModalForgotPassword show={forgotPassword} onHide={()=>setForgotPassword(false)}/>
        </>
    );

}