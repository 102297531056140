
import { Helmet } from 'react-helmet-async';
import blackWordLogo from '../../img/logo/black-word-logo.png';
import { FunnelFooter } from '../../components/landing/FunnelFooter';

import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment'
import $ from 'jquery'
import {set,ref,push} from 'firebase/database'
import { funnelDB } from '../../api/firebase-funnel-config';

export const FTPHInvitation = () =>{

    const [pageID,setPageID] = useState()

    useEffect(()=>{

        if(pageID){
            var now = moment().format('MMMM DD YYYY')

            if(pageID){

                $.getJSON("https://api64.ipify.org?format=json", function(e){

                    var cleanedIP = String(e.ip).split('.').join('-')

                    push(ref(funnelDB,`analytics/impressions/${pageID}/${now}`),e.ip).then(()=>{

                        set(ref(funnelDB,`analytics/unique/${pageID}/${now}/${cleanedIP}`),'VISITED').then(()=>{

                        })

                    })

                });

            }
        }else setPageID('ftph-invitation')

    },[pageID])

    function goToGroup(){
        window.open("https://www.facebook.com/groups/tradingcirclephilippines");
    }

    return(
        <>
            <Helmet>
                <title>Trading Circle - FTPH Invitation</title>
                <script>

                </script>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn py-4">

                <div className='row justify-content-center mt-lg-3 mt-0'>

                    <div className='col-12 text-center mb-4'>
                        <img className='d-none d-lg-inline darkmode-logo' src={blackWordLogo} width='20%' alt='logo'/>
                        <img className='d-inline d-lg-none darkmode-logo' src={blackWordLogo} width='70%' alt='logo'/>
                        <div className='row justify-content-center mb-3'>
                            <div className='col-lg-6 col-12'>
                                <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                            </div>
                        </div>
                        <h1 className='bold mb-2 text-blue black'>Forex Trading Philippines Invitation!</h1>
                        <h4 className='bold' style={{fontSize:'calc(1rem + 0.5vw)'}}>You are invited to join our public group</h4>
                    </div>

                    <div className='col-lg-7 col-12' data-aos="fade-up">

                        <div className='video-container' style={{padding:"56.25% 0 0 0",position:"relative"}}><iframe src="https://player.vimeo.com/video/851530288?h=ebf69204a0&badge=0&autopause=0&player_id=0&app_id=58479/embed" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} title="Website Walkthrough"></iframe></div>
                        <script src="https://player.vimeo.com/api/player.js"></script>

                    </div>

                    <div className='col-12 text-blue'>
                        
                        <div className='row text-center mt-3 mb-4 justify-content-center' style={{rowGap:"25px"}}>

                            <div className='col-lg-7 col-10 my-2' data-aos="fade-up">
                                <h3 className='black'><i className='fa fa-hand'/> CLICK THE BUTTON BELOW</h3>
                                <p style={{fontSize:'calc(0.7rem + 0.7vw)'}}>To Join Forex Trading Philippines</p>
                            </div>

                            <div className='col-9 d-lg-block d-none' data-aos="fade-up">
                                <button onClick={()=>goToGroup()} className='form-control btn-solid bold py-4 px-5 w-100 animate__animated animate__pulse animate__infinite animate__slow btn-funnel' style={{width:"fit-content",fontSize:'28px'}}><i class="fa-solid fa-users"></i> Join Group!</button>
                            </div>

                            <div className='col-11 d-lg-none d-block'>
                                <button onClick={()=>goToGroup()} style={{fontSize:'calc(0.75rem + 1 vw)'}} className='form-control btn-solid bold py-3 px-5 w-100 animate__animated animate__pulse animate__infinite animate__slow btn-funnel'><i class="fa-solid fa-users"></i> Join Group!</button>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
            
            <FunnelFooter/>
        </>
    );

}