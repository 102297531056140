
import { Helmet } from 'react-helmet-async';

import ModalLoading from '../../components/general/ModalLoading';
import { useEffect, useState } from 'react';

import {Header} from '../../components/general/Header'
import { onValue, ref } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import { studentAuth, studentDB } from '../../api/firebase-student-config';

import standardFacebookGroup from '../../img/community/standard-facebook-group.png'
import eliteFacebookGroup from '../../img/community/elite-facebook-group.png'
import instagram from '../../img/community/instagram.png'
import facebookPage from '../../img/community/facebook-page.png'
import tikTok from '../../img/community/tiktok.png'
import youTube from '../../img/community/youtube.png'

import { Footer } from '../../components/academy/Footer';

export const Community = () =>{

    const [processing,setProcessing] = useState(false);

    //var [uid,setUID] = useState(null);
    var [student,setStudent] = useState(null);

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
      
            if(student){
        
              onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
                if(snapshot.exists()){
                    //setUID(student.uid);
                    setStudent(snapshot.val());
                    setProcessing(false);
                }
              })
        
            }else setProcessing(false);
        
          })

    },[])

    return(
        <>
            <Helmet>
                <title>Trading Circle - Community</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn p-4">

                <Header title='Community'/>

                <div className='row justify-content-center mb-5 mt-4 text-blue'>

                    <div className='col-10'>
                        <div className='row d-flex justify-content-center' style={{gap:'40px 100px'}}>

                            <div className='col-lg-3 community-container' style={{minWidth:"300px"}}>
                                <img src={standardFacebookGroup} alt='social' width='100%'/>
                                <div className='row mt-2'>
                                    <div className='col-lg-12'>
                                        <h5><i className='fa-brands fa-facebook'/> Facebook Group</h5>
                                        <p className='text-justify' style={{fontSize:"14px"}}>Join our private Facebook Group where you can post and share your analysis.</p>
                                    </div>
                                    <div className='col-lg-12 mt-3'>
                                        <a href='https://www.facebook.com/groups/tradingcirclestandard/' rel='noreferrer' target="_blank"><button className='form-control btn-solid'><i className="fa-solid fa-user-group"/> Join Group</button></a>
                                    </div>
                                </div>
                            </div>

                            {student && student.level === 'Elite' &&
                            <div className='col-lg-3 community-container' style={{minWidth:"300px"}}>
                                <img src={eliteFacebookGroup} alt='social' width='100%'/>
                                <div className='row mt-2'>
                                    <div className='col-lg-12'>
                                        <h5><i className='fa-brands fa-facebook'/> Facebook Elite Group</h5>
                                        <p className='text-justify' style={{fontSize:"14px"}}>Join our private Facebook Elite Group where you can post and share your analysis.</p>
                                    </div>
                                    <div className='col-lg-12 mt-3'>
                                        <a href='https://www.facebook.com/groups/tradingcircleelite/' rel='noreferrer' target="_blank"><button className='form-control btn-solid'><i className="fa-solid fa-user-group"/> Join Group</button></a>
                                    </div>
                                </div>
                            </div>
                            }

                            <div className='col-lg-3 community-container' style={{minWidth:"300px"}}>
                                <img src={tikTok} alt='social' width='100%'/>
                                <div className='row mt-2'>
                                    <div className='col-lg-12'>
                                        <h5><i className='fa-brands fa-tiktok'/> TikTok</h5>
                                        <p className='text-justify' style={{fontSize:"14px"}}>Follow our Tiktok Page for more tips and valuable short content regarding trading and investment.</p>
                                    </div>
                                    <div className='col-lg-12 mt-3'>
                                        <a href='https://www.tiktok.com/@tradingcircle.com.ph' rel='noreferrer' target="_blank"><button className='form-control btn-solid'><i className="fa-solid fa-user-plus"/> Follow</button></a>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-3 community-container' style={{minWidth:"300px"}}>
                                <img src={youTube} alt='social' width='100%'/>
                                <div className='row mt-2'>
                                    <div className='col-lg-12'>
                                        <h5><i className='fa-brands fa-youtube'/> YouTube</h5>
                                        <p className='text-justify' style={{fontSize:"14px"}}>Subscribe to our YouTube Channel to receive free contents about trading.</p>
                                    </div>
                                    <div className='col-lg-12 mt-3'>
                                        <a href='https://www.youtube.com/c/TradingCircle/videos' rel='noreferrer' target="_blank"><button className='form-control btn-solid'><i className='fa-brands fa-youtube'/> Subscribe</button></a>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-3 community-container' style={{minWidth:"300px"}}>
                                <img src={facebookPage} alt='social' width='100%'/>
                                <div className='row mt-2'>
                                    <div className='col-lg-12'>
                                        <h5><i className='fa-brands fa-facebook'/> Facebook Page</h5>
                                        <p className='text-justify' style={{fontSize:"14px"}}>Like and Follow our Facebook Page to get notifications about updates and tips from the academy.</p>
                                    </div>
                                    <div className='col-lg-12 mt-3'>
                                        <a href='https://www.facebook.com/alexbalingitofficial' rel='noreferrer' target="_blank"><button className='form-control btn-solid'><i className='fa fa-thumbs-up'/> Like Page</button></a>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-3 community-container' style={{minWidth:"300px"}}>
                                <img src={instagram} alt='social' width='100%'/>
                                <div className='row mt-2'>
                                    <div className='col-lg-12'>
                                        <h5><i className='fa-brands fa-instagram'/> Instagram</h5>
                                        <p className='text-justify' style={{fontSize:"14px"}}>Follow our Instagram Page to receive valuable content including short blogs, tips, trading psychology, and more.</p>
                                    </div>
                                    <div className='col-lg-12 mt-3'>
                                        <a href='https://www.instagram.com/tradingcircle.com.ph/' rel='noreferrer' target="_blank"><button className='form-control btn-solid'><i className="fa-solid fa-user-plus"/> Follow</button></a>
                                    </div>
                                </div>
                            </div>
                            

                        </div>
                    </div>

                </div>

            </div>
            
            <ModalLoading show={processing} />
            <Footer/>
        </>
    );

}