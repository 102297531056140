/* eslint-disable jsx-a11y/anchor-has-content */
import whiteFullLogo from '../../img/logo/white-full-logo.png';

export const Footer = () =>{

    return(

        <footer className="container-fluid">

            <div className="row justify-content-center" style={{rowGap:'30px'}}>

                <div className="col-lg-3 px-lg-5 px-0 mb-lg-0 mb-5 footer-item">
                    <img src={whiteFullLogo} alt="white logo" width="70%" style={{maxWidth:'300px'}}/>
                    <p className='text-white text-justify thin' style={{opacity:"1",lineHeight:"1.75"}}>Trading Circle is the first community-building group for traders that emphasizes interaction and learning in one platform. This community is designed for humble beginners who want to achieve financial freedom through Forex Market.</p>

                    <div className='footer-social-media text-white d-flex mt-4' style={{fontSize:"28px",gap:"20px"}}>
                        
                        <a href='https://www.instagram.com/tradingcircle.com.ph' rel="noreferrer" target="_blank" className='fa-brands fa-instagram text-white'/>
                        <a href='https://www.facebook.com/alexbalingitofficial' rel="noreferrer" target="_blank" className='fa-brands fa-facebook text-white'/>
                        <a href='https://www.youtube.com/c/TradingCircle' rel="noreferrer" target="_blank" className='fa-brands fa-youtube text-white'/>
                        <a href='https://www.tiktok.com/@tradingcircle.com.ph' rel="noreferrer" target="_blank" className='fa-brands fa-tiktok text-white'/>

                    </div>
                
                </div>

                <div className="col-lg-3 px-lg-5 px-0 mb-lg-0 mb-5 footer-item">
                    <h4 className='saira bold text-white'>Contact</h4>
                    <p className='text-white text-justify thin' style={{opacity:"1",lineHeight:"1.75"}}><i className='fa fa-envelope me-2'/> alexbalingit@tradingcircle.com.ph</p>
                </div>

                <div className="col-lg-3 px-lg-5 px-0 mb-lg-0 mb-5 footer-item-small">
                    <h4 className='saira bold text-white'>Helpful Links</h4>
                    <p className='text-white text-justify thin mb-2 helpful-link' style={{opacity:"1",lineHeight:"1.75"}}><i className='fa fa-long-arrow-right me-2'/> <a className='text-white' href="/register">Register</a></p>
                    <p className='text-white text-justify thin mb-2 helpful-link' style={{opacity:"1",lineHeight:"1.75"}}><i className='fa fa-long-arrow-right me-2'/> <a className='text-white' href="/support">Contact Us</a></p>
                    <p className='text-white text-justify thin mb-2 helpful-link' style={{opacity:"1",lineHeight:"1.75"}}><i className='fa fa-long-arrow-right me-2'/> <a className='text-white' href="/policies/terms-and-conditions">Terms & Conditions</a></p>
                    <p className='text-white text-justify thin mb-2 helpful-link' style={{opacity:"1",lineHeight:"1.75"}}><i className='fa fa-long-arrow-right me-2'/> <a className='text-white' href="/policies/privacy-policy">Privacy Policy</a></p>
                    <p className='text-white text-justify thin mb-2 helpful-link' style={{opacity:"1",lineHeight:"1.75"}}><i className='fa fa-long-arrow-right me-2'/> <a className='text-white' href="/policies/video-copyright">Video Copyright</a></p>
                    <p className='text-white text-justify thin mb-2 helpful-link' style={{opacity:"1",lineHeight:"1.75"}}><i className='fa fa-long-arrow-right me-2'/> <a className='text-white' href="/policies/refund-policy">Refund Policy</a></p>
                    <p className='text-white text-justify thin mb-2 helpful-link' style={{opacity:"1",lineHeight:"1.75"}}><i className='fa fa-long-arrow-right me-2'/> <a className='text-white' href="/policies/legal-documents">Legal Documents</a></p>
                </div>

                {/*
                <div className="col-lg-3 px-lg-5 px-0 mb-lg-0 mb-5">
                    
                    <h4 className='saira bold text-white'>Register in Free Training</h4>
                    <p className='text-white text-justify thin' style={{opacity:"1",lineHeight:"1.75"}}>Don’t miss out on this offer and save your slot in the academy now.</p>
                    
                    <a href='/free-training' target="_blank"><button className='form-control saira mt-3 background-primary pb-2 pt-2'>Save My Slot</button></a>

                </div>*/}

            </div>

            <hr className='footer-hr mb-0'/>

            <div className="row p-lg-4 p-0 pb-4 pt-4">
                <div className='col-lg-6 text-lg-start text-center'>
                    <p className='text-white'>&copy; Trading Circle 2023, <span className='text-muted'>All Rights Reserved</span></p>
                </div>
                <div className='col-lg-6 text-lg-end text-center mt-lg-0 mt-1'>
                    <p className='text-white'><span className='text-muted'>Powered By </span> SDPT Solutions</p>
                </div>
            </div>

        </footer>

    );

}