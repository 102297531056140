import ModalLoading from '../../components/general/ModalLoading';
import { useEffect, useState } from 'react';
import { onValue, ref, set } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import { studentAuth, studentDB } from '../../api/firebase-student-config';
import demoChallenge from '../../img/demo-challenge/monthly-demo.png'
import demoChallengePrize from '../../img/demo-challenge/demo-challenge-prize.png'
import { adminDB } from '../../api/firebase-admin-config';
import ModalConfirmation from '../../components/general/ModalConfirmation';
import moment from "moment"

import ModalDemoAcount from '../../components/academy/ModalDemoAccount';
import ModalClaimPrize from '../../components/academy/ModalClaimPrize';
import axios from 'axios'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export const MonthlyDemoChallenge = ()=>{

    const [processing,setProcessing] = useState(false);

    var [uid,setUID] = useState(null);
    var [student,setStudent] = useState(null);

    var [challengeStatus,setChallengeStatus] = useState();
    var [joining,setJoining] = useState(false)
    var [joined,setJoined] = useState(false);
    var [demoAccount,setDemoAccount] = useState()
    var [checkingAccount,setCheckingAccount] = useState(false)

    var [studentIDs,setStudentIDs] = useState({})
    var [students,setStudents] = useState([])

    var [claimingPrize,setClaimingPrize] = useState(false)
    var [claimData,setClaimData] = useState(false)
    var [winStatus,setWinStatus] = useState('N/A')
    var [winData,setWinData] = useState()

    useEffect(()=>{

        if(uid){
            onValue(ref(adminDB,`hallOfFame`),(snapshot)=>{

                if(snapshot.exists()){

                    for(var key in snapshot.val()){
                        
                        if(snapshot.val()[key][uid] != null){

                            setWinData(key)
              
                            if(student['challenge']){
                                setWinStatus(student['challenge']['status'])
                                setClaimData(student['challenge'])
                            }
                            else setWinStatus('WIN')

                        }

                    }

                }

            })
        }

    },[uid,student])

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
      
            if(student){
        
              onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
                if(snapshot.exists()){
                    setUID(student.uid);
                    setStudent(snapshot.val());
                    setProcessing(false);
                }
              })
        
            }else setProcessing(false);
        
          })

        onValue(ref(adminDB,"demoChallenge"),(snapshot)=>{
            if(snapshot.exists()){

                var cInfo = snapshot.val()

                if(cInfo.status) setChallengeStatus(cInfo.status)

                if(cInfo.participants && cInfo.participants[uid]) setJoined(true)
                else setJoined(false)

                
            }else{
                setChallengeStatus('closed')
                setJoined(false)
            }   
        })

    },[challengeStatus,joined,uid])

    useEffect(()=>{

        if(joined && uid){

            onValue(ref(studentDB,`/demoChallengeAccount/${uid}`),(snapshot)=>{
                if(snapshot.exists()){
                    setDemoAccount(snapshot.val())
                }else setDemoAccount(null)
            });

        }

    },[joined,uid])

    useEffect(()=>{

        onValue(ref(adminDB,'/hallOfFame'),(snapshot)=>{

            if(snapshot.exists()){

                var dates = snapshot.val() 
                
                var studs = '{'

                for(var key in dates){

                    if(moment(key).format('MMMM YYYY') === moment().add(-1,'months').format('MMMM YYYY') || moment(key).format('MMMM YYYY') === moment().format('MMMM YYYY')){

                        for(var id in dates[key]){
                            studs += `"${id}":"${dates[key][id]}",`
                        }

                    }

                }

                studs = studs.substring(0,studs.length-1)
                studs += '}'

                console.log(studs)

                setStudentIDs(JSON.parse(studs))

            }
        })

    },[])

    useEffect(()=>{
        
        onValue(ref(studentDB,'/students'),(snapshot)=>{
            
            if(snapshot.exists()){

                var studentsInfo = snapshot.val()
                var winningStudents = []

                for(var key in studentIDs){
                    if(studentsInfo[key]){
                        studentsInfo[key].winningDate = studentIDs[key]
                        winningStudents.push(studentsInfo[key])                
                    }
                }

                winningStudents.reverse()
                console.log(winningStudents)
                setStudents(winningStudents)
                
            }

        })

    },[studentIDs])


    function joinChallenge(){

        setProcessing(true)
        var d = moment().format()

        set(ref(adminDB,'demoChallenge/participants/'+uid),d).then(()=>{
            
            sendJoinConfirmation()
    
        }).catch((error)=>{
            setProcessing(false)
            setJoining(false)
            alert(error)
        })

    }

    function sendJoinConfirmation(){
        setProcessing(true)
        axios.post('https://us-central1-trading-circle-ph-admin.cloudfunctions.net/app/api/email/support',{
        subject:`Registered Successfully - ${moment().format('MMMM YYYY')} Demo Challenge`,
        email:student.profile.emailAddress,
        html:`        
        <div style='display:table;margin:0 auto;'>
        <div style='max-width:500px;margin:0 auto;'>
            
            <p style='text-align:center;'>
            <img src='https://tradingcircle-ph.web.app/static/media/black-word-logo.1bf10b226314dd33a7ab.png' width='350px'/>
            </p>

            <hr/>

            <h2 style='white-space:pre;color:#01245A;margin-bottom:0;'>Registered Successfully - ${moment().format('MMMM YYYY')} Demo Challenge</h2>
            <p style='color:#01245A;margin-top:0;margin-bottom:0;'>Hi ${student.profile.name.firstName}, We would like to inform you that you are now successfully part of this month's Demo Challenge. Please wait for your demo account credentials on our website, we will also notify you via email when the credentials are released.</p>
            <br/>

            <p style='text-align:center;'>
                <img style="border-radius:25px" src='https://firebasestorage.googleapis.com/v0/b/trading-circle-ph-admin.appspot.com/o/email%2Fdemo-challenge-register.png?alt=media&token=9d2b6d4e-a0fb-4cca-b7bf-55128166dbf3' width='100%'/>
            </p>

            <p style='color:#01245A;margin-top:0;margin-bottom:0;'>You will be receiving an Account ID and a password in our Demo Challenge tab found on the link below: </p>
            <p style='color:#01245A;margin-top:0;margin-bottom:0;'><a href='https://www.tradingcircle.com.ph/demo-challenge' target='_blank'>www.tradingcircle.com.ph/demo-challenge</a>.</p>
            
            <br/>
            
            <p style='color:#01245A;margin-top:0;margin-bottom:0;'>Make sure you are logged in with your correct account specifically the email we used to send this message.</p>

        </div>
        </div>
        `
    }).then((res)=>{

        if(res.status === 200){

            setProcessing(false)
            setJoining(false)

        }else{
            alert(res.text)
            setProcessing(false)
        }

    })

    }

    return(
    <>
        <div className='col-lg-10'>

            <div className='row justify-content-center'>

                <div className='col-lg-6 text-center align-self-center'>
                    <img src={demoChallenge} alt='demo icon' width="95%" style={{maxWidth:'600px',borderRadius:'25px'}}/>
                </div>
                <hr className='mt-4 d-lg-none d-block' style={{maxWidth:'500px'}}/>
                <div className='col-lg-6 px-lg-5 py-lg-3 px-4 py-3' style={{maxWidth:'600px'}}>
                    <p className='saira text-justify mb-3 text-muted' style={{fontSize:"18px"}}>Please wait for the announcement of the registration of the demo challenge. Usually, the registration window starts at the beginning of the month.</p>
                    
                    <div className='row justify-content-center'>
                        <img src={demoChallengePrize} alt='demo icon' width="100%" style={{maxWidth:'500px'}}/>
                    </div>

                    <h3 className='saira text-center text-blue bold mt-2'>Prizes for Monthly Challenge Passers</h3>
                    {winData && <p className='saira text-center text-success'> <i className='fa fa-trophy'/> You passed on <span className='bold'>{moment(winData).format('MMMM YYYY')}</span></p>}
                    
                    {winStatus === 'N/A'?
                    <></>
                    :
                    winStatus === 'WIN'?
                    <button className='btn-solid w-100 py-2 mt-3' style={{width:'fit-content'}} onClick={()=>setClaimingPrize(true)}><i className='fa fa-check'/> Claim Prize</button>
                    :
                    winStatus === 'SHIPPED'?
                    <button className='btn-solid w-100 py-2 mt-3' style={{width:'fit-content'}} disabled><i className='fa fa-box'/> Shipped</button>
                    :
                    winStatus === 'PENDING'?
                    <button className='btn-solid w-100 py-2 mt-3' style={{width:'fit-content'}} onClick={()=>setClaimingPrize(true)}><i className='fa fa-edit'/> Edit Details</button>
                    :
                    <button className='btn-solid w-100 py-2 mt-3' style={{width:'fit-content'}} disabled><i className='fa fa-check'/> Claimed</button>
                    }

                    {claimData && 
                    <>
                        <h5 className='text-blue bold mb-0 mt-3'>Status</h5>
                        <p className={`small saira px-2 mt-1 bold ${claimData.status ==='PENDING'?'text-pending':'text-success'}`}>{claimData.status}</p>
                        
                        <h5 className='text-blue bold mb-0 mt-3'>T-Shirt Size</h5>
                        <p className={`small saira px-2 mt-1 text-blue`}>{claimData.size}</p>

                        <h5 className='text-blue bold mb-0 mt-3'>Current Address</h5>
                        <p className='text-blue small saira px-2 mt-1'>{claimData.address}</p>
                    </>
                    }

                </div>

                {students.length > 0 &&
                <>
                
                    <h3 className='text-center bold saira text-blue mt-5'><i className='fa fa-trophy'/> Monthly Winners</h3>

                    <div className='row justify-content-center mb-5 mt-4'>
                        <div className='col-12 d-none d-lg-block'>
                            
                            {students.length > 3?
                            <OwlCarousel
                            items={1}
                            className='owl-theme'
                            autoplay
                            dots
                            center
                            margin={300}
                            autoplayTimeout={2500}
                            loop
                            responsive={{
                                0:{
                                    items:1,
                                },
                                1024.2:{
                                    items:3,
                                }
                            }}
                            >
                                {
                                students.map((student)=>{
                                    return(
                                        <div className='row justify-content-center'>
                                            <div className=" col-12 item card-container py-5 px-3 text-center fame-container animate__animated animate__fadeIn">
                                                
                                                <p className="text-blue mb-4"><b>DEMO CHALLENGE PASSER</b><br/>{moment(student.winningDate).format('MMMM YYYY')}</p>
                                                <div className='row justify-content-center'>
                                                    <div className='col-12 text-center'>
                                                        <img className='text-center' src={student.profile.profilePictureURL} style={{width:'50%',display:'inline'}} alt='profile'/>
                                                    </div>
                                                </div>
                                                <h5 className="text-blue saira mt-4">{student.profile.name.firstName} {student.profile.name.lastName}</h5>

                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </OwlCarousel>
                            :
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-center' style={{gap:'100px'}}>
                                {
                                students.map((student)=>{
                                    return(
                                        <div className='row justify-content-center'>
                                            <div className=" col-12 item card-container py-5 px-3 text-center fame-container animate__animated animate__fadeIn">
                                                
                                                <p className="text-blue mb-4"><b>DEMO CHALLENGE PASSER</b><br/>{moment(student.winningDate).format('MMMM YYYY')}</p>
                                                <div className='row justify-content-center'>
                                                    <div className='col-12 text-center'>
                                                        <img className='text-center' src={student.profile.profilePictureURL} style={{width:'50%',display:'inline'}} alt='profile'/>
                                                    </div>
                                                </div>
                                                <h5 className="text-blue saira mt-4">{student.profile.name.firstName} {student.profile.name.lastName}</h5>

                                            </div>
                                        </div>
                                    )
                                })
                                }
                                </div>
                            </div>

                            }
                            
                        </div>
                        <div className='col-12 d-block d-lg-none'>

                            <OwlCarousel
                            items={1}
                            className='owl-theme'
                            autoplay
                            dots
                            center
                            margin={300}
                            autoplayTimeout={2500}
                            loop
                            responsive={{
                                0:{
                                    items:1,
                                },
                                1024.2:{
                                    items:3,
                                }
                            }}
                            >
                                {
                                students.map((student)=>{
                                    return(
                                        <div className='row justify-content-center'>
                                            <div className=" col-12 item card-container py-5 px-3 text-center fame-container animate__animated animate__fadeIn">
                                                
                                                <p className="text-blue mb-4"><b>DEMO CHALLENGE PASSER</b><br/>{moment(student.winningDate).format('MMMM YYYY')}</p>
                                                <div className='row justify-content-center'>
                                                    <div className='col-12 text-center'>
                                                        <img className='text-center' src={student.profile.profilePictureURL} style={{width:'50%',display:'inline'}} alt='profile'/>
                                                    </div>
                                                </div>
                                                <h5 className="text-blue saira mt-4">{student.profile.name.firstName} {student.profile.name.lastName}</h5>

                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </OwlCarousel>
                            
                        </div>
                    </div>

                </>
                }

                {challengeStatus === 'closed' && !joined &&
                <div className='col-lg-10 text-center background-faded-error py-3 my-4 animate__animated animate__fadeIn' style={{borderRadius:"30px"}}>
                    <p className='text-error saira'>The <b>Monthly Demo Challenge</b> is <b>CLOSED</b>. Please wait for the first week of the month for its opening.</p>
                </div>
                }

                {!joined && challengeStatus === 'open' &&
                <div className='col-lg-10 text-center background-faded-success py-3 my-4 animate__animated animate__fadeIn' style={{borderRadius:"30px"}}>
                    <p className='text-success saira'>The <b>Monthly Demo Challenge</b> is <b>OPEN</b>. Join Now</p>
                </div>
                }

                {joined && challengeStatus === 'open' &&
                <div className='col-lg-10 text-center background-faded-light-blue py-3 my-4 animate__animated animate__fadeIn' style={{borderRadius:"30px"}}>
                    <p className='text-blue saira'>Successfully <b>REGISTERED</b> for the <b>Monthly Demo Challenge</b></p>
                </div>
                } 

                {joined && challengeStatus === 'closed' &&
                <>
                    <div className='col-lg-10 text-center background-faded-light-blue py-3 mt-4 animate__animated animate__fadeIn' style={{borderRadius:"30px"}}>
                        <p className='text-blue saira'><b>Monthly Demo Challenge</b> is <b>ONGOING</b>.</p>
                        
                    </div>
                    {demoAccount?
                    <>
                        <div className='col-lg-4 col-10 mb-4 mt-3'>
                            <button className='btn-solid w-100 py-2' style={{width:'fit-content'}} onClick={()=>setCheckingAccount(true)}><i className='fa fa-id-card'/> Account Credentials</button>
                        </div>
                        <div className='col-12'/>
                    </>
                    :
                    <p className='text-muted text-center mb-4 mt-3 bold'><i className='fa fa-lock'/> Wait for your Demo Account</p>
                    }
                </>
                }   

                {/*START: DESKTOP CHALLENGE REQUIREMENTS*/}
                <div className='col-lg-4 card-container-debossed py-4 px-5 d-none d-lg-block' style={{minWidth:"525px"}}>

                    <h5 className='text-center bold saira text-blue mb-3'><i className="fa-solid fa-file-circle-check"/> Requirements</h5>

                    <div className='row' style={{fontSize:"20px",rowGap:"10px"}}>
                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Capital</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>$200</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Risk per Trade</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>2-3%</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Target Profit</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>$100</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Maximum Drawdown</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>$70</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Maximum Trades</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>50</p>
                        </div>

                        <hr style={{borderTop:"2px black solid"}}/>

                        {!joined && challengeStatus === 'open'?
                        <button className='form-control btn-solid' onClick={()=>setJoining(true)}>Join</button>
                        :
                        <button className='form-control btn-solid' disabled>{joined?"Already Registered":"Join"}</button>
                        }

                    </div>

                </div>
                {/*END: DESKTOP CHALLENGE REQUIREMENTS*/}

                {/*START: MOBILE CHALLENGE REQUIREMENTS*/}
                <div className='col-lg-4 card-container-debossed py-4 px-4 d-block d-lg-none' style={{maxWidth:"475px"}}>

                    <h5 className='text-center bold saira text-blue mb-3'><i className="fa-solid fa-file-circle-check"/> Requirements</h5>

                    <div className='row' style={{fontSize:"14px",rowGap:"10px"}}>
                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Capital</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>$200</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Risk per Trade</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>2-3%</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Target Profit</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>$100</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Maximum Drawdown</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>$70</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Maximum Trades</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>50</p>
                        </div>

                        <hr style={{borderTop:"2px black solid"}}/>

                        {!joined && challengeStatus === 'open'?
                        <button className='form-control btn-solid' onClick={()=>setJoining(true)}>Join</button>
                        :
                        <button className='form-control btn-solid' disabled>{joined?"Already Registered":"Join"}</button>
                        }

                    </div>

                </div>
                {/*END: MOBILE CHALLENGE REQUIREMENTS*/}

                <div className='col-12'/>
                
                
                <div className='col-lg-6 mt-5'>
                    
                    <h4 className='text-center saira text-blue bold m-0'><i className="fa-solid fa-person-circle-question me-2"/>Frequently Asked Questions</h4>
                    
                    <div className='row justify-content-center'>
                        <div className='col-6'>
                            <hr className='px-5 blue-hr' style={{opacity:"0.5"}}/>
                        </div>
                    </div>

                    <div className="accordion" id="accordionFAQ">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                                What will I receive if I passed the challenge?
                            </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                You will receive a <strong>certificate</strong> and <strong>exclusive merch</strong> from the <strong>Trading Circle Academy</strong> proving that you are now a certified Forex trader.
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                                What if I didn't pass the challenge?
                            </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                            <strong>Join again</strong> on the next batch.
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                                How many times can I join?
                            </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                As long as you feel that you need more practice, <strong>you may join</strong>.
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                                Can I still join if I passed the challenge already?
                            </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                            <strong>Yes.</strong> You are free to join as long as you like.
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSeven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven">
                                When do I know if the challenge starts already?
                            </button>
                            </h2>
                            <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                The moment that you press the join button and received your account credentials, <strong>you may start trading</strong>. You are officially part of the batch.
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingEight">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight">
                                What are the grounds for disqualification?
                            </button>
                            </h2>
                            <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                If you did <strong>not follow the instructions</strong> including: overrisking, trading exotic pairs, and refunding your account.
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingNine">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine">
                                Can I join again on the next batch if I get disqualified?
                            </button>
                            </h2>
                            <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                            <strong>Yes</strong>. This time follow the instructions.
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen">
                                Can I use other broker in taking the challenge?
                            </button>
                            </h2>
                            <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                            <strong>No</strong>. Only the <strong>recommended</strong> broker of <strong>Trading Circle Academy</strong> is allowed.
                            </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>

        <ModalDemoAcount show={checkingAccount} onHide={()=>setCheckingAccount(false)} demoAccount={demoAccount} />
        <ModalClaimPrize show={claimingPrize} onHide={()=>setClaimingPrize(false)} student={student} uid={uid} claimData={claimData} />
        
        <ModalLoading show={processing} />
        <ModalConfirmation show={joining} onConfirm={()=>joinChallenge()} onHide={()=>setJoining(false)} message={student && `Join the Monthly Demo Challenge using this account (${student.profile.emailAddress})?`}/>
    
    </>
    )
}