import Modal from 'react-bootstrap/Modal';
import { MutatingDots } from 'react-loader-spinner';

export default function ModalLoading(props) {
  return (

    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{backgroundColor:"transparent !important"}}
      contentClassName="transparent-modal"
      backdropClassName={props.noBackdrop?'no-backdrop':'top-most'}
      keyboard={false}
      backdrop='static'
    >
        <div className='row'>
            <div className='col-12 d-flex justify-content-center'>
                <MutatingDots 
                    height="100"
                    width="100"
                    color="var(--primary)"
                    secondaryColor= 'var(--accent)'
                    radius='12.5'
                    ariaLabel="mutating-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
        </div>

    </Modal>
  );
}