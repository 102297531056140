import { Helmet } from 'react-helmet-async';
import { Header } from '../../components/general/Header';
import { useEffect, useState } from 'react';
import { Footer } from '../../components/academy/Footer';
import ModalLoading from '../../components/general/ModalLoading';


export const LowTicketAcademy = (props) =>{

    let [processing,setProcessing] = useState(false)

    let [selectedDay,setSelectedDay] = useState(1);
    let [selectedVideo,setSelectedVideo] = useState(0);

    let [videoLink,setVideoLink] = useState("https://player.vimeo.com/video/1025411451?h=ab0b3643b9&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479");

    let [dayTitle] = useState([
        "Basic & Foundation",
        "Platform Navigation",
        "Technical Analysis"
    ])

    let [videoTitles] = useState([
        "How Forex Works",
        "Advantages of Forex",
        "Newbie Common Mistakes",
        "Setting Up Your FX Primus",
        "Navigating Tradingview",
        "Psychological Preparation",
        "",
        "",
        ""
    ])

    let [videoLinks] = useState([
        "https://player.vimeo.com/video/1025411451?h=ab0b3643b9&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        "https://player.vimeo.com/video/1025412992?h=200ed626f1&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        "https://player.vimeo.com/video/1025422992?h=5e6c71d498&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        "https://player.vimeo.com/video/1025621799?h=75b1e9b9c1&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        "https://player.vimeo.com/video/1025620485?h=8d2354a336&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        "https://player.vimeo.com/video/1025619133?h=d4f2fe4676&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        "",
        "",
        ""
    ]);

    useEffect(()=>{

        setVideoLink(videoLinks[selectedVideo])
        setProcessing(true)
        setTimeout(()=>{
            setProcessing(false);
        },1000)

    },[selectedVideo,videoLinks])

    useEffect(()=>{
        
        setSelectedVideo((selectedDay-1)*3)
        setProcessing(true)
        setTimeout(()=>{
            setProcessing(false);
        },1000)

    },[selectedDay,videoLinks])

    return(
        <>
            <Helmet>
                <title>Trading Circle - Academy</title>
            </Helmet>

            <div className='container-fluid pt-3'>

                <Header title="Three-Day Forex Power Course"/>

                <div className='row justify-content-center mt-4 mb-5'>
                    <div className='col-lg-12 col-12'>
                        <div className='row justify-content-center' style={{columnGap:'12px'}}>

                            <button onClick={()=>setSelectedDay(1)} className={`py-2 ${selectedDay === 1?"col-4 btn-blue":"col-3 btn-outline-blue"}`}><i className='fa fa-user'/> Day 1</button>

                            <button onClick={()=>setSelectedDay(2)} className={`py-2 ${selectedDay === 2?"col-4 btn-blue":"col-3 btn-outline-blue"}`}><i className='fa fa-compass'/> Day 2</button>

                            <button onClick={()=>setSelectedDay(3)} className={`py-2 ${selectedDay === 3?"col-4 btn-blue":"col-3 btn-outline-blue"}`}><i className='fa fa-chart-bar'/> Day 3</button>
                            
                        </div>
                    </div>
                    <div className='col-lg-10 mt-5'>
                        <div className='row'>
                            <div className='col-lg-4 '>
                                <h5 className='bold text-center'>{dayTitle[selectedDay-1]}</h5>
                                <hr className='hr-white'/>
                                <div className='row px-3 mb-4' style={{rowGap:'10px'}}>
                                {
                                    videoTitles.map((e,i)=>{
                                        
                                        if( i >= (selectedDay-1)*3 && i < (selectedDay)*3){
                                            return(
                                            <div className={`col-12 text-start pointer ${selectedVideo === i?"text-light-blue":"text-dark-blue"}`} onClick={()=>setSelectedVideo(i)}>
                                                <i className='fa fa-circle-play'/> {e}
                                            </div>
                                            )
                                        }else return null;
                                    })
                                }
                                </div>
                            </div>
                            <div className='col-lg-8'>
                                <div className='video-container' style={{padding:"56.25% 0 0 0",position:"relative"}}><iframe src={videoLink} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} title="Website Walkthrough"></iframe></div>
                                <script src="https://player.vimeo.com/api/player.js"></script>
                            </div>
                        </div>
                    </div>
                    
                </div>

            </div>

            <Footer/>
            <ModalLoading show={processing}/>


        </>
    )

}