import formBG from '../../img/bg/form-bg.png';
import wordLogo from '../../img/logo/word-logo.png';
import whiteLogo from '../../img/logo/white-logo.png';
import blueFullLogo from '../../img/logo/blue-full-logo.png';
import profilePlaceholder from '../../img/placeholder/profile-placeholder.png';

import { Footer } from '../../components/landing/Footer';
import { useEffect, useState } from 'react';
import $ from 'jquery';
import {hasLetter, getFileExt,hasCorrectEmail, hasNumber, hasUppercase, toTitleCase} from '../../helper/formatter'
import { Helmet } from 'react-helmet-async';
import {createUserWithEmailAndPassword} from 'firebase/auth'
import {onValue, ref,set} from 'firebase/database'
import {getDownloadURL, ref as sref, uploadBytes} from 'firebase/storage'
import {studentAuth, studentDB, studentStorage} from '../../api/firebase-student-config'
import ModalLoading from '../../components/general/ModalLoading';

import moment from 'moment'
import ModalCropper from '../../components/general/ModalCropper';
import ModalAlert from '../../components/general/ModalAlert';
import { adminDB } from '../../api/firebase-admin-config';
import { globalMigrationMode } from '../../helper/globals';

export const Register = () =>{

    const [processing,setProcessing] = useState(false);
    const [step,setStep] = useState(1)

    const [firstName,setFirstName] = useState()
    const [lastName,setLastName] = useState()
    const [birthday,setBirthday] = useState()
    const [contactNumber,setContactNumber] = useState()
    const [discovery,setDiscovery] = useState('Facebook')

    const [rawImage,setRawImage] = useState()
    const [profilePicture,setProfilePicture] = useState()
    const [profilePictureURL,setProfilePictureURL] = useState()
    const [cropping,setCropping] = useState(false)

    const [emailAddress,setEmailAddress] = useState()
    const [password,setPassword] = useState()
    const [confirmPassword,setConfirmPassword] = useState()

    const [policyAgree,setPolicyAgree] = useState(false)

    const [migrateNotice,setMigrateNotice] = useState(true)
    const [migrationMode] = useState(globalMigrationMode)
    const [migrationStudents,setMigrationStudents] = useState()
    const [currentMigrationStudent,setCurrentMigrationStudent] = useState()

    useEffect(()=>{

        if(migrationMode){

            onValue(ref(adminDB,'/migration'),(snapshot)=>{
                if(snapshot.exists()){
                    
                    var mStudents = []
                    
                    for(var key in snapshot.val()){
                        mStudents.push(snapshot.val()[key])
                    }   
                    
                    setMigrationStudents(mStudents)
                    
                }
            })

        }
        

    },[migrationMode])

    
    function verifyFirstName(){

        var txtFirstName = $('#txtFirstName').val().trim();
        var errFirstName = $('#errFirstName');
        
        errFirstName.text('')
        setFirstName(null);

        if(txtFirstName.trim().length === 0) errFirstName.text('Blank spaces are not allowed.');
        else if(txtFirstName.length <= 1) errFirstName.text('Atleast 2 Characters.');
        else setFirstName(toTitleCase(txtFirstName));
        
    }

    function verifyLastName(){

        var txtLastName = $('#txtLastName').val().trim();
        var errLastName = $('#errLastName');
        
        errLastName.text('')
        setLastName(null);

        if(txtLastName.trim().length === 0) errLastName.text('Blank spaces are not allowed.');
        else if(txtLastName.length <= 1) errLastName.text('Atleast 2 Characters.');
        else setLastName(toTitleCase(txtLastName));

    }

    function verifyBirthday(){

        var txtBirthday = $('#txtBirthday').val();
        var errBirthday = $('#errBirthday');
        
        errBirthday.text('')
        setBirthday(null);

        if(txtBirthday.trim().length === 0) errBirthday.text('Select a Date.');
        else setBirthday(txtBirthday);


    }

    function verifyContactNumber(){

        var txtContactNumber = $('#txtContactNumber').val().trim();
        var errContactNumber = $('#errContactNumber');
        
        errContactNumber.text('')
        setContactNumber(null);

        if(txtContactNumber.trim().length === 0) errContactNumber.text('Blank spaces are not allowed.');
        else if(hasLetter(txtContactNumber)) errContactNumber.text('Letters are not allowed.');
        else if(txtContactNumber.length > 15) errContactNumber.text('Invalid Format.');
        else if(txtContactNumber.includes(' ')) errContactNumber.text('Spaces are not allowed')
        else setContactNumber(txtContactNumber);

    }

    function verifyDiscovery(){
        var slcDiscovery = $('#slcDiscovery').find(":selected").val();
        setDiscovery(slcDiscovery)
    }

    function verifyProfilePicture(){

        var fileProfilePicture = $("#fileProfilePicture").prop('files')
        var txtErr = $("#errProfilePicture")
    
        setProfilePicture(null)
        setProfilePictureURL(null)
        txtErr.text('')
    
        if(fileProfilePicture.length === 0) txtErr.text('Please select a file.')
        else if(getFileExt(fileProfilePicture[0].name).toLowerCase() !== 'png' && getFileExt(fileProfilePicture[0].name).toLowerCase() !== 'jpg' && getFileExt(fileProfilePicture[0].name).toLowerCase() !== 'jpeg') txtErr.text('PNG and JPG Files are the only accepted files.')
        else{
            var _URL = window.URL || window.webkitURL;

            var objectURL = _URL.createObjectURL(fileProfilePicture[0])

            setRawImage(objectURL)
            setCropping(true)

        }
        
    }

    function confirmProfilePicture(croppedImage){

        var txtErr = $("#errProfilePicture")
    
        setProfilePicture(null)
        setProfilePictureURL(null)
        txtErr.text('')

        setCropping(false)  

        var xhr = new XMLHttpRequest();
        xhr.open("GET", croppedImage);
        xhr.responseType = "blob";
        
        xhr.onload = function(){

            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(this.response);

            setProfilePicture(croppedImage)
            setProfilePictureURL(imageUrl)

        }
        xhr.send();

    }

    function cancelCrop(){
        $("#fileProfilePicture").val(null)
        setCropping(false)
        setProfilePicture(null)
        setProfilePictureURL(null)
        setRawImage(null)
    }

    function verifyEmailAddress(){

        var txtEmailAddress = $('#txtEmailAddress').val();
        var errEmailAddress = $('#errEmailAddress');
        
        txtEmailAddress = txtEmailAddress.trim()

        errEmailAddress.text('')
        //$('#infoEmailAddress').text('')  
        setEmailAddress(null);
        setCurrentMigrationStudent(null)

        if(txtEmailAddress.trim().length === 0) errEmailAddress.text('Blank spaces are not allowed.');
        else if(!hasCorrectEmail(txtEmailAddress.trimEnd())) errEmailAddress.text('Invalid Email Format.');
        else{
            
            if(migrationMode){

                var canMigrate = false

                for(var i in migrationStudents){
                    
                    if(migrationStudents[i].email === txtEmailAddress){
                        setCurrentMigrationStudent(migrationStudents[i])
                        canMigrate = true
                        break
                    }

                }

                if(canMigrate){
                    setEmailAddress(txtEmailAddress)
                    //$('#infoEmailAddress').text('Existing Student Detected.')
                }
                else{
                    errEmailAddress.text('Email is not from an Old Student.')
                } //setEmailAddress(txtEmailAddress)
                     
                

            }
            else setEmailAddress(txtEmailAddress);

        }

    }

    function verifyPassword(){

        var txtPassword = $('#txtPassword').val();
        var errPassword = $('#errPassword');
        
        errPassword.text('')
        setPassword(null);

        if(txtPassword.trim().length === 0) errPassword.text('Blank spaces are not allowed.');
        else if(txtPassword.length < 8) errPassword.text('Atleast 8 characters.');
        else if(!hasUppercase(txtPassword)) errPassword.text('Should have atleast 1 uppercase letter.')
        else if(!hasNumber(txtPassword)) errPassword.text('Should have atleast 1 number.')
        else setPassword(txtPassword);

        verifyConfirmPassword()

    }

    function verifyConfirmPassword(){

        var txtPassword = $('#txtPassword').val();

        var txtConfirmPassword = $('#txtConfirmPassword').val();
        var errConfirmPassword = $('#errConfirmPassword');
        
        errConfirmPassword.text('')
        setConfirmPassword(null);

        if(txtConfirmPassword !== txtPassword) errConfirmPassword.text('Password does not match.')
        else setConfirmPassword(txtConfirmPassword);

    }
    
    function verifyAgree(){
        var aPP = $('#chkAgreePrivacyPolicy').is(":checked")
        var aTC = $('#chkAgreeTermsConditions').is(":checked")
        setPolicyAgree(aPP && aTC)
    }

    function registerUser(){

        setProcessing(true);

        createUserWithEmailAndPassword(studentAuth,emailAddress,password).then((student)=>{

            fetch(profilePicture).then(r => r.blob()).then(blobFile => {

                var file = new File([blobFile], 'Hi', { type: blobFile.type })

                uploadBytes(sref(studentStorage,'students/profilePicture/' + student.user.uid), file).then((res)=>{

                    getDownloadURL(sref(studentStorage,'students/profilePicture/' + student.user.uid)).then((url)=>{
    
                        var registerDate = moment().format('MMMM DD, YYYY hh:mm:ss a')
    
                        const body = {
                            profile:{
                                name:{
                                    firstName:firstName,
                                    lastName:lastName
                                },
                                birthday:birthday,
                                contactNumber:contactNumber,
                                emailAddress:emailAddress,
                                profilePictureURL:url,
                                discovery:discovery,
                                enrollment:{
                                    registerDate:registerDate,
                                    enrollmentDate:currentMigrationStudent && currentMigrationStudent.membership?registerDate:null,
                                    upgradeDate:currentMigrationStudent && currentMigrationStudent.membership === 'Elite'?registerDate:null,
                                }
                            }
                        }
    
                        set(ref(studentDB,'students/' + student.user.uid),body).then(()=>{
    
                            window.location.href = '/';
                            setProcessing(false);
    
                        }).catch((error)=>{
                            alert(error.message);
                            setProcessing(false);
                        })
    
    
                    }).catch((error)=>{
                        alert(error.message);
                        setProcessing(false);
                    })
    
                }).catch((error)=>{
                    alert(error.message);
                    setProcessing(false);
                })

            })
            
            
        }).catch((error)=>{

            if(error.code === 'auth/email-already-in-use'){
                $('#errEmailAddress').text('Email is already in use, try logging in instead.')
            }
            //alert(error.message);
            setProcessing(false);
        })

    }

    

    return(
        <>
            <Helmet>
                <title>Trading Circle - Register</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn credential-container" style={{marginTop:'-15px'}}>

                <div className="row h-100 justify-content-center mx-0">

                    <div className="col-lg-6 d-lg-block d-none" style={{background:`url(${formBG})`,backgroundSize:"cover", backgroundPosition:"center", borderRadius:"0 2vw 2vw 0"}}>

                        <div className="row h-100 justify-content-center" style={{background:"rgba(0,0,0,0.5)",borderRadius:"inherit"}}>
                            
                            <div className='col-12 align-self-center text-center animate__animated animate__fadeInDown'>

                                <img className='wow fadeIn creds-white-logo' src={whiteLogo} alt="white logo"/><br/>
                                <img className='wow fadeIn creds-word-logo' src={wordLogo} alt="word logo"/>  
                                <h1 className='text-white mt-3 mb-0 wow fadeIn'>Become a Student</h1>

                            </div>

                        </div>

                    </div>

                    <div className="col-lg-6 col-11 mb-lg-0 creds-input-container">

                        <div className='row h-100 justify-content-center m-0 p-0'>

                            <div className='col-lg-8 text-center align-self-center animate__animated animate__fadeInUp creds-form-container'>

                                <img className='wow fadeIn ps-5 pe-5' src={blueFullLogo} alt="blue logo" width="100%"/>
                                <p className='saira m-0'>Infinite Trading Experience</p>
                                
                                <hr className='blue-hr mb-4'/>

                                <div className='row justify-content-center'>

                                    <div className='col-12 mb-3'>
                                        <h1 className='saira bold text-blue m-0'>Start Learning</h1>
                                        <p className='saira'>Learn the secrets.</p>
                                        {true && <p className='saira text-light-blue medium pointer my-3' onClick={()=>setMigrateNotice(true)}><i className="fa fa-circle-exclamation"/> Transferring Notice</p>}
                                    </div>

                                    <div className='col-12 mb-4'>
                                        <div className='row justify-content-center'>
                                            <div className='col-12 d-flex justify-content-center' style={{gap:"10px"}}>
                                                <div className={step===1?'dot active':'dot'}/>
                                                <div className={step===2?'dot active':'dot'}/>
                                                <div className={step===3?'dot active':'dot'}/>
                                            </div>
                                        </div>
                                    </div>

                                    

                                    <div className='col-12 p-0 animate__animated animate__fadeIn' style={{display:step===1?'':'none'}}>

                                        <div className='row p-lg-0 ps-4 pe-4'>

                                            <div className='col-lg-6 text-start mb-3'>
                                        
                                                <label className='saira bold text-blue mb-1' htmlFor="txtFirstName">First Name</label>
                                                <input id="txtFirstName" onChange={()=>verifyFirstName()} type="text" class="form-control"/>
                                                <small id="errFirstName" class="form-text text-danger"></small>

                                            </div>

                                            <div className='col-lg-6 text-start mb-3'>
                                        
                                                <label className='saira bold text-blue mb-1' htmlFor="txtLastName">Last Name</label>
                                                <input id="txtLastName" onChange={()=>verifyLastName()} type="text" class="form-control"/>
                                                <small id="errLastName" class="form-text text-danger"></small>

                                            </div>

                                            <div className='col-12 text-start mb-3'>
                                        
                                                <label className='saira bold text-blue mb-1' htmlFor="txtBirthday">Birthday</label>
                                                <input onChange={()=>verifyBirthday()} id="txtBirthday" type="date" class="form-control"/>
                                                <small id="errBirthday" class="form-text text-danger"></small>

                                            </div>

                                            <div className='col-12 text-start mb-3'>
                                        
                                                <label className='saira bold text-blue mb-1' htmlFor="txtContactNumber">Contact Number</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><span className='text-blue bold fa fa-phone'></span></span>
                                                    </div>
                                                    <input id="txtContactNumber" onChange={()=>verifyContactNumber()} maxLength={15} type="text" class="form-control"/>
                                                </div>
                                                <small id="errContactNumber" class="form-text text-danger"></small>

                                            </div>

                                            <div className='col-12 text-start mb-4'>
                                        
                                                <label className='saira bold text-blue mb-1' htmlFor="slcDiscovery">Where did you see Trading Circle?</label>
                                                <select className='form-select' id='slcDiscovery' onChange={()=>verifyDiscovery()}>
                                                    <option value=''>-- Select One --</option>
                                                    <option value='Facebook Ads'>Facebook Ads</option>
                                                    <option value='Facebook Group'>Facebook Group</option>
                                                    <option value='Forex Trading PH'>Forex Trading PH</option>
                                                    <option value='TikTok'>TikTok</option>
                                                    <option value='Instagram'>Instagram</option>
                                                    <option value='YouTube Ads'>YouTube Ads</option>
                                                    <option value='YouTube Main Channel'>YouTube Main Channel</option>
                                                    <option value='Friend Referral'>Friend Referral</option>
                                                </select>

                                            </div>

                                        </div>
                                        
                                    </div>

                                    <div className='col-12 p-0 animate__animated animate__fadeIn' style={{display:step===2?'':'none'}}>

                                        <div className='row p-lg-0 ps-4 pe-4'>

                                            <div className='col-12 text-start mb-3 justify-content-center'>
                                        
                                                <div className='row'>
                                                    <div className='col-12 d-flex justify-content-center'>
                                                        <img src={profilePictureURL?profilePictureURL:profilePlaceholder} alt="profile" width="150px" style={{borderRadius:"100rem"}}/>
                                                    </div>
                                                    <small id="errProfilePicture" class="form-text text-danger text-center mb-3 mt-2"></small>
                                                </div>

                                                <label className='saira bold text-blue mb-1' htmlFor="fileProfilePicture">Profile Picture</label>
                                                <input id="fileProfilePicture" onChange={()=>verifyProfilePicture()} type="file" class="form-control" accept='image/*'/>

                                            </div>

                                        </div>
                                        
                                    </div>

                                    <div className='col-12 p-0 animate__animated animate__fadeIn mb-3' style={{display:step===3?'':'none'}}>

                                        <div className='row p-lg-0 ps-4 pe-4'>

                                            <div className='col-12 text-start mb-4  '>
                                        
                                                <label className='saira bold text-blue mb-1' htmlFor="txtEmailAddress">Email Address</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i className='fa fa-user'/></span>
                                                    </div>
                                                    <input id="txtEmailAddress" onChange={()=>verifyEmailAddress()} type="text" class="form-control" placeholder="Enter Email"/>
                                                </div>
                                                <small id="errEmailAddress" class="form-text text-danger mb-3 mt-2"></small>
                                                {migrationMode && <small id="infoEmailAddress" class="form-text text-light-blue text-start mt-2 d-block">*Only Old Students are accepted for now</small>}

                                            </div>

                                            <div className='col-12 text-start mb-3'>
                                                
                                                <label className='saira bold text-blue mb-1' htmlFor="txtPassword">Password</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i className='fa fa-lock'/></span>
                                                    </div>
                                                    <input id="txtPassword" onChange={()=>verifyPassword()} type="password" class="form-control" placeholder="Enter Password"/>
                                                </div>
                                                <small id="errPassword" class="form-text text-danger text-center mb-3 mt-2"></small>

                                            </div>

                                            <div className='col-12 text-start mb-3'>
                                                
                                                <label className='saira bold text-blue mb-1' htmlFor="txtConfirmPassword">Confirm Password</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i className='fa fa-lock'/></span>
                                                    </div>
                                                    <input id="txtConfirmPassword" onChange={()=>verifyConfirmPassword()} type="password" class="form-control" placeholder="Enter Password Again"/>
                                                </div>
                                                <small id="errConfirmPassword" class="form-text text-danger text-center mb-3 mt-2"></small>

                                            </div>

                                            <div className='col-12 text-start mb-1 mt-2 ms-3'>
                                                <input onChange={()=>verifyAgree()} id='chkAgreePrivacyPolicy' className='me-2 pointer' type="checkbox"/>
                                                <label className='saira text-blue mb-1'>Agree to our <a href='/policies/privacy-policy' target="_blank" className='text-blue'><b><u>Privacy Policy</u></b></a></label>
                                            </div>

                                            <div className='col-12 text-start mb-2 ms-3'>
                                                <input onChange={()=>verifyAgree()} id='chkAgreeTermsConditions' className='me-2 pointer' type="checkbox"/>
                                                <label className='saira text-blue mb-1'>Agree to our <a href='/policies/terms-and-conditions' target="_blank" className='text-blue'><b><u>Terms & Conditions</u></b></a></label>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>

                                    <div className='col-12 p-lg-0 ps-4 pe-4'>
                                        {step === 1 &&
                                            <div>
                                            {firstName && lastName && birthday && contactNumber && discovery?
                                                <button className='form-control saira m-0' onClick={()=>setStep(2)}>Next<i className='fa fa-arrow-right ms-2'/></button>
                                                :
                                                <button className='form-control saira m-0' disabled>Next<i className='fa fa-arrow-right ms-2'/></button>
                                            }   
                                            </div>
                                        }
                                        
                                        
                                        {step === 2 && 
                                        <div className='row'>

                                            <div className='col-2 pe-0'>
                                                <button className='form-control saira m-0' onClick={()=>setStep(1)}><i className='fa fa-arrow-left'/></button>
                                            </div>

                                            <div className='col-10'>
                                                {profilePicture?
                                                <button className='form-control saira m-0' onClick={()=>setStep(3)}>Next<i className='fa fa-arrow-right ms-2'/></button>
                                                :
                                                <button className='form-control saira m-0' disabled>Next<i className='fa fa-arrow-right ms-2'/></button>
                                                }
                                            </div>

                                        </div>
                                        }

                                        {step === 3 && 
                                        <div className='row'>

                                            <div className='col-2 pe-0'>
                                                <button className='form-control saira m-0' onClick={()=>setStep(2)}><i className='fa fa-arrow-left'/></button>
                                            </div>

                                            <div className='col-10'>
                                                {emailAddress && password && confirmPassword && policyAgree?
                                                <button className='form-control saira m-0' onClick={()=>registerUser()}><i className='fa fa-check me-1'/>Register</button>
                                                :
                                                <button className='form-control saira m-0' disabled><i className='fa fa-check me-1'/>Register</button>
                                                }
                                            </div>

                                        </div>
                                        }

                                    </div>

                                    <div className='col-12 mt-3'>
                                        <p className='saira text-dark-blue medium'>Already have an Account? <a className='bold pointer text-dark-blue' href='/login'>Sign in here.</a></p>
                                    </div>
                                    
                                    <div className='col-8 mt-4'>
                                        <hr className='blue-hr'/>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
            
            <Footer/>
            
            {true && <ModalAlert show={migrateNotice} onHide={()=>setMigrateNotice(false)} icon='fa fa-circle-exclamation' title='IMPORTANT' message='We are now <b>Transferring Existing Students</b> to the <b>New Website</b>.<br/><br/> If you are an <b>Existing Student</b> before <b>April 12, 2023</b> please register using your <b>old email address</b>.'/>}
            
            <ModalCropper show={cropping} image={rawImage} onHide={()=>cancelCrop()} onCrop={(img)=>confirmProfilePicture(img)}/>   
            <ModalLoading show={processing} />
            
        </>
    );

}