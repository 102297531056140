import { Helmet } from "react-helmet-async"
import { PolicySidebar } from "../../components/general/PolicySidebar"

import bir from "../../img/legal-documents/BIR.png"
import dti from "../../img/legal-documents/DTI-permit.png"

export const LegalDocuments = () =>{

    return(
        <div className="container-fluid">

            <Helmet>
                <title>Trading Circle - Legal Documents</title>
            </Helmet>

            <div className="row justify-content-center p-lg-5 p-0">

                <PolicySidebar/>

                <div className="col-lg-6 text-blue policy-container ps-lg-4 px-5 animate__animated animate__fadeIn text-center">
                    <h3 className="bold saira mt-lg-5 mt-4">Legal Documents</h3>
                    <h5 className="bold saira">Below are the legal documents of Trading Circle</h5>

                    <img className="my-5" src={dti} alt='dti' width='90%'/>
                    <img className="my-5" src={bir} alt='BIR' width='90%'/>
                    
                </div>

            </div>

        </div>
    )

}