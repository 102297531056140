import { onAuthStateChanged } from "firebase/auth"
import { onValue, ref, set } from "firebase/database"
import {ref as sref} from 'firebase/storage'
import { getDownloadURL } from "firebase/storage"
import React, { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { adminDB, adminStroage } from "../../api/firebase-admin-config"
import { studentAuth, studentDB } from "../../api/firebase-student-config"
import ModalConfirmation from "../../components/general/ModalConfirmation"
import ModalLoading from "../../components/general/ModalLoading"
import blackWordLogo from '../../img/logo/black-word-logo.png';


export const AssessmentResults = ()=>{

    const [processing,setProcessing] = useState(true)

    const [params] = useSearchParams()

    const [course,setCourse] = useState()
    const [assessment,setAssessment] = useState([])
    const [assessmentImages] = useState([])

    const [score,setScore] = useState(0)
    const [attempt,setAttempt] = useState(0)

    const [student,setStudent] = useState()

    const navigate = useNavigate();

    const [retaking,setRetaking] = useState(false)

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(()=>{
        onAuthStateChanged(studentAuth,(s)=>{
            if(s) setStudent(s)
        })

    },[])
    
    useEffect(()=>{

        if(params.get('chapter')){

            if(student){

                setProcessing(true)

                onValue(ref(studentDB,`/assessments/${student.uid}/${params.get('chapter')}`),(snap)=>{
                    if(snap.exists()){

                        if(snap.val().answers){
                            
                            setScore(snap.val().score)
                            setAttempt(snap.val().attempt)

                            onValue(ref(adminDB,`/assessment/${params.get('chapter')}`),(snapshot)=>{

                                onValue(ref(adminDB,`/courses/${params.get('chapter')}`),(s)=>{
                                    if(s.exists()){
                                        setCourse(s.val())
                                        setProcessing(false)
                                    }
                                    else navigate(`/module/assessment?chapter=${params.get('chapter')}`)
                                });
            
                                if(snapshot.exists()){
                                    
                                    var questions = []

                                    for(var key in snap.val().answers){
                                        var q = snapshot.val()[snap.val().answers[key].index]

                                        var cs = []

                                        for(var k in q.Choices){
                                            cs.push(JSON.parse(`{"letter":"${k}","choice":"${q.Choices[k]}"}`))
                                        }
                                        
                                        q['Key'] = snap.val().answers[key].index
                                        q['Choices'] = cs
                                        q['Index'] = key
                                        q['YourAnswer'] = snap.val().answers[key].answer
                                        questions.push(q)

                                    }
            
                                    setAssessment(questions)
            
                                }else navigate(`/module/assessment?chapter=${params.get('chapter')}`)
            
                            })

                        }else navigate(`/module/assessment?chapter=${params.get('chapter')}`)

                    }else navigate(`/module/assessment?chapter=${params.get('chapter')}`)
                })

            }

        }else navigate(`/module/assessment?chapter=${params.get('chapter')}`)
        

    },[params,student,navigate])

    useEffect(()=>{

        if(assessment.length > 0 && assessmentImages.length === 0){

            assessmentImages.length = 0
            
            function getImage(i){
                
                if(!assessment[i]) setTimeout(()=>{getImage(i)},500)
                else{

                getDownloadURL(sref(adminStroage,`/assessment/C${params.get('chapter')}-Q${assessment[i].Key}.png`)).then((url)=>{
            
                    assessmentImages[assessment[i].Key] = url
                    forceUpdate()

                    if(i < assessment.length) getImage(i+1)


                }).catch((error)=>{
                    if(i < assessment.length) getImage(i+1)
                })  
            }

            }

            getImage(0)


        }

    },[assessment,params,assessmentImages,forceUpdate])

    function retakeAssessment(){

        setProcessing(true)

        setTimeout(()=>{
            set(ref(studentDB,`/assessments/${student.uid}/${params.get('chapter')}/answers`),{}).then(()=>{
                navigate(`/module/assessment?chapter=${params.get('chapter')}`)
            }).catch((error)=>{
                setProcessing(false)
            })
        },500)
        

    }

    return(
        <>
        {course &&
        <div className="row justify-content-center animate__animated animate__fadeIn" style={{margin:0}}>
            <div className="col-lg-6 col-11 card-container pb-5 px-0 my-5 assessment-container align-self-center">
                             
                <p className="mb-4" style={{backgroundImage:`url(${course.thumbnailURL})`,borderRadius:'10px',height:'5px',backgroundPosition:'center bottom'}}/>
                
                <div className="text-center mt-3 mb-4">
                    <img className="darkmode-logo" src={blackWordLogo} style={{width:'calc(12.5rem + 5vw)'}} alt='logo'/>
                </div>
                
                {course &&
                <div className="row justify-content-center">
                    <div className='col-lg-5 col-11 animate__animated animate__fadeIn'>
                        <p className='text-center module-chapter mb-2' style={{fontVariant:'small-caps',backgroundImage:`url(${course.thumbnailURL})`,fontSize:'20px'}}>Chapter {params.get('chapter')}</p>
                        
                        <div className="px-lg-0 px-5">
                            <img alt='icon' className='course-thumbnail animate__animated animate__fadeIn px-lg-0' style={{width:"100%",borderRadius:"25px"}} src={course.thumbnailURL}/>
                        </div>
                    </div>
                    <div className="col-lg-5 col-11 align-self-center px-5 mt-lg-0 mt-4">
                        <p className="text-center"><i className="fa-solid fa-file-circle-check module-chapter mb-2" style={{fontSize:'32px',backgroundImage:`url(${course.thumbnailURL})`,backgroundPosition:'right top'}}/></p>
                        <p className="text-center bold module-chapter" style={{fontSize:'32px',backgroundImage:`url(${course.thumbnailURL})`,backgroundPosition:'right top'}}>{score}/{assessment.length}</p>                    
                        <p className="bold text-center module-chapter" style={{fontSize:'20px',backgroundImage:`url(${course.thumbnailURL})`,backgroundPosition:'right top',fontVariant:'small-caps'}}><i className="text-light-blue"/> Result</p>
                        <hr/>
                        <p className="bold text-center module-chapter" style={{fontSize:'14px',backgroundImage:`url(${course.thumbnailURL})`,backgroundPosition:'right top'}}><i className="fa-solid fa-repeat text-light-blue"/> {2-attempt} Chance Left</p>
                        
                        {attempt < 2 &&
                        <div className="row">
                            <button className="py-2 px-5 mt-3" style={{backgroundImage:`url(${course.thumbnailURL})`,backgroundPosition:'right bottom'}} onClick={()=>setRetaking(true)}>Re-take Assessment</button>        
                        </div>
                        }

                    </div>
                </div>
                }   

            </div>

            {assessment.map((q)=>{

                return(
                    <div className="row justify-content-center animate__animated animate__fadeIn" key={`Q-${q.Index}`}>
                        <div className="col-lg-6 col-11 card-container pb-4 pt-3 px-0 my-3 assessment-container align-self-center">

                            <div className="row px-5 pt-4">
                                <div className="col-12 mb-4">
                                    <p className="bold text-blue text-justify" style={{fontSize:'calc(0.85rem + 0.3vw)'}}>{`${q.Index}. ${q.Question}`}</p>
                                </div>

                                {assessmentImages[q.Key] &&
                                <div className="col-12 mb-4 justify-content-center text-center">
                                    <img className="assessment-image" src={assessmentImages[q.Key]} alt='assessment'/>
                                </div>
                                }

                                {q.Choices.map((choice)=>{
                                    
                                    return(
                                        <p id={`answer-${choice['letter']}`} key={`answer-${choice['letter']}`} style={{fontSize:'calc(0.8rem + 0.2vw)'}} className={`pointer px-5 py-3 mb-1 mt-1 assessment-choice text-blue ${q['YourAnswer'] === choice['letter']?q['YourAnswer'] === q['Answer']?'selected correct':'selected wrong':'locked'}`}>
                                            <b>{choice['letter']}.</b> {choice['choice']} <i className={q['YourAnswer'] === choice['letter']?q['YourAnswer'] === q['Answer']?'fa fa-check':'fa fa-times':''}/>
                                        </p>
                                    )

                                })}

                                <p className="mt-3 px-4 text-lg-start text-center" style={{opacity:'0.5',fontSize:'calc(0.75rem + 0.15vw)'}}>Knowledge Assessment - <b>{course.title}</b></p>

                            </div>
                        </div>
                    </div>
                )

                })}

        </div>
        }

            <ModalConfirmation show={retaking} onHide={()=>setRetaking(false)} onConfirm={()=>retakeAssessment()} title='Retake Assessment?' message='Retaking the Assessment will delete the current results, proceed?'/>
            <ModalLoading show={processing}/>

        </>
    )
}