import { onValue, ref, set } from "firebase/database"
import { useEffect, useState } from "react"
import { studentDB } from "../../api/firebase-student-config"
import ModalLoading from "../general/ModalLoading"
export const TodoList = (props)=>{

    var [processing,setProcessing] = useState(false)
    
    var [todos,setTodos] = useState(
        {
            "A-Finish Chapters 1 to 3": false,
            "B-Open broker account under FX Primus": false,
            "C-Create a demo account in FX Primus Metatrader 4": false,
            "D-Sign in to Tradingview": false,
            "E-Attend live session training every Monday 7:30 pm": false,
            "F-First time plotting of Support and Resistance and Trendlines": false,
            "G-Post sample analyses to Facebook group": false,
            "H-Visit Forexfactory and analyze the imminent news": false,
            "I-Apply the CIRCLE Strategy and consult the coaches": false,
            "J-Enter a demo trade in Metatrader 4": false,
            "K-Join the monthly demo challenge": false,
            "L-Pass the demo challenge": false,
            "M-Fund and start a live account with 200 USD": false,
            "N-Join the Inner Circle for free signal analyses": false
        }
    )

    var [todoEnabled,setTodoEnabled] = useState()
    var [todoBefore,setTodoBefore] = useState()

    var [percentage,setPercentage] = useState()

    var [halfTodo,setHalfTodo] = useState([])

    useEffect(()=>{

        if(props.uid){
            onValue(ref(studentDB,`todos/${props.uid}`),(snapshot)=>{
                if(snapshot.exists()){

                    for(var key in snapshot.val()){
                        todos[key] = snapshot.val()[key] 
                    }

                    var json = snapshot.val()

                    //console.log(json)

                    setTodos(json)
                    
                }else{
                    set(ref(studentDB,`todos/${props.uid}`),todos);
                }
            })
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.uid])

    useEffect(()=>{

        var firstHalf = []
        var secondHalf = []
        var enableUntil = null
        var enableBefore = null

        var keys = Object.keys(todos);
        var half = Math.ceil(keys.length/2);

        for(var i = 0;i<half;i++){
            firstHalf.push(keys[i])
            if(!todos[keys[i]] && !enableUntil){
                enableUntil = keys[i]
                if(keys[i-1]) enableBefore = keys[i-1]
            }
        }

        for(i = half;i<keys.length;i++){
            secondHalf.push(keys[i])
            if(!todos[keys[i]] && !enableUntil){
                enableUntil = keys[i]
                if(keys[i-1]) enableBefore = keys[i-1]
            }
        }

        var completed = 0

        for(var key in todos){
            if(todos[key]) completed++
        }

        setPercentage(Math.floor((completed/keys.length) * 100))
        
        setTodoEnabled(enableUntil)
        setTodoBefore(enableBefore)
        
        setHalfTodo([firstHalf,secondHalf])

    },[todos])

    function updateTodo(key,value){

        var t = todos   
        t[key] = value
        setTodos(t)
        setProcessing(true)
        
        set(ref(studentDB,`todos/${props.uid}`),t).then(()=>{
            setProcessing(false)
        });

        if(!value) setTodoBefore(null)

    }
    
    return(
        <>
            <div className='row justify-content-center mt-5 mb-5 animate__animated animate__fadeIn' style={{gap:"20px"}}>
                                
                <div className='col-12 d-lg-flex d-block mb-lg-3 mb-0'>
                    <h5 className='bold saira text-lg-start text-center text-blue m-0'>To-do List</h5>
                    <div className="progress ms-lg-3 d-none d-lg-block align-self-center" style={{width:'85%'}}>
                        <div className="progress-bar saira bold" role="progressbar" style={{background:'var(--primary)',width:`${percentage}%`}}><span>{percentage === 100 &&<i className="fa fa-check me-1"/>}{percentage}%</span></div>
                    </div>
                </div>

                <div className='col-11 d-block d-lg-none mb-3 mb-lg-0'>
                    <div className="progress" style={{width:'100%'}}>
                        <div className="progress-bar saira bold" role="progressbar" style={{background:'var(--primary)',width:`${percentage}%`}}><span>{percentage === 100 &&<i className="fa fa-check me-1"/>}{percentage}%</span></div>
                    </div>
                </div>

                <div className="col-12">

                    <div className="row px-lg-5 px-4">

                    

                        <div className="col-lg-6 todo-container">

                            {halfTodo.length > 0 && halfTodo[0].map(key => {
                                
                                var len = Object.keys(todos).length;

                                return (
                                <div className="mb-lg-2 mb-3" key={key}>
                                    <div className='text-blue mb-lg-2 mb-3 d-block px-2'>
                                        
                                        {(todoBefore && key === todoBefore) || (percentage === 100 && key === Object.keys(todos)[len-1])? 
                                        <input onChange={()=>updateTodo(key,false)} id={`chk${String(key).trim()}`} className='me-lg-3 me-2 pointer d-inline' type="checkbox" checked/>
                                        :
                                        key === todoEnabled?
                                        <input onChange={()=>updateTodo(key,true)} id={`chk${String(key).trim()}`} className='me-lg-3 me-2 pointer d-inline' type="checkbox"/>
                                        :
                                        todos[key]?
                                        <input id={`chk${key}`} className='me-lg-3 me-2 pointer d-inline' type="checkbox" disabled checked/>
                                        :
                                        <input id={`chk${key}`} className='me-lg-3 me-2 pointer d-inline' type="checkbox" disabled/>
                                        }
                                        

                                        <label className='saira text-blue d-inline pointer' htmlFor={`chk${key}`}>{String(key).split('-')[1]}</label>
                                    </div>
                                    <hr className="my-2"/>
                                </div>
                                );

                            })}

                        </div>

                        <div className="col-lg-6 todo-container">

                            {halfTodo.length > 0 && halfTodo[1].map(key => {
                                
                                var len = Object.keys(todos).length;

                                return (
                                <div className="mb-lg-2 mb-3" key={key}>
                                    <div className='text-blue mb-lg-2 mb-3 d-block px-2'>

                                    

                                        {(todoBefore && key === todoBefore) || (percentage === 100 && key === Object.keys(todos)[len-1])? 
                                        <input onChange={()=>updateTodo(key,false)} id={`chk${String(key).trim()}`} className='me-lg-3 me-2 pointer d-inline' type="checkbox" checked/>
                                        :
                                        key === todoEnabled?
                                        <input onChange={()=>updateTodo(key,true)} id={`chk${String(key).trim()}`} className='me-lg-3 me-2 pointer d-inline' type="checkbox"/>
                                        :
                                        todos[key]?
                                        <input id={`chk${key}`} className='me-lg-3 me-2 pointer d-inline' type="checkbox" disabled checked/>
                                        :
                                        <input id={`chk${key}`} className='me-lg-3 me-2 pointer d-inline' type="checkbox" disabled/>
                                        }

                                        <label className='saira text-blue d-inline pointer' htmlFor={`chk${key}`}>{String(key).split('-')[1]}</label>
                                    </div>
                                    <hr className="my-2"/>
                                </div>
                                );

                            })}

                        </div>

                    </div>

                </div>

            </div>
            <ModalLoading show={processing}/>
        </>
    )
}