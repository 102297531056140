
import { Helmet } from 'react-helmet-async';
import blackWordLogo from '../../img/logo/black-word-logo.png';
import { FunnelFooter } from '../../components/landing/FunnelFooter';
import irregShape from "../../img/bg/irregular-bg.png"

import xmasBlue from '../../img/merch/blue.png'
import xmasBlack from '../../img/merch/black.png'
import xmasRed from '../../img/merch/red.png'
import xmasGreen from '../../img/merch/green.png'
import xmasWhite from '../../img/merch/white.png'
import xmasYellow from '../../img/merch/yellow.png'

import nyBlack from '../../img/merch/ny-black.png'
import nyBlue from '../../img/merch/ny-blue.png'
import nyWhite from '../../img/merch/ny-white.png'

import bdo from '../../img/logo/bdo.png'
import chinaBank from '../../img/logo/china-bank.png'
import coinsPH from '../../img/logo/coins-ph.png'
import gcash from '../../img/logo/gcash.png'
import landBank from '../../img/logo/landbank.png'
import maya from '../../img/logo/maya.png'
import bpi from '../../img/logo/bpi.png'
import securityBank from '../../img/logo/security-bank.png'
import unionBank from '../../img/logo/union-bank.png'

import { useEffect, useState } from 'react';
import { numberWithCommas } from '../../helper/formatter';
import { useParams } from 'react-router';
import { onValue, ref } from 'firebase/database';
import { studentDB } from '../../api/firebase-student-config';
import ModalLoading from '../../components/general/ModalLoading';

export const TradingCircleFamilyShirtTracking = () =>{

    const {id} = useParams();
    const [processing,setProcessing] = useState(true)
    const [order,setOrder] = useState()

    const merchImage = {
        'xmasBlue':xmasBlue,
        'xmasBlack':xmasBlack,
        'xmasRed':xmasRed,
        'xmasGreen':xmasGreen,
        'xmasWhite':xmasWhite,
        'xmasYellow':xmasYellow,
        'nyWhite':nyWhite,
        'nyBlue':nyBlue,
        'nyBlack':nyBlack
    }

    const discount ={
        3:200,
        4:200,
        5:300
    }


    const shippingFee = {
        1:75
    }

    const mugs = {
        5:1
    }


    const [cart,setCart] = useState([{
        color:'Blue',
        size:'M',
        key:0
    }]);

    useEffect(()=>{

        onValue(ref(studentDB,`preOrders/${id}`),(snapshot)=>{
            if(snapshot.exists()){
                setOrder(snapshot.val())
                var c = snapshot.val().cart
                var ca = [];
                for(var k in c){
                    ca.push(c[k]);
                }
                setCart(ca);

                setProcessing(false)
            }else window.location.href = '/trading-circle-family-shirt'
        })

    },[id])


    return(
        <>
            <Helmet>
                <title>Trading Circle - Family Shirt</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn py-4">

                <div className='row justify-content-center mt-lg-3 mt-0'>

                    <div className='col-12 text-center mb-4'>
                        <img className='d-none d-lg-inline darkmode-logo' src={blackWordLogo} width='20%' alt='logo'/>
                        <img className='d-inline d-lg-none darkmode-logo' src={blackWordLogo} width='70%' alt='logo'/>
                        <div className='row justify-content-center mb-3'>
                            <div className='col-lg-6 col-12'>
                                <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                            </div>
                        </div>
                        <h1 className='bold mb-2 text-blue black'>Order Tracker</h1>
                        <h4 className='bold mb-1' style={{fontSize:'calc(1rem + 0.4vw)'}}>{order && order.fullName}</h4>
                        <h4 className='mb-1 px-5' style={{fontSize:'calc(0.7rem + 0.45vw)'}}>{order && order.address}</h4>
                        <h4 className='text-muted' style={{fontSize:'calc(0.7rem + 0.45vw)'}}>{id}</h4>
                        
                        {order && order.status &&
                            <>
                                {order.status === 'PENDING'?
                                <h4 className='text-warning black' style={{fontSize:'calc(0.7rem + 0.45vw)'}}><i className='fa fa-triangle-exclamation'/> PENDING</h4>
                                :
                                order.status === 'PAID'?
                                <h4 className='text-success black' style={{fontSize:'calc(0.7rem + 0.45vw)'}}><i className='fa fa-money'/> PAID</h4>
                                :
                                <h4 className='text-success black' style={{fontSize:'calc(0.7rem + 0.45vw)'}}><i className='fa fa-truck'/> SHIPPED</h4>
                                }
                            </>
                        }
                        
                    </div>                    

                    {cart.map((c)=>{
                        return(
                        <div className='text-center my-5 mx-5 animate__animated animate__fadeIn' style={{maxWidth:'300px',position:'relative'}}>
                            <img src={merchImage[c.color]} width='250px' alt='shirt'/>
                            <label className='saira bold text-blue mb-1' htmlFor="slcType">Color</label>
                            <select value={c.color} className='form-select' id='slcType' disabled>
                                <option value='xmasBlue'>Christmas Blue</option>
                                <option value='xmasBlack'>Christmas Black</option>
                                <option value='xmasRed'>Christmas Red</option>
                                <option value='xmasGreen'>Christmas Green</option>
                                <option value='xmasWhite'>Christmas White</option>
                                <option value='xmasYellow'>Christmas Yellow</option>
                                <option value='nyBlack'>New Year Black</option>
                                <option value='nyBlue'>New Year Blue</option>
                                <option value='nyWhite'>New Year White</option>
                            </select>

                            <label className='saira bold text-blue mt-2' htmlFor="slcSize">Size</label>
                            <select value={c.size} className='form-select' id='slcSize' disabled>
                                <option value='XS'>Extra Small (XS)</option>
                                <option value='S'>Small (S)</option>
                                <option value='M'>Medium (M)</option>
                                <option value='L'>Large (L)</option>
                                <option value='XL'>Extra Large (XL)</option>
                                <option value='XXL'>Double Extra Large (XXL)</option>
                                <option value='XXXL'>Triple Extra Large (XXXL)</option>
                            </select>

                        </div>
                        )
                    })}

                    <div className='row justify-content-center mb-3'>
                        <div className='col-lg-6 col-12'>
                            <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                        </div>
                        <div className='col-12'/>
                        <div className='col-lg-6 col-12 ps-5'>
                            
                            {cart.length > 0 &&
                            <div className={`row ${shippingFee[cart.length]?`text-error`:`text-success`} animate__animated animate__fadeIn`}>
                                <div className='col-lg-4 col-6'>
                                    <h4 style={{fontSize:'calc(1rem + 0.3vw)'}}>Shipping Fee : </h4>
                                </div>
                                <div className='col-lg-8 col-6'>
                                    <h4 className='black' style={{fontSize:'calc(1rem + 0.3vw)'}}>{shippingFee[cart.length]?`₱ ${numberWithCommas(shippingFee[cart.length])}`:`FREE`}</h4>
                                </div>
                            </div>
                            }

                            <div className='row text-blue animate__animated'>
                                <div className='col-lg-4 col-6'>
                                    <h4 style={{fontSize:'calc(1rem + 0.5vw)'}}>Original Price : </h4>
                                </div>
                                <div className='col-lg-8 col-6'>
                                    <h4 className='black' style={{fontSize:'calc(1rem + 0.5vw)'}}>₱ {numberWithCommas(cart.length * 399)} ({cart.length} Shirt/s)</h4>
                                </div>
                            </div>

                            {discount[cart.length] &&
                            <>
                                <div className='row text-success animate__animated animate__fadeIn'>
                                    <div className='col-lg-4 col-6'>
                                        <h4 style={{fontSize:'calc(1rem + 0.5vw)'}}>Discount : </h4>
                                    </div>
                                    <div className='col-lg-8 col-6'>
                                        <h4 className='black' style={{fontSize:'calc(1rem + 0.5vw)'}}>₱ {numberWithCommas(discount[cart.length])}</h4>
                                    </div>
                                </div>
                            </>
                            }
                            
                                
                            {mugs[cart.length] &&
                            <>
                                <div className='row text-success animate__animated animate__fadeIn'>
                                    <div className='col-lg-4 col-6'>
                                        <h4 style={{fontSize:'calc(1rem + 0.5vw)'}}>Freebie : </h4>
                                    </div>
                                    <div className='col-lg-8 col-6'>
                                        <h4 className='black' style={{fontSize:'calc(1rem + 0.5vw)'}}>{mugs[cart.length]} TC Mug</h4>
                                    </div>
                                </div>
                            </>
                            }

                            <hr/>

                            <div className='row text-light-blue animate__animated animate__fadeIn'>
                                <div className='col-lg-4 col-6'>
                                    <h4 style={{fontSize:'calc(1rem + 0.65vw)'}}>Total Price : </h4>
                                </div>
                                <div className='col-lg-8 col-6'>
                                    {discount[cart.length] && !shippingFee[cart.length] && <h4 className='black' style={{fontSize:'calc(1rem + 0.65vw)'}}>₱ {numberWithCommas((cart.length * 399) - discount[cart.length])}</h4>}
                                    {shippingFee[cart.length] && !discount[cart.length] && <h4 className='black' style={{fontSize:'calc(1rem + 0.65vw)'}}>₱ {numberWithCommas((cart.length * 399) + shippingFee[cart.length])}</h4>}
                                    {shippingFee[cart.length] && discount[cart.length] && <h4 className='black' style={{fontSize:'calc(1rem + 0.65vw)'}}>₱ {numberWithCommas((cart.length * 399) + shippingFee[cart.length] - discount[cart.length])}</h4>}
                                    {!shippingFee[cart.length] && !discount[cart.length] && <h4 className='black' style={{fontSize:'calc(1rem + 0.65vw)'}}>₱ {numberWithCommas((cart.length * 399))}</h4>}
                                </div>
                            </div>

                            <hr/>

                            <div className='row text-light-blue animate__animated animate__fadeIn mt-5'>
                                <div className='col-12 text-center'>
                                    <h4 className='black' style={{fontSize:'calc(1.5rem + 0.65vw)'}}><i className='fa fa-money-check-dollar'/> Settle Your Payment Here</h4>
                                    <p>Send your proof of payment in our <a href='https://www.facebook.com/alexbalingitofficial' className='underline' target='_blank' rel='noreferrer'>Official Facebook Page here.</a></p>
                                </div>
                            </div>
                            
                            <div className='row justify-content-center text-center'>

                                <div className='col-lg-12 funnel-container'>

                                    <div className='row justify-content-center my-4' style={{rowGap:'50px'}}>

                                        <div className='col-lg-6 col-11' data-aos='fade-up'>
                                            <img src={gcash} width='80%' alt='logo'/>
                                            <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                            <h5><b>Account Number:</b> 0929 853 5283</h5>
                                            {/*<h5><b>Account Name:</b> Aleli Joy Balingit</h5>
                                            <h5><b>Account Number:</b> 0927 577 3805</h5>*/}
                                            {/*<h5><b>Account Name:</b> Aljay Balingit</h5>
                                            <h5><b>Account Number:</b> 0920 971 0611</h5>*/}
                                        </div>

                                        <div className='col-lg-6 col-11' data-aos='fade-up'>
                                            <img src={bdo} width='80%' alt='logo'/>
                                            <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                            <h5><b>Account Number:</b> 0056 4034 7652</h5>
                                        </div>

                                        <div className='col-lg-6 col-11' data-aos='fade-up'>
                                            <img src={chinaBank} width='80%' alt='logo'/>
                                            <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                            <h5><b>Account Number:</b> 1268 0202 1864</h5>
                                        </div>

                                        <div className='col-lg-6 col-11' data-aos='fade-up'>
                                            <img src={coinsPH} width='80%' alt='logo'/>
                                            <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                            <h5><b>Account Number:</b> 0929 853 5283</h5>
                                        </div>

                                        <div className='col-lg-6 col-11' data-aos='fade-up'>
                                            <img src={landBank} width='80%' alt='logo'/>
                                            <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                            <h5><b>Account Number:</b> 2796 2042 76</h5>
                                        </div>

                                        <div className='col-lg-6 col-11' data-aos='fade-up'>
                                            <img src={maya} width='80%' alt='logo'/>
                                            <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                            <h5><b>Account Number:</b> 09298535283</h5>
                                        </div>

                                        <div className='col-lg-6 col-11' data-aos='fade-up'>
                                            <img src={bpi} width='80%' alt='logo'/>
                                            <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                            <h5><b>Account Number:</b> 4719 3229 51</h5>
                                        </div>

                                        <div className='col-lg-6 col-11' data-aos='fade-up'>
                                            <img src={securityBank} width='80%' alt='logo'/>
                                            <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                            <h5><b>Account Number:</b> 00000 5691 5885</h5>
                                        </div>

                                        <div className='col-lg-6 col-11' data-aos='fade-up'>
                                            <img src={unionBank} width='80%' alt='logo'/>
                                            <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                            <h5><b>Account Number:</b> 1094 2909 4115   </h5>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            

                            
                        </div>
                    </div>



                    
                </div>

            </div>

            <div className='d-lg-block d-none' style={{background:`url('${irregShape}')`,width:'125%',height:'1507px',position:'absolute',top:'0',zIndex:'-999',backgroundPosition:'center center',right:'-12.5%',backgroundSize:'cover',opacity:'0.05'}}/>
            <div className='d-lg-none d-block' style={{background:`url('${irregShape}')`,width:'100%',height:'1005px',position:'absolute',top:'-100px',zIndex:'-999',backgroundPosition:'center center',backgroundSize:'cover',opacity:'0.05'}}/>
            
            <ModalLoading show={processing}/>
            <FunnelFooter/>
        </>
    );

}