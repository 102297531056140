import { Helmet } from "react-helmet-async"
import { Footer } from "../../components/landing/Footer"
import { MainCarousel } from "../../components/landing/MainCarousel"

import alexBalingit from "../../img/people/alex-portrait.png"
import a from "../../img/home/a.png"
import b from "../../img/home/b.png"
import c from "../../img/home/c.png"
import d from "../../img/home/d.png"
import e from "../../img/home/e.png"

import { useState } from "react"

import bgShapeDark from "../../img/bg/bg-shape-dark.png"
import { NavLink } from "react-router-dom"


export const Home = ()=>{

    var [videoIDs] = useState([
        's3ERRrmDzD8', 
        'ZnZAt7n5ZXg', 
        '0yOG0AejxMM', 
        'jkCMp28WDsU', 
    ])

     var [archivedIDs] = useState([
        'GrfpM6hISgI', 
        'AIb1lS7uNOU', 
        'BinmsLKIi_g', 
        'Kca0XwacihE', 
        'UGD3Xk0EztA', 
        '8DmQVztDDT4', 
        'kX6p_aQVaEE', 
        'RccpxxuADY8', 
        'QUMueGY7KFg', 
        'sLOaVN2ckdI', 
        'lDYtbpnj-mA', 
        '0YhhhPVtuUs', 
        'es-bGGXpse0', 
        'B6EKtGRWEgI', 
        'xxQ2FUo-s6I', 
        'zyJNvv6Jllo'
    ])

    return(
        <>
            <Helmet>
                <title>Trading Circle</title>
            </Helmet>

            <div className="container-fluid animate__animated animate__fadeIn p-0" style={{marginTop:'-15px',overflowX:'hidden'}}>

                <MainCarousel/>

                <div className="row justify-content-center d-none d-lg-flex" style={{margin:'100px 50px'}}>
                    
                    <div className="col-lg-4 align-self-center me-3" style={{minWidth:'500px'}} data-aos='fade-right'>
                        <h5 className="text-blue">Designed for Serious and Beginner Traders</h5>
                        <h2 className="saira" style={{fontSize:'36px'}}>Discover my Personal CIRCLE Strategy to Maximize your Profitability.</h2>
                        <p className="text-muted">Watch Free Forex Training. NEVER AGAIN (1) Gamble your capital and life savings. (2) Overwhelmed by the charts (3) Spend all day studying alone.</p>
                        <NavLink to='/register'><button className="btn-blue w-100 py-2 mt-3">Learn Forex</button></NavLink>
                    </div>

                    <div className="col-lg-2 align-self-center" style={{minWidth:'300px'}} data-aos='fade-left'>
                        <img src={alexBalingit} style={{width:'100%'}} alt='alex'/>
                    </div>

                </div>

                <div className="row justify-content-center d-block d-lg-none m-5">

                    <div className="col-12 align-self-center text-center" data-aos='fade-up'>
                        <img src={alexBalingit} style={{width:'calc(60% + 1.5vw)',maxWidth:'300px'}} alt='alex'/>
                    </div>
                    
                    <div className="col-lg-4 align-self-center mt-3" data-aos='fade-up'>
                        <div className="row justify-content-center">
                            <div className="col-12" style={{maxWidth:'500px'}}>
                                <h5 className="text-blue" style={{fontSize:'calc(0.4rem + 1.5vw)'}}>Designed for Serious and Beginner Traders</h5>
                                <h2 className="saira" style={{fontSize:'calc(1rem + 1.25vw)'}}>Discover my Personal CIRCLE Strategy to Maximize your Profitability.</h2>
                                <p className="text-muted text-justify" style={{fontSize:'calc(0.5rem + 1vw)'}}>Watch Free Forex Training. NEVER AGAIN (1) Gamble your capital and life savings. (2) Overwhelmed by the charts (3) Spend all day studying alone.</p>
                                <button className="btn-blue w-100 py-2 mt-3" style={{fontSize:'calc(0.4rem + 1.5vw)'}}>Learn Forex</button>
                            </div>
                        </div>
                        
                    </div>

                </div>

                <div className="row justify-content-center mx-0 my-5">


                    <div className="col-12 mt-5 mb-5" style={{background:`url('${bgShapeDark}')`,backgroundSize:'cover',backgroundPosition:'center',minHeight:'1000px'}}>
                        <div className="row h-100">
                            <div className="col-12 align-self-center mb-lg-0 mb-5">
                              
                                <div className="row justify-content-center text-center mb-lg-0 mb-5">

                                    <div className="col-lg-5 my-3 mt-5" data-aos='fade-right'>
                                        <img src={a} alt='a' style={{width:'calc(15rem + 30%)'}}/>
                                    </div>
                                    <div className="col-lg-5 my-3 mt-5" data-aos='fade-left'>
                                        <img src={b} alt='b' style={{width:'calc(15rem + 30%)'}}/>
                                    </div>

                                    <div className="col-12 d-none d-lg-block" style={{margin:'calc(-5rem + -1.5%) 0px'}} data-aos='fade-up'>
                                        <img src={e} alt='e' style={{width:'15%'}}/>
                                    </div>
                                    <div className="col-12 d-block d-lg-none my-4" data-aos='fade-up'>
                                        <img src={e} alt='e' style={{width:'40%'}}/>
                                    </div>

                                    <div className="col-lg-5 my-3 mb-5" data-aos='fade-right'>
                                        <img src={c} alt='c' style={{width:'calc(15rem + 30%)'}}/>
                                    </div>
                                    <div className="col-lg-5 my-3 mb-5" data-aos='fade-left'>
                                        <img src={d} alt='d' style={{width:'calc(15rem + 30%)'}} />
                                    </div>

                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className="col-12" id="testimonials">
                        <h3 className="text-light-blue bold text-center">Testimonials</h3>

                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <hr className="blue-hr mt-1 mb-4"/>
                                    <div className="row justify-content-center" style={{gap:'20px'}}>
                                        {videoIDs &&
                                        videoIDs.map((id)=>{
                                            
                                            return(
                                                <div className="col-lg-2 col-10" key={id} style={{borderRadius:'25px',minWidth:'300px'}}>
                                                    <div style={{"height":"0px","overflow": "hidden","paddingTop":"56.25%","position":"relative","width": "100%",'borderRadius':'25px'}}>
                                                        <iframe
                                                            style={{"position":"absolute","top":"0px","left":"0px","width":"100%", "height":"100%"}}
                                                            src={`https://tube.rvere.com/embed?v=${id}`}
                                                            title="YouTube video player"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            sandbox="allow-scripts allow-forms allow-same-origin allow-presentation"
                                                            allowFullScreen>
                                                        </iframe>
                                                    </div>
                                                </div>
                                            )

                                        })
                                        }
                                    </div>
                                <hr className="blue-hr mt-3 mb-1"/>

                            </div>
                        </div>

                    </div>
                    
                </div>
            
                
            </div>

            <Footer/>

        </>
    )
}